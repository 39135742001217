import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import axiosCerebrum from '../../../../axios-cerebrum'
import 'url-search-params-polyfill';
import moment from 'moment';

const styles = theme => ({
  root: {
  },
})

function Preview(props) {

  const {
    classes,
    state,
    theme
  } = props;

  // const urlSearch = new URLSearchParams(window.location.search);
  // const changeId = urlSearch.get('changeId');

  const [template, setTemplate] = useState();
  const [templateLoading, setTemplateLoading] = useState(false);
  const [templateError, setTemplateError] = useState(false)

  let KLogo =
    `<svg width="92px" height="20px" viewBox="0 0 92 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>K_LOGO</title>
      <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-2">
              <g id="Group-4" transform="translate(72.000000, 0.000000)" fill="#000000" fill-opacity="0.87">
                  <g id="Group">
                      <polygon id="Fill-48" points="6.98795181 16.064257 4.01606426 13.1548859 4.01606426 4.01606426 6.98795181 4.01606426"></polygon>
                      <polygon id="Fill-49" points="15.0200803 5.06024096 11.6126851 5.06024096 9.95983936 7.26682102 11.9929691 9.07630522"></polygon>
                      <polygon id="Fill-50" points="7.95180723 10.6096796 12.9047487 15.9839357 16.9477912 15.9839357 9.60378486 8.03212851"></polygon>
                      <path d="M9.99994614,1.6156916 C5.37680621,1.6156916 1.6156916,5.37691392 1.6156916,10.0000539 C1.6156916,14.6231938 5.37680621,18.3843084 9.99994614,18.3843084 C14.6230861,18.3843084 18.3843084,14.6231938 18.3843084,10.0000539 C18.3843084,5.37691392 14.6230861,1.6156916 9.99994614,1.6156916 M9.99994614,20 C4.48591386,20 0,15.5140861 0,10.0000539 C0,4.48602157 4.48591386,0 9.99994614,0 C15.5139784,0 20,4.48602157 20,10.0000539 C20,15.5140861 15.5139784,20 9.99994614,20" id="Fill-51"></path>
                  </g>
              </g>
              <g id="Front-Logo">
                  <text id="KADA" font-family="Arial" font-size="20" font-weight="bold" fill="#000000">
                      <tspan x="0" y="17">K</tspan>
                      <tspan x="16" y="17">A</tspan>
                      <tspan x="32" y="17">D</tspan>
                      <tspan x="48" y="17">A</tspan>
                  </text>
              </g>
          </g>
      </g>
  </svg>`

  useEffect(() => {
    setTemplateLoading(true);
    setTemplateError(false)
    axiosCerebrum
      .post(
        '/api/notifications/email/preview',
        {
          "recipient_user_ids": [...state.notificationUsers, ...state.notificationUsersManual.filter(el => el.selected).map(el => el.id)],
          "additional_recipient_emails": state.notificationEmailsManual.filter(el => el.selected).map(el => el.id),
          "template_id": state.selectedEmailTemplate,
          "template_data": {
            "extract_id": state.extractData.id,
            "title": state.notificationInput.title,
            "business_impact": state.notificationInput.impact,
            "description": state.notificationInput.description.replaceAll("\n", '<br/>'),
            "date": moment(state.notificationInput.date).format('YYYY-MM-DD'),
            "status": state.notificationInput.status || '',
            "link": state.notificationInput.link || '',
            "contact": state.notificationInput.contact,
            'object_id': state.historyObject?.id
          },
        }
      )
      .then(response => {
        let newBody = response.data.body
          .replace('<img src="cid:var_image_logo" alt="KADA Logo" width="70" height="20" style="vertical-align: middle;">', KLogo)
          .replace(/href=(["'])(.*?)(["'])/g, '')
          .replace('id="change-email-body"', 'id="change-email-body" style="background:none;"')
          .replace('id="change-email-root"', 'id="change-email-root" style="padding:0 0 24px;"')
        setTemplate(newBody);
        setTemplateLoading(false);
        setTemplateError(false)
      })
      .catch(error => {
        console.log(error);
        setTemplateLoading(false);
        setTemplateError(true)
      })
    // eslint-disable-next-line
  }, [])


  return (
    <div className={classes.root}>
      <div style={{ marginBottom: 24 }}>
        <Typography style={{ fontSize: 20, color: theme.palette.header.main }}>NOTIFICATION DETAILS</Typography>
        <Typography style={{ fontSize: 12, color: theme.palette.primaryText.light }}>Construct a notification to users of this object</Typography>
      </div>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        {
          templateLoading && <div style={{ width: '100%', display: 'flex', justifyContent: "center" }}><CircularProgress color='secondary' style={{ margin: '40px auto' }} /></div>
        }
        {
          templateError && <Typography style={{ color: theme.palette.primaryText.main, marginTop: 30 }}>Error occurred loading template.</Typography>
        }
        {
          template &&
          parse(
            template,
          )
        }
      </div>
    </div>
  )
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Preview));

