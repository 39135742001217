import React, {} from 'react';
import { withTheme, withStyles, Typography, Checkbox } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { convertMBSizes, formatNumber } from '../../../utilities'
import PropTypes from 'prop-types'

const styles = theme => ({
  chart:{
    '& text':{
      whiteSpace:'pre',
    },
    '& .apexcharts-series path':{
      cursor:'pointer'
    },
    '& .apexcharts-datalabel':{
      letterSpacing:1.5
    },
    '& .apexcharts-yaxis-label':{
      fontSize:10
    }
  },
  clickabledChart:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  checkboxRoot:{
    width:16,
    height:16,
    color:theme.palette.secondary.main,
    '& svg':{
      width:16,
      height:16,
      color:theme.palette.secondary.main,
    }
  }
})

const BarChartVertical = props => {

  const {
    theme,
    classes,
    id,
    height,
    width,
    series, // takes array of objects {name:..., data:[...]}
    labels,
    toggleLabel,
    isFileSize,
    isLog,
    // colours
  } = props;
  
  const [shownLabels, setShownLabels] = React.useState(labels)
  const secondaryBarColour = '#FC642D'

  let shownDataIndex = shownLabels.map(l=>labels.indexOf(l));
  let finalSeries = series.map(s=>({
    ...s,
    data:s.data.filter((d,i)=>shownDataIndex.includes(i)),
    clickFns:s.clickFns?.filter((d,i)=>shownDataIndex.includes(i))
  }))
  
  const options = {
    chart: {
      type: 'bar',
      id:id,
      toolbar:{show:false},
      parentHeightOffset:0,
      events:{
        xAxisLabelClick: function(event, chartContext, config){
          let labelIndex = config.labelIndex;
          finalSeries[0].clickFns?.[labelIndex]?.()
        },
        click: function(event, chartContext, config) {
          if(config.seriesIndex<0 || config.dataPointIndex<0)return;
          finalSeries[config.seriesIndex]?.clickFns?.[config.dataPointIndex]?.()
        }
      }
    },
    colors:[function({dataPointIndex,value,seriesIndex}){
      if( toggleLabel?.value?.filter(l=>shownLabels.includes(l)).map(l=>shownLabels.indexOf(l)).includes(dataPointIndex))return secondaryBarColour;
      return '#A1C4FD'
    }],
    plotOptions: {
      bar: {
        borderRadius:4,
        columnWidth:'30%',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        // barHeight:shownLabels.length<4?'30%' : '60%',
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        }
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
    },
    dataLabels:{
      style:{
        colors:[theme.palette.primaryText.main],
        fontSize:'12px'
      },
      offsetY:-20,
      formatter: function(val, opts) {
        // get correspoing value from series based on opts
        let value = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
        let formatted = '';
        try{
          if(isFileSize){
            formatted = convertMBSizes(value) + ''
          }else{
            formatted = (formatNumber(value,undefined,true) + '')
            if((value+'').length>3){
              formatted = formatted.padStart((value+'').length*1.5,' ');
            }
          }
        }catch(error){
          console.log(error)
        }
        return formatted
      },
    },
    xaxis:{
      show:true,
      categories:shownLabels,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show:true,
        style:{
          colors:theme.palette.primaryText.main
        }
      }
    },
    grid:{
      // show:false
      xaxis: {
        lines: {
            show: false
        }
      },   
      yaxis: {
        lines: {
            show: false
        }
      }, 
    },
    yaxis:{
      showAlways:true,
      axisBorder: {
        show: true,
      },
      tickAmount:1,
      axisTicks: {
        show: false,
      },
      logarithmic: isLog?true:false,
      logBase: 1/2,
      labels:{
        align:'right',
        style:{
          colors:theme.palette.primaryText.light,
          cssClass:finalSeries[0].clickFns?classes.clickabledChart:undefined
        },
        formatter: function(val) {
          if(isFileSize){
            return convertMBSizes(val) + ''
          }
          return formatNumber(val.toFixed(0))
        },
        maxWidth:128,
      }
    },
    legend:{
      position:'top',
      labels:{
        colors:theme.palette.primaryText.main
      },
      itemMargin:{
        horizontal:8
      },
      markers:{
        width:10,
        height:10
      },
      horizontalAlign:'left',
      fontSize:'12px',
    },
    tooltip: {
      shared: false,
      intersect: true,
      y:{
        formatter: function(val) {
          if(val!==Math.floor(val))return ''
          if(isFileSize){
            return convertMBSizes(val) + ''
          }
          return formatNumber(val.toFixed(0))
        },
      },
    },
  };

  return (
    <div>
      {
        toggleLabel?.value?.length?
        <div 
          style={{display:'flex',alignItems:'center',cursor:'pointer',position:'relative',bottom:-8,zIndex:1}}
          onClick={event=>{
            if(shownLabels.some(l=>toggleLabel.value.includes(l))){
              setShownLabels(shownLabels.filter(el=>!toggleLabel.value.includes(el)))
            }else{
              setShownLabels(labels)
            }
          }}
        >
          <Checkbox color='secondary' classes={{root:classes.checkboxRoot}} style={{padding:0,width:16,height:16}} checked={shownLabels.some(l=>toggleLabel.value.includes(l))} />
          {/* <div 
            style={{border:`1px solid ${secondaryBarColour}`,width:12,height:12,borderRadius:2,background:shownLabels.some(l=>toggleLabel.value.includes(l))?secondaryBarColour:undefined }}
          >
          </div> */}
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginLeft:6}}>{toggleLabel.name}</Typography>
        </div>
        :undefined
      }
      {
        shownLabels.length>0 && 
        <ReactApexChart 
          className={classes.chart} 
          key={id} 
          options={options} 
          series={finalSeries} 
          height={height} 
          width={width} 
          style={{justifyContent:'center', display:'flex'}} 
          type="bar" 
        />
      }
    </div>
  )
}

BarChartVertical.prototype = {
  id:PropTypes.string.isRequired,
  series:PropTypes.arrayOf(PropTypes.shape({
    name:PropTypes.string.isRequired,
    data:PropTypes.arrayOf(PropTypes.number).isRequired,
    clickFns:PropTypes.arrayOf(PropTypes.func)
  })).isRequired,
  labels:PropTypes.arrayOf(PropTypes.string).isRequired,
  height:PropTypes.number,
  width:PropTypes.number,
  toggleLabel:PropTypes.shape({
    name:PropTypes.string.isRequired,
    value:PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
}



export default withTheme()(withStyles(styles)(BarChartVertical));