import React, { useState, useEffect } from 'react';
import { withTheme, withStyles, Typography, Stepper, Step, StepLabel, StepContent} from '@material-ui/core';
import AddDBName from './AddDBName';
import axiosCerebrum from '../../../axios-cerebrum';
import { toTitleCase } from '../../../utilities';
import AddHostDetail from './AddHostDetail';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';
import { waitForHostCreated } from '../CreateSource/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  title:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:1.5
  },
  chip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    marginBottom: '1rem',
    borderRadius: 5
  },
  selector: {
    ...theme.components.selector,
    width: 618,
    height:56,
    overflow:'hidden',
    marginTop: '0.5rem',
  },
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    marginTop: '0.5rem',
    width:'100%',
  },
  inputArea:{
    ...theme.components.inputBase,
    marginTop: '0.5rem',
    width:'100%',
    padding:16,
    boxSizing:'border-box'
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  label: {
    '& svg':{
      color:theme.palette.primaryText.light
    }
  },
  suggestionItem:{
    padding:'6px 16px',
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    },
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    height:24,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
  },
  hostTag:{
    padding:'6px 16px 6px 8px',
    backgroundColor:theme.palette.chip.main,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    color:theme.palette.primaryText.main,
    borderRadius:18,
    display:'flex',
    alignItems:'center',
    marginBottom:8,
    height:36,
    overflow:'hidden',
    width:'max-content',
    maxWidth:'100%',
    boxSizing:'border-box'
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

const AddReference = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    editedHost
  } = props;

  const [step, setStep] = useState(0)
  const [tmpEditedHost, setTmpEditedHost] = useState(editedHost)
  const [sourceName, setSourceName] = useState(editedHost?.alternate_name||'')
  const [hostName, setHostName] = useState(editedHost?.name||'')
  const [hostDescription, setHostDescription] = useState(editedHost?.description||'')
  const [sourceObject, setSourceObject] = useState('none')
  const [dbNames, setDBNames] = useState([])
  const [dbNamesError, setDBNamesError] = useState(false)
  const [dbNamesLoading, setDBNamesLoading] = useState(false)
  const [originalDBs, setOriginalDBs] = useState([])
  const [creating, setCreating] = useState(false)
  
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [addDbModalOpen, setAddDbModalOpen] = useState(false)

  const {
    sendAlert
  } = useAlert({})

  useEffect(()=>{
    history.push(`/admin/sources`)
    return () => {
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  const loadDBNames = () => {
    setDBNamesLoading(true)
    axiosCerebrum
      .get(
        `/api/hosts/${editedHost.id}/related`,{
          params:{
            relationship:'CHILDREN',
            per_page:200
          }
        }
      )
      .then(response=>{
        let dbNames = response.data.items.map(item=>item.name)
        setDBNames(dbNames)
        setOriginalDBs(response.data.items)
        setSourceObject(response.data.items[0]?toTitleCase(response.data.items[0].type):'none')
        setDBNamesLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setDBNamesLoading(false)
        setDBNamesError(true)
      })
  }

  useEffect(()=>{
    if(editedHost){
      loadDBNames()
    }
  // eslint-disable-next-line
  },[])

  let steps = [
    `${tmpEditedHost?'Edit':'Add'} Source details`,
    `Add a ${sourceObject==='none'?'Database/Tool':sourceObject} name`
  ]

  const getStepLabelText = index => {
    switch(index){
      case 0:
        return steps[index];
      case 1:
        return steps[index];
      default:
        return ''
    }
  }

  const onChangeSourceName = event => {
    setSourceName(event.target.value)
  }

  const onChangeHostName = value => {
    setHostName(value)
  }

  const onChangeHostDescription = event => {
    setHostDescription(event.target.value)
  }

  const onChangeSourceObject = event => {
    setSourceObject(event.target.value)
  }


  // const onReset = () => {
  //   setHostName('')
  // }


  const onFinish = () => {
    dispatch({type:'set_hosts_updated',hostsUpdated:true})
    dispatch({type:'set_tab_state',tabState:0});
  }

  const onCallUpdateDB = () => {

    setCreating(true)
    let addedDBNames = dbNames.filter(dbName=>!originalDBs.map(db=>db.name).includes(dbName))
    let removedDBs = originalDBs.filter(db=>!dbNames.includes(db.name))

    let promises = [];
    let url = sourceObject==='Database'?'databases':'tools';
    addedDBNames.forEach(dbName=>{
      promises.push(
        axiosCerebrum.post(
          `/api/${url}`,
          {
            "host_id": tmpEditedHost.id,
            "description": "",
            "name": dbName
          }
        )
      )
    })
    removedDBs.forEach(db=>{
      promises.push(
        axiosCerebrum.delete(
          `/api/${url}/${db.id}`
        )
      )
    })
    Promise
      .all(promises)
      .then(response=>{
        sendAlert({message:`${sourceObject}s updated successfully`,type:'info'})
        history.push(`/admin/sources`)
        onFinish();
        setCreating(false)
      })
      .catch(error=>{
        setAlertMessage(`Failed to link ${sourceObject}s`)
        setAlertOpen(true)
        setCreating(false)
      })
  }

  const onUpdateDB = () => {
    let addedDBNames = dbNames.filter(dbName=>!originalDBs.map(db=>db.name).includes(dbName))
    let removedDBs = originalDBs.filter(db=>!dbNames.includes(db.name))

    if(addedDBNames.length===0 && removedDBs.length===0){
      history.push(`/admin/sources`)
      onFinish();
      setCreating(false);
      return;
    }
    
    setAddDbModalOpen(true)

  }

  const onUpdate = () => {
    setCreating(true)
    axiosCerebrum
      .put(
        `/api/hosts/${tmpEditedHost.id}`,{
          "alternate_name":sourceName,
          "description": hostDescription,
        }
      )
      .then(response=>{
        setStep(1)
        setTmpEditedHost(response.data)
        dispatch({type:'set_hosts_updated',hostsUpdated:true})
        setCreating(false)
      })
      .catch(error=>{
        sendAlert({message:`Error occurred updating reference, please try again`,type:'error'})
        setCreating(false)
      })
  }

  const onPostCreateHost = () => {
    setCreating(true)
    axiosCerebrum
      .post(
        `/api/hosts`,{
          "alternate_name":sourceName,
          "host": hostName,
          "description": hostDescription,
        }
      )
      .then(async response=>{
        await waitForHostCreated(response.data.id)
        setStep(1)
        setCreating(false)
        setCreateModalOpen(false)
        dispatch({type:'set_hosts_updated',hostsUpdated:true})
        setTmpEditedHost(response.data)
      })
      .catch(error=>{
        setAlertMessage('Error occurred creating reference, please try again');
        setAlertOpen(true)
        setCreating(false)
      })
  }

  const onCreate = () => {
    if(tmpEditedHost){
      onUpdate()
      return;
    }
    setCreateModalOpen(true)
  }

  const detailsFilled = () => {
    if(!sourceName.trim())return false;
    if(!hostName.trim() && !editedHost)return false;
    if(sourceObject==='none')return false;
    return true;
  }

  const labelClickable = index => {
    let clickable = false;
    if(index===0)return true;
    if(index===1 && detailsFilled())clickable =  true;
    return clickable && labelClickable(index-1)
  }

  const onLabelClick = index => {
    if(!labelClickable(index))return;
    setStep(index)
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return (
          <AddHostDetail
            classes={classes} 
            setStep={setStep}
            sourceName={sourceName}
            onChangeSourceName={onChangeSourceName}
            hostName={hostName}
            onChangeHostName={onChangeHostName} 
            hostDescription={hostDescription} 
            onChangeHostDescription={onChangeHostDescription}
            sourceObject={sourceObject} 
            onChangeSourceObject={onChangeSourceObject} 
            dbNames={dbNames} 
            dbNamesLoading={dbNamesLoading} 
            dbNamesError={dbNamesError} 
            editedHost={editedHost}
            detailsFilled={detailsFilled}
            onCreate={onCreate}
            creating={creating}
          />
        )
      case 1:
        return (
          <AddDBName 
            classes={classes} 
            creating={creating} 
            setStep={setStep} 
            sourceObject={sourceObject} 
            dbNames={dbNames} 
            setDBNames={setDBNames} 
            onUpdateDB={onUpdateDB} 
            originalDBs={originalDBs}
            dbNamesLoading={dbNamesLoading} 
            dbNamesError={dbNamesError} 
            editedHost={editedHost}
          />
        )
      default:
        return <div></div>
    }
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginBottom:12,color:theme.palette.header.main,}}>
        {
          editedHost?
          'EDIT DETAILS':
          'ADD DETAILS'
        }
      </Typography>
      
      <Stepper activeStep={step} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel classes={{iconContainer:classes.label}} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <WarningConfirmModal
        description={
          <span style={{whiteSpace:'pre-wrap'}}>
            You are about to create a new source called "{sourceName}".
            {'\n\n'}
            Currently reference sources cannot be deleted. An upcoming release will allow you to delete reference sources.
          </span>
        }
        setModalOpen={setCreateModalOpen}
        modalOpen={createModalOpen}
        onConfirm={()=>onPostCreateHost()}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'YES'}
        disableConfirmButton={creating}
      />

      <WarningConfirmModal
        title={`Adding a ${sourceObject}`}
        description={
          <span style={{whiteSpace:'pre-wrap'}}>
            You are about to add a {sourceObject} to a reference source.
            {'\n\n'}
            Currently reference sources and its {sourceObject} cannot be deleted. An upcoming release will all you to delete these objects. Please check the {sourceObject} name is correct before proceeding
          </span>
        }
        setModalOpen={setAddDbModalOpen}
        modalOpen={addDbModalOpen}
        onConfirm={()=>onCallUpdateDB()}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'ADD'}
        disableConfirmButton={creating}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(AddReference));