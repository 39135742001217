import React from 'react';
import { withStyles } from '@material-ui/core';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { getCollectionObjectName } from '../../BasicSearch/MainSearch/Utils/Utils';

const styles = theme => ({
  root: {
    // maxWidth:800,
    marginTop: 8
  }
})

const Body = props => {

  const {
    classes,
    sessionData,
    history,
  } = props;

  const objectType = getCollectionObjectName({ collectionName: 'WARNING NOTICE', collectionType: 'PLATFORM' })
  console.log(objectType)
  return (
    <div className={classes.root}>
      <BasicSearch
        key={'my-notices-search'}
        customID={'my-notices-search'}
        initialView={'main_search'}
        history={history}
        // alwaysOpenNewTab={true}
        removeContainerStyle={true}
        propObjectType={objectType}
        propQuery={`*`}
        propSelectedFilters={'created_at_srt,last_updated_srt,expiry_srt,active_srt'}
        propColumn={'created_at_srt,last_updated_srt,expiry_srt,active_srt'}
        forceFq={`created_by_msrt:"${sessionData.name}" AND object_type_srt:COLLECTION_INSTANCE AND collection_srt:"WARNING NOTICE"`}
        resultItemVariant={"simplified"}
        disableDefaultFilters
        hiddenComponents={['header', 'tab', 'listTitle', 'columnSelector', 'cartButton', 'downloadButton']}
        customEmptyMsg={'No notices available'}
      />
    </div>
  )
}


export default withStyles(styles)(Body);