import React from 'react';
import { withTheme, withStyles, Typography, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import ListTile from './TilesComponents/ListTile';
import IconDataTile from './TilesComponents/IconDataTile';
import TextDataTile from './TilesComponents/TextDataTile'
import { getIconComponent, onClickResultItem, getInitials, getSeverityIcon, toTitleCase,getTrustScoreIcon,getTrustScoreBand, getTrustScoreTooltip, formatNumber } from '../../../../utilities'; 
import moment from 'moment';
import SingleLineDataTile from './TilesComponents/SingleLineDataTile';

const styles = theme => ({
  dataBox: {
    marginBottom: '2rem'
  },
  avatar: {
    backgroundColor: theme.palette.avatar.main,
    width:24,
    height:24,
    fontSize:12
  },
})

function ViewOnlyTile(props) {

  const {
    classes,
    theme,
    history,
    boxInfo,
    alwaysOpenNewTab
  } = props;

  const generateBox = box => {
    const dispBody = box.disp_body;
    const id = box.id;
    const title = box.disp_title && box.disp_title.toUpperCase();
    // defaults
    if(id==="lastUsed" && typeof(dispBody)==='string'){
      return (
        <IconDataTile
          title={title}
          text={dispBody}
          left={getIconComponent({label: 'time', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    if(["assignedTo",'createdBy','columntype','lastUpdatedBy','topschemas',"topTeams","topcontent",'topdata',"topTools","topUsers","topSources"].includes(id) && typeof(dispBody)==='string'){
      return (
        <IconDataTile
          title={title}
          text={dispBody}
          left={getIconComponent({label: 'help', size: 24, colour: theme.palette.primaryText.light})}
          action={box.box_action}
        />
      )
    }

    
    // below for column/table/database/macro/procedure profiles
    
    if(['lastUpdatedBy','createdBy'].includes(id) || (['managedBy','topTeams'].includes(id) && dispBody.length===1) ){
      if(dispBody[0].disp_value===""){
        return (
          <IconDataTile
            title={title}
            text={"Unassigned"}
            action={box.box_action}
            left={getIconComponent({label: 'help', size: 24, colour: theme.palette.primaryText.light})}
          />
        )
      }
      // if(id==='topTeams'){
      return (
        <IconDataTile
          title={title}
          text={dispBody[0].disp_value}
          left={<Avatar className={classes.avatar}>{getInitials(dispBody[0].name || dispBody[0].disp_value)}</Avatar>}
          onClick={
            dispBody[0].disp_value==='KADA'?
            null:
            (['managedBy'].includes(id)?
              ()=>onClickResultItem({history:history,label:dispBody[0].labels,id:dispBody[0].id,newWindow:alwaysOpenNewTab})
              :
              ()=>(alwaysOpenNewTab?window.open(box.link,'_blank'):history.push(box.link))
            )
          }
        />
      )
      // }
      // return (
      //   <CustomTile
      //     title={title}
      //     body={
      //       <UserChip
      //         user={{id:dispBody[0].id, name:dispBody[0].disp_value}}
      //         onClick={
      //           dispBody[0].disp_value==='KADA'?
      //           null:
      //           (['managedBy'].includes(id)?
      //             ()=>onClickResultItem({history:history,label:dispBody[0].labels,id:dispBody[0].id,newWindow:alwaysOpenNewTab})
      //             :
      //             ()=>(alwaysOpenNewTab?window.open(box.link,'_blank'):history.push(box.link))
      //           )
      //         }
      //         hideBorder
      //         height={32}
      //         avatarGap={18}
      //       />
      //     }
      //   />
      // )
    }


    else if(id==="contact"){
      return (
        <IconDataTile
          title={'CONTACT'}
          text={dispBody.name}
          onClick={dispBody.onClick}
          left={getIconComponent({label: 'email', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }


    else if(id==='columntype'){
      return (
        <IconDataTile
          title={title}
          text={dispBody[0].disp_value}
          left={getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if(['consumer_m','producer_m','owner_m','steward_m'].includes(id)){
      return (
        <SingleLineDataTile 
          title={title}
          text={dispBody.value}
        />
      )
    }

    else if(id==='contentType'){
      return (
        <IconDataTile
          title={title}
          text={dispBody[0].disp_value}
          left={getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if(id==='description'){
      return (
        <TextDataTile 
          title={title}
          text={dispBody.value}
        />
      )
    }

    else if(id==='team'){
      if(dispBody.length===1){
        return (
          <IconDataTile
            title={title}
            text={dispBody[0].name}
            onClick={() => {
              dispBody[0].onClick?dispBody[0].onClick():
                alwaysOpenNewTab?
                window.open(`/profile/team/${dispBody[0].id}`,'_blank'):
                history.push(`/profile/group/${dispBody[0].id}`)
            }}
            left={<Avatar className={classes.avatar}>{getInitials(dispBody[0].name)}</Avatar>}
          />
        )
      }
      return (
        <ListTile
          title={title}
          list={
            dispBody.map(item => ({
              name: item.name,
              onClick: () => {
                item.onClick?item.onClick():
                  alwaysOpenNewTab?
                  window.open(`/profile/team/${item.id}`,'_blank'):  
                  history.push(`/profile/group/${item.id}`)
              }
            }))
          }
        />
      )
    }


    else if(id==='channels'){
      if(dispBody.length===1){
        return (
          <IconDataTile
            title={title}
            text={dispBody[0].disp_value}
            onClick={dispBody[0].onClick}
            tooltip={dispBody[0].tooltip}
            left={getIconComponent({label:dispBody[0].labels, size: 24,colour:theme.palette.primary.main})}
          />
        )
      }
      return (
        <ListTile
          title={title}
          list={
            box.disp_body.map(item => ({
              name: toTitleCase(item.disp_value),
              onClick: item.onClick,
              tooltip:item.tooltip
            }))
          }
          icons={
            box.disp_body.map(item => {
              return getIconComponent({label: item.labels, size: 20,colour:theme.palette.primary.main})
            })
          }
        />
      )
    }

    else if(id==="trustscore"){
      return (
        <IconDataTile
          title={title}
          tooltip={getTrustScoreTooltip({trustScore: dispBody[0].disp_value})}
          text={getTrustScoreBand({trustScore: dispBody[0].disp_value})} // recommendations
          subtext={'Last calculated on ' + moment(dispBody[1].disp_value).format('ll').split(',')[0]} // last calculated
          left={getTrustScoreIcon({trustScore: dispBody[0].disp_value, size: 24})} //trustscore icon
        />
      )
    }

    else if(id==='dataType'){
      return (
        <IconDataTile
          title={title}
          text={dispBody[0].disp_value}
          left={getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if(id==='source'){
      let isReference = dispBody[0].disp_value.toLowerCase()==='reference';
      return (
        <IconDataTile
          title={title}
          text={dispBody[0].disp_value}
          subtext={dispBody[0].disp_subtext}
          left={getIconComponent({label: dispBody[0].label, size: 24, colour: isReference?theme.palette.primaryText.light:theme.palette.primary.main}) || getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if(id==='sourceType'){
      let isReference = dispBody[0].disp_value.toLowerCase()==='reference';
      return (
        <IconDataTile
          onClick={dispBody[0].onClick}
          title={title}
          text={dispBody[0].disp_value}
          subtext={dispBody[0].disp_subtext}
          left={getIconComponent({label: isReference?'help':dispBody[0].labels, size: 24, colour: isReference?theme.palette.primaryText.light:theme.palette.primary.main}) || getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if(id==='sourceType_no_location'){
      let isReference = dispBody[0].disp_value.toLowerCase()==='reference';
      return (
        <IconDataTile
          onClick={dispBody[0].onClick}
          title={title}
          text={dispBody[0].disp_value}
          left={getIconComponent({label: isReference?'help':dispBody[0].labels, size: 24, colour: isReference?theme.palette.primaryText.light:theme.palette.primary.main}) || getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if(id==="lastUsed"){
      return (
        <IconDataTile
          title={title}
          text={moment(dispBody[0].disp_value).fromNow()}
          onClick={dispBody[0].onClick}
          action={box.box_action}
          subtext={toTitleCase(box.disp_footer)}
          left={getIconComponent({label: 'time', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }

    else if((['topUsers','topTeams','managedBy'].includes(id) && dispBody.length>1) || id==='jobTypes' || id === "topSources"){
      return (
        <ListTile
          title={title}
          action={box.box_action}
          displayAsChip={id==='jobTypes'}
          list={
            box.disp_body.map(item => ({
              name: item.disp_value,
              onClick: () => {
                alwaysOpenNewTab?
                window.open(`/profile/${item.labels}/${encodeURIComponent(item.id)}`,'_blank'):
                history.push(`/profile/${item.labels}/${encodeURIComponent(item.id)}`);
              }
            }))
          }
          icons={
            id==='topSources' && box.disp_body.map(item => {
              return getIconComponent({label: item.labels, size: 24,colour:theme.palette.primary.main})
            })
          }
        />
      )
    }

    else if(id==="runs"){
      return (
        <IconDataTile
          title={title}
          text={formatNumber(dispBody[0].disp_value) + ' times'}
          left={getIconComponent({label: 'time', size: 24, colour: theme.palette.primary.main})} 
        />
      )
    }

    else if(id==="topTools" || id==="topcontent" || id==='topdata'){
      return (
        <ListTile
          title={title}
          list={
            box.disp_body.map(item => ({
            name:item.name,
            onClick: () => {
                onClickResultItem({item,id:item.id, label: item.labels, collection: 'content', history: history,newWindow:alwaysOpenNewTab})
              }
            }))
          }
          icons={
            box.disp_body.map(item => {
              return getIconComponent({label: item.labels, size: 24,colour:theme.palette.primary.main})
            })
          }
        />
      )
    }

    else if(['knowledge','joins'].includes(id)){
      return (
        <ListTile
          title={title}
          list={
            box.disp_body.map(item => ({
              name:`${item.count} ${item.name}(s)`,
              onClick:item.onClick
            }))
          }
          icons={
            box.disp_body.map(item => {
              return getIconComponent({label: item.labels, size: 24,colour:theme.palette.primary.main})
            })
          }
          action={box.box_action}
          disableCollapse={true}
        />
      )
    }

    else if(id==='topschemas'){
      return (
        <ListTile
          title={title}
          list={
            box.disp_body.map(item => ({
            name:item.name,
            onClick: () => {
                if (id==="topTools") return
                onClickResultItem({item,id:item.id, label: item.labels, collection: 'data', history: history, nodeKey: item.nodeKey,newWindow:alwaysOpenNewTab})
              }
            }))
          }
          icons={
            box.disp_body.map(item => {
              return getIconComponent({label: item.labels, size: 24,colour:theme.palette.primary.main})
            })
          }
        />
      )
    }

    else if(id==='issueType'){
      return (
        <IconDataTile
          title={title}
          left={getIconComponent({size: 24,colour:theme.palette.primary.main,label:'info'})}
          text={<Typography style={{fontSize: '1rem', color: theme.palette.primaryText.main}}>
            {dispBody[0].disp_value}
          </Typography>}
        />
      )
    }


    else if(id==='lastUpdated' || id==='createdOn' || id==='lastRun' ){
      // if(!moment(dispBody[0].disp_value).isValid())return undefined;
      return (
        <IconDataTile
          title={title}
          text={moment(dispBody[0].disp_value).isValid()?moment(dispBody[0].disp_value).fromNow():'Date not found'}
          left={getIconComponent({label: 'time', size: 24, colour: theme.palette.primary.main})}
        />
      )
    }
    
    else if(id==='assignedTo' ){
      return (
        <div style={{cursor: 'pointer'}} onClick={()=>history.push(box.link)}>
        <IconDataTile
          title={title}
          text={toTitleCase(dispBody[0].disp_value)}
          subtext={dispBody[1]?dispBody[1].disp_value:undefined}
          left={<Avatar className={classes.avatar} >{getInitials(dispBody[0].disp_value)}</Avatar>}
        />
        </div>
      )
    }

    else if(id==='masked'){
      return (
        <SingleLineDataTile
          title={title}
          text={
            <div style={{height:34,borderRadius:17,padding:'0 6px',background:'#00D46A',color:'#000',fontSize:12,display:'flex',alignItems:'center',width:'max-content'}}>
              {dispBody[0].disp_value}
            </div>
          }
        />
      )
    }

    // process

    else if(id==='managedby'){
      if(!dispBody[0].disp_value || dispBody[0].disp_value===""){
        return (
          <IconDataTile
            title={title}
            text={"Not assigned"}
            left={getIconComponent({label: 'help', size: 24, colour: theme.palette.primaryText.light})}
          />
        )
      }
      return (
        <IconDataTile
          title={title}
          text={toTitleCase(dispBody[0].disp_value)}
          subtext={toTitleCase(dispBody[1]?dispBody[1].disp_value:box.disp_footer)}
          left={<Avatar className={classes.avatar} >{getInitials(dispBody[0].disp_value)}</Avatar>}
          onClick={()=>{
            alwaysOpenNewTab?
            window.open(`/profile/group/${dispBody[0].nodeKey}`, '_blank'):
            history.push(`/profile/group/${dispBody[0].nodeKey}`)
          }}
        />
      )
    }

    else if(id==='criticality'){
      let critNum = 10;
      if(dispBody==='medium')critNum=20;
      if(dispBody==='high')critNum=30;
      return(
        <IconDataTile
          title={title}
          text={<Typography style={{fontSize: '1rem', color: theme.palette.primaryText.light}}>{toTitleCase(dispBody)}</Typography>}
          left={getSeverityIcon({severity: critNum, size: 24})}
        />
      )
    }

    else if(id==='collectionType'){
      return (
        <IconDataTile
          title={title}
          text={dispBody[0] && dispBody[0].disp_value?dispBody[0].disp_value:undefined}
          left={getIconComponent({label:dispBody[0].label||'info', size: 24, colour: theme.palette.primary.main})}
          onClick={box.onClick}
        />
      )
    }
  }
  
  return generateBox(boxInfo)

}

ViewOnlyTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ViewOnlyTile));