import { checkProfileEditable } from "../../permissionChecker";

export const actionIds = {
  bulkUpdateProperties: 'bulk_update_properties',
  linkToCollections: 'link_to_collections',
  linkToList: 'link_to_list',
  createAndLinkToList: 'create_and_link_to_list',
  linkToWarning: 'link_to_warning',
  createAndLinkToWarning: 'create_and_link_to_warning',
}

export const actionTitles = {
  [actionIds.bulkUpdateProperties]: 'Bulk update properties',
  [actionIds.linkToCollections]: 'Link to collections',
  [actionIds.linkToList]: 'Link to list',
  [actionIds.createAndLinkToList]: 'Create and link to list',
  [actionIds.linkToWarning]: 'Link to warning notice',
  [actionIds.createAndLinkToWarning]: 'Create and link to warning notice',
}

export const getValidActions = ({ sessionData }) => {
  let actions = [];
  if(checkProfileEditable({ sessionData })) {
    actions.push({
      title: actionTitles[actionIds.bulkUpdateProperties],
      id: actionIds.bulkUpdateProperties,
    })
  }
  // if(checkCollectionLinkable({ sessionData })) {
  //   actions.push({
  //     title: 'Link to collections',
  //     id: actionIds.linkToCollections,
  //   })
  // }
  actions.push({
    title: actionTitles[actionIds.linkToList],
    id: actionIds.linkToList,
  })
  // actions.push({
  //   title: 'Create and link to list',
  //   id: actionIds.createAndLinkToList,
  // })
  actions.push({
    title: actionTitles[actionIds.linkToWarning],
    id: actionIds.linkToWarning,
  })
  // actions.push({
  //   title: 'Create and link to warning notice',
  //   id: actionIds.createAndLinkToWarning,
  // })
  return actions;
}