import React from 'react';
import { withStyles} from '@material-ui/core';
import ContributedTimeLine from '../Contributed/ContributedTimeLine';
import CreatedTimeLine from '../Created/CreatedTimeLine';
import RunTimeLine from '../Run/RunTimeLine';
import UsedTabLoader from '../Used/UsedTabLoader';
import AssignedTabLoader from '../Assigned/AssignedTabLoader';
import AssignedTimeLine from '../Assigned/AssignedTimeLine';
import UsedTimeLine from '../Used/UsedTimeLine';
import ContributedTabLoader from '../Contributed/ContributedTabLoader';
import ListTimeLine from '../List/ListTimeLine';
import ListList from '../List/ListList';
import FollowedTimeLine from '../Followed/FollowedTimeLine';
import RunTabLoader from '../Run/RunTabLoader';
import CreatedTabLoader from '../Created/CreatedTabLoader';
import FollowedTabLoader from '../Followed/FollowedTabLoader';

const styles = theme => ({
  root: {
    // maxWidth:800,
    marginTop:8
  }
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    sessionData,
    history,
    tabOptions,
    variant
  } = props;

  let headerTop = variant==='user_profile'?154:180

  let bodyContent;
  if(state.viewType==='TIMELINE'){
    switch (tabOptions[state.tabState]) {
      case 'LIST':
        bodyContent = (
          <ListTimeLine headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'CONTRIBUTED':
        bodyContent = (
          <ContributedTimeLine headerTop={headerTop}  state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'USED': 
        bodyContent = (
          // <UsedTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
          <UsedTimeLine state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        );
        break;
      case 'RUN':
        bodyContent = (
          <RunTimeLine headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'CREATED':
        bodyContent = (
          <CreatedTimeLine headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'FOLLOWING':
        bodyContent = (
          <FollowedTimeLine headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'ASSIGNED':
        bodyContent = (
          <AssignedTimeLine headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      default:
    }
  }

  if(state.viewType==='LIST'){
    switch (tabOptions[state.tabState]) {
      case 'LIST':
        bodyContent = (
          <ListList headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'CONTRIBUTED': 
        bodyContent = (
          <ContributedTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        );
        break;
      case 'USED': 
        bodyContent = (
          <UsedTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        );
        break;
      case 'RUN':
        bodyContent = (
          <RunTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'CREATED':
        bodyContent = (
          <CreatedTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'FOLLOWING':
        bodyContent = (
          <FollowedTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      case 'ASSIGNED':
        bodyContent = (
          <AssignedTabLoader headerTop={headerTop} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        )
        break;
      default:
    }
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}


export default withStyles(styles)(Body);