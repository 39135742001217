import React, {  } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SchemaList from '../SchemaList/SchemaList';
import PropTypes from 'prop-types';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Details from '../Details/Details';
// import Reference from '../Reference/Reference'
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyWrapper: {
    height: 'calc(100vh - 178px)'
  },
  appBar: {
    zIndex: 10,
  }
});

const DatabaseBody = props => {

  const {
    classes,
    tabOptions,
    tabState,
    history,
    nodeKey,
    data,
    state,
    dispatch,
    sessionData,
    fetchList,
    isShell,
    isLineageLinkable
  } = props;

  let bodyContent;

  switch (tabOptions[tabState]) {
    case 'SCHEMAS': // schemas
      bodyContent = (
        <SchemaList
          history={history}
          data={data}
          nodeKey={nodeKey}
          state={state}
          dispatch={dispatch}
          isShell={isShell}
        />
      )
      break;
    case 'DETAILS': // details
      bodyContent = (
        <Details
          history={history}
          state={state}
          dispatch={dispatch}
          nodeKey={nodeKey}
          fetchList={fetchList}
          userName={sessionData.user_name}
        >
        </Details>
      );
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // usage
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          dispatch={dispatch}
          label={'database'}
          drillThrough={false}
        />
      )
      break;
    case 'LINEAGE':
      bodyContent = (
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    default:
  }
  
  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

DatabaseBody.propTypes = {
  classes: PropTypes.object.isRequired,
  schemaCount: PropTypes.number,
  tableList: PropTypes.arrayOf(PropTypes.object),
  tableCount: PropTypes.number,
  selectedSchema: PropTypes.string,
  onSchemaClick: PropTypes.func,
  requestMoreSchemas: PropTypes.func,
  requestMoreTables: PropTypes.func,
  schemaLoading: PropTypes.bool,
  tableLoading: PropTypes.bool,
  tableUpdating: PropTypes.bool,
  schemaUpdating: PropTypes.bool,
  nodeKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  tabState: PropTypes.bool.isRequired,
  insightsData: PropTypes.object,
  insightsLoading: PropTypes.bool,
  insightsError: PropTypes.object,
  sessionData: PropTypes.object
}

export default withStyles(styles)(DatabaseBody);