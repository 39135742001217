import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Typography, CircularProgress, Checkbox, Button, MenuItem, InputBase, Paper, ClickAwayListener, MenuList, Popper } from '@material-ui/core';
import { getDispFields, getIconComponent, getLabelPlural, mapSearchObjectName, sendMessage, getTrustScoreIcon } from '../../../../../utilities';
import DrawerDragTrigger from '../../../DrawerDragTigger/DrawerDragTrigger';
import axiosCerebrum from '../../../../../axios-cerebrum';
import axiosSolr from '../../../../../axios-solr';
import { addAditionalGroupsIntoSwimlane, removeGroupFromSwimlane } from '../layoutUtils';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../../ContextMenu/ContextMenu'
import KTooltip from '../../../KTooltip/KTooltip';
import { addBadgeToIcon, getIconLabel, isShowTrust } from '../../../SearchResults/utils';
import { checkCanAddToCart } from '../../../../../permissionChecker';
import { useStore } from 'react-redux';
import CartAdder from '../../../CartAdder/CartAdder';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  customScroll:{
    overflow:"auto",
    paddingBottom:24,
    overscrollBehaviorY:"contain",
    ...theme.components.hideScroll
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    width:'max-content',
    maxWidth:"100%",
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    cursor:"pointer",
    '&:hover':{
      textDecoration:"underline"
    }
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  listItem:{
    height:56,
    display:"flex",
    alignItems:"center",
    overflow:"hidden",
    paddingLeft:16,
    paddingRight:16,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"default",
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listItemTitle:{
    fontSize:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main
  },
  listItemSubTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  iconButton:{
    padding:4
  },
  sectionTitle:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:16
  },
  cartItem:{
    '& .select-checkbox':{
      width:0,
      transition:'width 0.2s ease-in-out',
    },
    '& .item-icon':{
      width:24,
      transition:'width 0.2s ease-in-out',
    },
    '&:hover':{
      overflow:'hidden',
      '& .item-icon':{
        width:0,
        overflow:"hidden",
      },
      '& .select-checkbox':{
        width:24,
      }
    }
  },
  iconWrapper:{
    flex:'0 0 24px',
    height:24,
    width:24,
    marginRight:16,
    display:'flex',
    // overflow:'hidden'
  },
  columnHeader:{
    color:theme.palette.primaryText.main,
    fontSize:12,
    letterSpacing:1.5
  },
  selector:{
    ...theme.components.selector,
    width:120,
    flexGrow:0,
    flexShrink:0
  },
  inputBase:{
    ...theme.components.inputBase,
    // maxWidth:350,
    // width: '100%',
    height:42,
    flexGrow:1,
    marginRight:24
  },
})


function SwimlaneDrawer(props) {
  const {
    classes,
    theme,
    drawerOpen,
    setDrawerOpen,
    selectedLevel,
    childrenMap,
    currentLinks,
    currentGroups,
    mapControls,
  } = props;

  let initialWidth = 600;
  try{
    if(localStorage.getItem('objectDrawerWidth')){
      initialWidth = Number(localStorage.getItem('objectDrawerWidth')).valueOf() || 600;
    }
  }catch{}


  // const {
  //   sendAlert
  // } = useAlert({})

  const [paperWidth, setPaperWidth] = useState(initialWidth)
  const [childList, setChildList] = useState({})
  const [selectedItems, setSelectedItems] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [addToCartIds, setAddToCartIds] = useState()
  const [totalCount, setTotalCount] = useState()
  const listRef = useRef()

  const [searchValue, setSearchValue] = useState('')
  const searchTimeout = useRef()
  const [sort, setSort] = useState('score:DESC')
  const [tagView, setTagView] = useState('visibility')
  const [viewSelectorAnchor, setViewSelectorAnchor] = useState(false)

  const store = useStore();
  let sessionData = store.getState().auth.session_user;

  const direction = selectedLevel>0?'DOWNSTREAM':"UPSTREAM"
  const selectedGroups = currentGroups.filter(g=>g.level===selectedLevel && g.prevLevelRef?.expanded)
  const groupCount = selectedGroups.length
  
  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])

  const getExpandedItem = () => {
    let prevItem;
    let targetLevel = selectedLevel + (selectedLevel>0?-1:1)
    currentGroups.forEach(g=>{
      if(prevItem){
        return;
      }
      if(g.level===targetLevel){
        childrenMap[g.id].forEach(c=>{
          if(c.expanded){
            prevItem = c;
          }
        })
      }
    })
    return prevItem
  }
  
  const loadChildList = ({page=1, sortVal=sort, searchVal=searchValue}) => {
    let prevItem = getExpandedItem()  

    if(!prevItem){
      setChildList({error:true})
      return;
    }
    setChildList({loading:true,data:page===1?undefined:childList.data})
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(mapSearchObjectName(prevItem.data.obj.object_type_txt||prevItem.data.obj.object_type, prevItem.data.obj.code_type_txt))}/${prevItem.data.obj.id}/lineage`,{
          params:{
            page,
            per_page:10,
            direction,
            include_inactive:mapControls.isShowActiveOnly?false:true,
            include_reference:mapControls.isShowReference?true:false,
            'search.name':searchVal||undefined,
            ['sort.'+sortVal.split(':')[0]]:sortVal.split(':')[1]
          }
        }
      )
      .then(response=>{
        if(!searchVal.trim() && page===1){
          setTotalCount(response.data.total)
        }
        if(response.data.total===0){
          setChildList({data:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{params:{
              q:"*",
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              rows:response.data.items.length
            }}
          )
          .then(solrRes=>{
            let items = response.data.items.map(e=>({
              ...e,
              ...(solrRes.data.response.docs.find(d=>d.id===e.id)||{})
            }))
            setChildList({
              data:{
                ...response.data, 
                items:page===1?items:childList.data.items.concat(items)
              }
            })
          })
          .catch(error=>{
            console.log(error)
            setChildList({error:true})
          })
      })
      .catch(error=>{
        console.log(error)
        setChildList({error:true})
      })
  }

  const shouldLoadMore = () => {
    if(childList.loading || childList.error || !childList.data) return false;
    return childList.data.page < childList.data.pages;
  }

  useEffect(()=>{
    if(shouldLoadMore() && listRef.current){
      if(listRef.current.scrollHeight<=listRef.current.clientHeight){
        loadChildList({page:childList.data.page+1})
      }
    }
  // eslint-disable-next-line
  },[childList.data, childList.loading])

  useEffect(()=>{
    if(selectedLevel){
      setSearchValue('')
      setSort('score:DESC')
      loadChildList({page:1,searchVal:'',sortVal:'score:DESC'})
    }
    // eslint-disable-next-line
  },[selectedLevel])


  const onChangeSort = value => {
    setSort(value)
    loadChildList({page:1, sortVal:value})
  }

  const onChangeSearch = value => {
    setSearchValue(value)
    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(()=>{
      loadChildList({page:1, searchVal:value})
    },350)
  }


  const onClickShowInSwimlane = () => {

    let nodesToRemove = selectedGroups.filter(e=>!selectedItems.includes(e.data.obj.id))
    let nodesToAdd = childList.data.items.filter(e=>selectedItems.includes(e.id) && !selectedGroups.find(g=>g.data.obj.id===e.id))
    
    if(nodesToRemove.length>0){
      removeGroupFromSwimlane({
        groups:nodesToRemove,
        currentGroups,
        targetNode:getExpandedItem(),
      })
    }
    if(nodesToAdd.length>0){
      addAditionalGroupsIntoSwimlane({
        groups:nodesToAdd,
        currentGroups,
        currentLinks,
        currentChildrenMap:childrenMap,
        mapControls,
        targetNode:getExpandedItem(),
        direction
      })
    }

    setSelectedItems([])
    setIsSelectAll(false)
    sendMessage({lineage_alert_message:`Map updated`})
    sendMessage({lineage_update_map:true})
  }

  const onAddToCart = () => {
    let canAddToCartItems = childList.data.items.filter(e=>selectedItems.includes(e.id) && checkCanAddToCart({sessionData, objectType:e.object_type}));
    setAddToCartIds(canAddToCartItems.map(e=>e.id))
    setSelectedItems([])
    setIsSelectAll(false)
  }

  const checkIsVisible = el => {
    return selectedGroups.find(g=>g.data.obj.id===el.id)
  }

  const getVisibilityButton = el => {
    let isVisible = selectedGroups.find(g=>g.data.obj.id===el.id)
    return (
      <KTooltip title={`${isVisible?'Hide':'Show'} in swimlane`}>
        <IconButton
          className={classes.iconButton}
          style={{marginRight:4}}
          onClick={(event)=>{
            event.stopPropagation();
            if(isVisible){
              if(selectedGroups.length===1){
                sendMessage({lineage_alert_message:`At least one parent should be selected`})
                return;
              }
              removeGroupFromSwimlane({
                groups:selectedGroups.filter(g=>g.data.obj.id===el.id),
                currentGroups,
                targetNode:getExpandedItem(),
              })
            }else{
              addAditionalGroupsIntoSwimlane({
                groups:[el],
                currentGroups,
                currentLinks,
                currentChildrenMap:childrenMap,
                mapControls,
                targetNode:getExpandedItem(),
                direction
              })
              // childrenMap[selectedItem.id] = childrenMap[selectedItem.id]||[]
              // let nodes = constructChildNodes({
              //   childNodes:[el],
              //   groupNode:selectedItem, 
              //   detailMap:{}, 
              //   direction:drawerOpen.direction
              // });
              // addAdditionalNodesIntoGroup({nodes, targetGroup:selectedItem, childrenMap, currentGroups})
            }
            sendMessage({lineage_update_map:true})
          }}
        >
          {
            isVisible?
            getIconComponent({label:"visibility_on",size:24,colour:theme.palette.primary.main}):
            getIconComponent({label:"visibility_off",size:24,colour:theme.palette.primaryText.light})
          }
        </IconButton>
      </KTooltip>
    )
  }


  const viewOptions = [
    {value:"visibility",label:"Visibility"},
    {value:"trust",label:"Trust"},
  ]

  const onChangeTagView = view => {
    setTagView(view)
    if(view==='trust'){
      onChangeSort('score:DESC')
    }
  }

  const generateSortIcon = () => {
    if(!['trust'].includes(tagView))return <></>
    let ascIcon = getIconComponent({label:'triangle_up',size:20,colour:theme.palette.primaryText.main})
    let descIcon = getIconComponent({label:'triangle_down',size:20,colour:theme.palette.primaryText.main})
    let icon, onClick;

    if(tagView==='trust'){
      if(sort==='score:ASC'){
        icon = ascIcon;
        onClick = ()=>onChangeSort('score:DESC')
      }else if(sort==='score:DESC'){
        icon = descIcon;
        onClick = ()=>onChangeSort('score:ASC')
      }
    }

    return <IconButton className={classes.iconButton} style={{marginLeft:4}} onClick={onClick}>{icon}</IconButton>
  }
  
  
  if(!drawerOpen)return <></>
  
  return (
    <Drawer anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        selectedLevel && 
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('objectDrawerWidth',paperWidth+diff)
            }}
          />
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:16}}>
            <div style={{flexGrow:1}}></div>
            <IconButton 
              onClick={()=>setDrawerOpen(false)} 
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          <div 
            className={classes.customScroll} 
            ref={listRef}
            onScroll={e=>{
              e.stopPropagation();
              if(shouldLoadMore() && e.target.scrollTop+e.target.clientHeight>=e.target.scrollHeight){
                loadChildList({page:childList.data.page+1})
              }
            }}
            style={{paddingLeft:24}}
          >
            <div style={{position:"sticky",top:0,zIndex:10,background:theme.palette.background.main}}>
              <Typography className={classes.header}>
                Edit {direction.toLowerCase()} level {selectedLevel} swimlane view
              </Typography>
              <Typography className={classes.sectionTitle}>
                {
                  isNaN(totalCount)?
                  `${groupCount} item(s) visible in swimlane`:
                  `${groupCount} of ${totalCount} parent item(s) visible in swimlane`
                }
              </Typography>

              <div style={{display:'flex',alignItems:'center',marginBottom:24,justifyContent:"space-between"}}>
                <InputBase
                  value={searchValue}
                  onChange={e=>{
                    onChangeSearch(e.target.value)
                  }}
                  placeholder='Search'
                  className={classes.inputBase}
                  endAdornment={
                    <IconButton 
                      disabled={searchValue===''} 
                      onClick={()=>onChangeSearch('')}
                      style={{width:32,height:32,marginRight:6}}
                    >
                      {getIconComponent({label:searchValue===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  }
                />
              </div>

              <div style={{display:'flex',marginBottom:26}}>
                <Button onClick={onClickShowInSwimlane} color='primary' variant="contained" disabled={selectedItems.length===0}>
                  SEE IN SWIMLANE
                </Button>
                <Button style={{marginLeft:8}} onClick={onAddToCart} color='primary' variant="contained" disabled={selectedItems.length===0}>
                  ADD TO CART
                </Button>
              </div>


              {
                childList.data?.items?.length>0 && 
                <div style={{display:'flex',alignItems:'center',paddingLeft:16,paddingBottom:8}}>
                  <Checkbox
                    style={{
                      color:isSelectAll?theme.palette.secondary.main:theme.palette.primaryText.light,
                      padding:0,
                      marginRight:16,
                    }}
                    checked={isSelectAll}
                    onClick={event=>{
                      event.stopPropagation();
                      if(isSelectAll){
                        setSelectedItems([])
                      }else{
                        setSelectedItems(childList.data.items.map(e=>e.id))
                      }
                      setIsSelectAll(!isSelectAll)
                    }}
                  />
                  <Typography className={classes.columnHeader} style={{flexGrow:1,marginRight:16}}>NAME</Typography>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <Typography 
                      className={classes.columnHeader} 
                      onClick={(event)=>{
                        setViewSelectorAnchor(viewSelectorAnchor?false:event.currentTarget)
                      }}
                      style={{textDecoration:'underline',cursor:'pointer',textTransform:'uppercase'}}
                    >
                      {viewOptions.find(e=>e.value===tagView)?.label}
                    </Typography>
                    {
                      generateSortIcon()
                    }
                    <Popper open={Boolean(viewSelectorAnchor)} anchorEl={viewSelectorAnchor} placement='bottom-end'>
                      <ClickAwayListener onClickAway={()=>setTimeout(()=>setViewSelectorAnchor(false))}>
                        <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                          <MenuList className={classes.listContainer}>
                            {
                              viewOptions.map(el=>(
                                <MenuItem
                                  onClick={()=>{
                                    setViewSelectorAnchor(false)
                                    onChangeTagView(el.value)
                                  }}
                                  className={classes.menuItem}
                                >
                                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>{el.label}</Typography>
                                </MenuItem>
                              ))
                            }
                          </MenuList>
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </div>
                </div>
              }
            </div>

            <div>
              {
                childList.data?.items?.map(el=>(
                  <>
                    <ContextMenuTrigger id={el.id}>
                      <div 
                        className={classes.listItem + ' ' + classes.cartItem}
                        style={{
                          background:selectedGroups.find(g=>g.data.obj.id===el.id)?theme.palette.primary.main+'10':undefined
                        }}
                      >
                        <div className={classes.iconWrapper}>
                          {
                            <Checkbox 
                              data-test-classname="select-checkbox"
                              className='select-checkbox'
                              style={{
                                width:selectedItems.includes(el.id)?24:undefined,
                                height:24,
                                padding:0,
                                color: selectedItems.includes(el.id)?theme.palette.secondary.main:theme.palette.primaryText.light,
                                float:'left',
                                overflow:'hidden'
                              }}  
                              checked={selectedItems.includes(el.id)}
                              onClick={event=>{
                                event.stopPropagation();
                                if(selectedItems.includes(el.id)){
                                  setIsSelectAll(false)
                                  setSelectedItems(selectedItems.filter(e=>e!==el.id))
                                }else{
                                  setSelectedItems([...selectedItems,el.id])
                                }
                              }}
                            />
                          }
                          <div 
                            style={{
                              width:selectedItems.includes(el.id)?0:undefined,
                              height:24,
                              overflow:selectedItems.includes(el.id)?'hidden':undefined,
                            }} 
                            className='item-icon'
                          >
                            {
                              addBadgeToIcon({
                                icon: getIconComponent({label:getIconLabel({label:el.object_type,item:el}),size:24,colour:checkIsVisible(el)?theme.palette.primary.main:theme.palette.primaryText.light}),
                                modification_badge: el.modification_badge_txt,
                                active: el.active_txt,
                                opacity: checkIsVisible(el)?undefined:0.5
                              })
                            }
                          </div> 
                        </div>
                        {/* <div style={{width:24,height:24,marginRight:16}}>
                          {getIconComponent({label:el.object_type,size:24,colour:theme.palette.primary.main})}
                        </div> */}
                        <div style={{overflow:'hidden',flexGrow:1,marginRight:16}}>
                          <KTooltip title={getDispFields(el, 'dispTitle')}>
                            <Typography className={classes.listItemTitle} style={{color:checkIsVisible(el)?undefined:theme.palette.primaryText.light}}>
                              {getDispFields(el, 'dispTitle')}
                            </Typography>
                          </KTooltip>
                          <KTooltip title={getDispFields(el, 'dispSubtitle')}>
                            <Typography className={classes.listItemSubTitle}>
                              {getDispFields(el, 'dispSubtitle')}
                            </Typography>
                          </KTooltip>
                        </div>
                        {tagView==='visibility' && getVisibilityButton(el)}
                        {tagView==='trust' && isShowTrust(el.object_type) && getTrustScoreIcon({trustScore:el.score, size:24})}
                      </div>
                    </ContextMenuTrigger>
                    <ContextMenu 
                      id={el.id}
                    >
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </>
                ))
              }
              {
                childList.loading && 
                <div style={{display:"flex",justifyContent:'center'}}>
                  <CircularProgress color='secondary'/>
                </div>
              }
              {
                childList.error && 
                <Typography>Error occurred loading items</Typography>
              }
              {
                childList.data?.items?.length===0 &&
                <Typography>No items found</Typography>
              }
            </div>
          </div>

          <CartAdder
            objectIds={addToCartIds}
            onFinish={()=>{setAddToCartIds()}}
          />

        </>
      }
      
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(SwimlaneDrawer));
