import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router'
import { withTheme, withStyles, Typography, Paper, MenuItem } from '@material-ui/core';
import { getIconComponent, onClickResultItem, mapSearchObjectName, sendMessage } from '../../../utilities'
import ListModalAdder from '../ListModalAdder/ListModalAdder';
import GlossaryModalAdder from '../GlossaryAdder/GlossaryModalAdder'
import { useStore } from 'react-redux';
import { checkCanAddToCart, checkTermLinkable } from '../../../permissionChecker';
import axiosCerebrum from '../../../axios-cerebrum';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    background:theme.palette.contextMenuBackground.main,
    borderRadius:4,
    minWidth:300,
    padding:'8px 0',
  },
  listText:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    marginLeft:19,
    paddingRight:8
  },
  menuItem:{
    paddingTop:8,
    paddingBottom:8,
    borderRadius:4
  },
  standaloneItemContainer:{
    padding:'0 0 4px',
    margin:'4px 0',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  standaloneBottomItemContainer:{
    padding:'4px 0 0',
    margin:'4px 0 0',
    borderTop:`1px solid ${theme.palette.listItemDivider.main}`
  }
})

function ContextMenu(props) {

  const {
    classes,
    theme,
    item,
    itemLabel,
    itemUrl,
    history,
    actions,
    customActions,
    onActionClicked,
    isHidden
  } = props;
  
  const [modalOpen, setModalOpen] = useState(false)
  // const [subMenuAnchor, setSubMenuAnchor] = useState()
  const [selectedAction, setSelectedAction] = useState()

  let sessionData = useStore().getState().auth.session_user;
  let termLinkable = checkTermLinkable({sessionData})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  // useEffect(()=>{
  //   setSubMenuAnchor()
  //   setSelectedAction()
  // },[item])

  const onButtonClicked = () => {
    // window.scroll(0,1)
    // window.scroll(0,-1)
    onActionClicked?.()
  }
  
  if(!actions || actions.length===0)return <div></div>
  
  let label = itemLabel;
  let collection,id;
  if(!itemUrl && item){
    if(item.labels)label = item.labels.toLowerCase();
    if(item.object && item.object.name)label = item.object.name.toLowerCase();
    if(item.object_type_txt)label = item.object_type_txt.toLowerCase();
    if(item.source_template)label='source'
    if(!label && item.type)label = item.type.toLowerCase();
    if(label==='collection_instance')collection='collections';
    id = item.id;
  }
  
  let collectionName, collectionType;
  if(item && label==='collection_instance'){
    collectionName = item.collection_txt || item.collection_name || item.parent_name
    collectionType = item.collection_type_txt;
    if(item.parent){
      collectionName = item.parent.name
      collectionType = item.parent.category
    }
  }
  if(collectionName)collectionName = collectionName.toLowerCase()
  if(collectionType)collectionType = collectionType.toLowerCase()
  
  const onAddToCart = () => {
    axiosCerebrum
      .post(
        `/api/me/cart`,{
          object_ids:[item.id]
        }
      )
      .then(response=>{
        sendAlert({message:'Successfully added item to Cart',type:'info'})
        sendMessage({reloadCart:true})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred adding item to Cart',type:'error'})
      })
  }

  const addToCartComponent = ( 
    <MenuItem 
      className={classes.menuItem}
      // button 
      onClick={(event)=>{
        event.preventDefault();
        event.stopPropagation();
        onAddToCart();
        onButtonClicked()
      }}
    >
      {getIconComponent({label:'add_cart',size:24,colour:theme.palette.primaryText.dark})}
      <Typography className={classes.listText}>Add to Cart</Typography> 
    </MenuItem>
  )

  const generateCustomAction = el => {
    if(el.id==='addToCart'){
      return addToCartComponent;
    }
    return (
      <MenuItem 
        className={classes.menuItem}
        button 
        onClick={(event)=>{
          event.preventDefault();
          event.stopPropagation();
          el.onClick({item})
          onButtonClicked()
        }}
      >
        {getIconComponent({label:el.iconLabel,size:24,colour:theme.palette.primaryText.dark})}
        <Typography className={classes.listText}>{el.name}</Typography> 
      </MenuItem>
    )
  }
  
  return (
    <>
      {
        !modalOpen && !isHidden && 
        <Paper className={classes.root}>
          {
            actions.includes('open_new_tab') && (item || itemUrl) && 
            <div className={classes.standaloneItemContainer}>
              <MenuItem 
                className={classes.menuItem}
                button 
                onClick={(event)=>{
                  event.preventDefault();
                  event.stopPropagation();
                  if(itemUrl)window.open(itemUrl,'_blank')
                  else{onClickResultItem({history,label,collection,id,item,newWindow:true})}
                  onButtonClicked()
                }}
              >
                {getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.dark})}
                <Typography className={classes.listText}>Open in new tab</Typography> 
              </MenuItem>
            </div>
          }
          {
            customActions && item && customActions.filter(el=>el.isStandalone).map(el=>(
              <div key={el.name} className={classes.standaloneItemContainer} style={{borderBottom:el.hideBottomBorder?'none':undefined,margin:el.customMargin}}>
                {
                  generateCustomAction(el)
                }
              </div>
            ))
          }
          {
            checkCanAddToCart({sessionData,objectType:(label||'').toUpperCase()}) && !customActions?.find(c=>c.id==='addToCart') &&
            <div className={classes.standaloneItemContainer}>
              {addToCartComponent}
            </div>
          }
          {
            customActions && item && customActions.filter(el=>el.id==='link_to_collection').map(el=>(
              <div key={el.name} className={classes.standaloneBottomItemContainer} style={{borderTop:'none',margin:0}}>
                {
                  generateCustomAction(el)
                }
              </div>
            ))
          }
          {
            actions.includes('bookmark') && 
            item && 
            collectionName!=='list' && 
            collectionType!=='list' &&
            label!=='collection' &&
            <MenuItem 
              className={classes.menuItem}
              button 
              onClick={(event)=>{
                event.preventDefault();
                event.stopPropagation();
                setModalOpen(true)
                setSelectedAction('bookmark')
                onButtonClicked()
              }}
            >
              {getIconComponent({label:'add_list',size:24,colour:theme.palette.primaryText.dark})}
              <Typography className={classes.listText}>Add to Lists</Typography> 
            </MenuItem>
          }
          {
            actions.includes('glossary') && item && termLinkable &&
            <MenuItem 
              className={classes.menuItem}
              button 
              onClick={(event)=>{
                event.preventDefault();
                event.stopPropagation();
                setModalOpen(true)
                setSelectedAction('glossary')
              }}
            >
              {getIconComponent({label:'glossary',size:24,colour:theme.palette.primaryText.dark})}
              <Typography className={classes.listText}>Link to Glossary Term</Typography> 
            </MenuItem>
          }
          {
            customActions && item && customActions.filter(el=>!el.isStandalone && !el.isStandaloneBottom && el.id!=='link_to_collection').map(el=>(
              generateCustomAction(el)
            ))
          }
          {
            customActions && item && customActions.filter(el=>el.isStandaloneBottom && el.id!=='link_to_collection').map(el=>(
              <div key={el.name} className={classes.standaloneBottomItemContainer} style={{borderTop:el.hideTopBorder?'none':undefined,margin:el.hideTopBorder?0:undefined,padding:el.hideTopBorder?0:undefined}}>
                {
                  generateCustomAction(el)
                }
              </div>
            ))
          }
        </Paper>
      }
      {
        item && collectionName!=='list' && selectedAction==='bookmark' && 
        <ListModalAdder
          object={item}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      }
      {
        item && selectedAction==='glossary' && 
        <GlossaryModalAdder
          object={{
            name:item.name_txt || item.name,
            id:item.id,
            object:{name:mapSearchObjectName(label, item.code_type_txt)}
          }}
          history={history}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      }
    </>
  )

}

ContextMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  customActions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    iconLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isStandalone: PropTypes.bool
  })),
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  itemUrl: PropTypes.string,
  itemLabel: PropTypes.string,
  onActionClicked: PropTypes.func
}

export default withTheme()(withStyles(styles)(withRouter(ContextMenu)));