import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/MyNotices/Body/Body';
import 'url-search-params-polyfill';
import { setInitialState } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { addHistory } from '../../HistoryManager';

const styles = theme => ({
});

const initialState = {
  tabState:0,
};

function reducer(state, action) {
  switch (action.type) {
    case  "set_tab_state":
      return {
        ...state,
        tabState:action.tabState
      }
    default:
      console.log(action)
      throw new Error("Reducer action not supported.", action);
  }
}

const MyNotices = props => {
  const {
    history,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  const [state, dispatch] = useReducer(reducer,setInitialState(pageCache,initialState));

  useEffect(()=>{
    if(!state)return;
    storePageCache({
      cacheID:window.location.href,
      ...state,
    })
  // eslint-disable-next-line
  },[state])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:'my_ecosystem', 
      title: 'Notices', 
      subTitle:`Notices`,
      type:'application',
    })
  },[])


  return (
    <ProfileLayout
      header={
        <div>
          <ProfileHeader
            title="My Notices"
            subtitle=" "
            label='warning_notice'
            minHeight={100}
          />
        </div>}
      // tabBar={
      //   <TabBar
      //     tabOptions={tabOptions}
      //     tabState={state.tabState}
      //     setTabState={value => {dispatch({type:'set_tab_state',tabState:value})}}
      //     minWidth={200}
      //     maxWidth={200}
      //     disableUnderline={true}
      //   />
      // }
      body={
        <Body
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
        />
      }
    />
  )
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyNotices));