import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, Typography, withStyles, Button, Modal, Paper, CircularProgress, Divider } from '@material-ui/core';
import { collectionIds, formatTimeToUtc, getDispFields, getFontColourByBackground, getLabelPlural, mapObjectName, mapSearchObjectName, onClickResultItem, sendMessage, toTitleCase } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import { checkFilled, EditFields } from '../CollectionInstanceEditFields/CollectionInstanceEditFields';
import SearchSelector from '../SearchSelector/SearchSelector';
import { useStore } from 'react-redux'
import { CerebrumLongListLoader, SolrLongListLoader } from '../../../LongListLoader';
import moment from 'moment';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { getIconLabel } from '../SearchResults/utils';

const styles = theme => ({
  modalContainer: {
    width: 880,
    maxWidth: '85vw',
    margin: 'auto',
    marginTop: '15vh',
    outline: 'none',
  },
  formBody: {
    // minHeight:'60vh',
    paddingLeft: 24,
    paddingBottom: 8,
    paddingRight: 24,
    paddingTop: 24,
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`,
    overflow: 'hidden'
  },
  modalTitle: {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 8,
    wordBreak: 'break-word'
  },
  listContainer: {
    maxHeight: '35vh',
    minHeight: 36,
    overflow: 'auto',
    marginTop: 8,
    marginBottom: 24,
    ...theme.components.customScroll
  },
  normalText: {
    color: theme.palette.primaryText.main
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 47,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
    overflow: 'hidden'
  },
  captionTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    letterSpacing: 2
  },
  chip: {
    height: 24,
    borderRadius: 12,
    fontSize: 13,
    padding: '0px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8
  }
})

function WarnigModalAdder(props) {

  const {
    theme,
    classes,
    object,
    modalOpen,
    setModalOpen,
  } = props;

  let objectLabel = object.object_type_txt || object.labels;
  if (object.object) objectLabel = object.object.name;
  objectLabel = mapSearchObjectName(objectLabel, object.code_type_txt)

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const [isUpdated, setIsUpdated] = useState(false)

  const [linkedWarning, setLinkedWarning] = useState()
  const [myWarnings, setMyWarnings] = useState()

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [searchValue, setSearchValue] = useState('')
  const modalStates = ['link', 'create']
  const [modalState, setModalState] = useState(modalStates[0])

  const [warningTemplateData, setWarningTemplateData] = useState()
  const [values, setValues] = useState({});
  const [expiry, setExpiry] = useState(moment().add(1, 'hour'))

  const [userMap, setUserMap] = useState({})
  const [collectionMap, setCollectionMap] = useState({})

  const loadSolrDetails = data => {
    return new Promise((resolve, reject) => {
      if (data.length === 0) return resolve([])
      SolrLongListLoader({
        url: '/solr/search/select',
        params: {
          q: "*",
          fq: `id:(${data.map(el => el.id).join(' OR ')})`,
          fl: '*',
        },
        rows: 100,
        onFinishLoad: ({ data: solrDetail }) => {
          let enrichedData = data.map(el => {
            let detail = solrDetail.find(l => l.id === el.id) || {}
            return {
              ...el,
              ...detail
            }
          })
          enrichedData = [
            ...enrichedData.filter(l => l.active_txt==='YES'),
            ...enrichedData.filter(l => l.active_txt!=='YES')
          ]
          resolve(enrichedData)
        },
        onError: () => {
          resolve(data)
        }
      })
    })
  }

  const loadLinkedWarnings = () => {
    CerebrumLongListLoader({
      url: `/api/${getLabelPlural(objectLabel)}/${object.id}/related/collections`,
      params: {
        parent_name: 'Warning Notice',
      },
      per_page: 50,
      onStartLoad: () => {
        setLinkedWarning({ loading: true, error: false })
      },
      onFinishLoad: async ({ data }) => {
        let finalData = await loadSolrDetails(data)
        setLinkedWarning({ data: finalData })
      },
      onError: () => {
        setLinkedWarning({ error: true })
      }
    })
  }

  const loadMyWarning = () => {
    CerebrumLongListLoader({
      url: `/api/users/${sessionData.id}/related/collections`,
      params: {
        parent_name: 'Warning Notice',
        relationship: 'MODIFIES,CREATOR_OF',
        sort: 'END_DESC'
      },
      per_page: 50,
      onStartLoad: () => {
        setMyWarnings({ loading: true, error: false })
      },
      onFinishLoad: async ({ data }) => {
        let finalData = await loadSolrDetails(data)
        setMyWarnings({ data: finalData })
      },
      onError: () => {
        setMyWarnings({ error: true })
      }
    })
  }

  const loadwarningTemplateData = () => {
    setWarningTemplateData({ loading: true })
    axiosCerebrum
      .get(`/api/collections/${collectionIds.warningNotice}`)
      .then(response => {
        setWarningTemplateData({ data: response.data })
      })
      .catch(error => {
        setWarningTemplateData({ error: true })
      })
  }

  useEffect(() => {
    if (modalOpen) {
      loadLinkedWarnings({})
    }
    if (modalOpen && (!myWarnings || myWarnings.error)) {
      loadMyWarning({})
    }
    if (!modalOpen && isUpdated) {
      sendMessage({ reload_warning_notice: true })
      setIsUpdated(false)
    }
    // eslint-disable-next-line
  }, [modalOpen, modalState, isUpdated])

  useEffect(() => {
    if (modalState === modalStates[1] && (!warningTemplateData || warningTemplateData.error)) {
      loadwarningTemplateData()
    }
    // eslint-disable-next-line
  }, [modalState])

  const setModifiedUser = (el) => {
    axiosCerebrum
      .put(`/api/me/follows/${el.id}?type=OPT_IN`)
      .catch(error => {
        console.log(error)
      })
    if (myWarnings && myWarnings.data && myWarnings.data.find(l => l.id === el.id)) return;
    axiosCerebrum
      .put(
        `/api/collectioninstances/${el.id}/related?relationship=MODIFIED_BY&object_id=${sessionData.id}`
      )
      .catch(error => {
        console.log(error)
      })

  }


  const onAddWarning = (el, onFail) => {
    axiosCerebrum
      .put(`/api/collectioninstances/${el.id}/related?object_id=${object.id}&relationship=MEMBERS`)
      .then(response => {
        if (linkedWarning && linkedWarning.data) {
          setLinkedWarning({ data: [{ ...el, ...response.data }, ...linkedWarning.data] })
        }
        setIsUpdated(true)
        axiosCerebrum
          .put(`/api/me/follows/${el.id}?type=OPT_IN`)
          .catch(error => {
            console.log(error)
          })
        setModifiedUser(el)
      })
      .catch(error => {
        let msg = `Error occurred adding the warning, please try again`
        if (error.response && error.response.status && error.response.status === 409) {
          msg = 'The warning is already added'
        }
        onFail?.()
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const onRemoveWarning = (el) => {
    axiosCerebrum
      .delete(`/api/collectioninstances/${el.id}/related?object_id=${object.id}&relationship=MEMBERS`)
      .then(response => {
        if (linkedWarning && linkedWarning.data) {
          setLinkedWarning({ data: linkedWarning.data.filter(l => l.id !== el.id) })
        }
        setIsUpdated(true)
        setModifiedUser(el)
      })
      .catch(error => {
        let msg = `Error occurred deleting the warning, please try again`
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const onCreateWarning = () => {
    axiosCerebrum
      .post(
        `/api/collectioninstances`,
        {
          "collection_id": collectionIds.warningNotice,
          "description": (values['description'] || '').replace(/^(<br>|\s)*/, '').replace(/(<br>|\s)*$/, ''),
          "name": values['name'],
          "properties": {},
          "expiry": formatTimeToUtc(expiry),
          "source_id": 1000
        }
      )
      .then(response => {
        axiosCerebrum
          .put(`/api/me/follows/${response.data.id}?type=OPT_IN`)
          .catch(error => {
            console.log(error)
          })
        setValues({})
        setModalState(modalStates[0])
        onAddWarning(response.data, () => {
          if (myWarnings && myWarnings.data) {
            setMyWarnings({ data: [{ ...response.data, isNew: true }, ...myWarnings.data] })
          }
        })
      })
      .catch(error => {
        let msg = `Error occurred creating the warning, please try again`
        if(error.response && error.response.status && error.response.status===409){
          msg = 'Error occurred creating the warning. A warning exists with the same name'
        }
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const generateListItem = ({ warning, action }) => {
    let expiry = warning.expiry || warning.expiry_srt
    let isExpired = expiry && moment(expiry).isBefore(moment())
    let expiryBackground = isExpired ? '#BDBDBD' : theme.palette.success.main
    return (
      <SimpleResultItem
        item={warning}
        label={getIconLabel({ label: 'collection_instance', item: warning })}
        title={getDispFields(warning, 'dispTitle')}
        titleColour={theme.palette.primaryText.main}
        onTitleClick={() => onClickResultItem({ item: warning, label: 'collection_instance', newWindow: true, id: warning.id })}
        showUnderline
        tailObject={
          <div style={{ display: 'flex' }}>
            {
              warning.isNew &&
              <div className={classes.chip} style={{ background: '#ff9801', color: '#fff' }}>
                New
              </div>
            }
            {
              expiry &&
              <div className={classes.chip} style={{ background: expiryBackground, color: getFontColourByBackground(expiryBackground) }}>
                {isExpired ? 'Expired' : 'Active'}
              </div>
            }
            {
              action === 'remove' &&
              <Button data-test-id={`list-remove-button-${warning.name.replace(/\s+/g, '-').toLowerCase()}`} color='secondary' variant='outlined' onClick={() => onRemoveWarning(warning)} style={{ marginRight: 8, padding: '2px 8px' }}>REMOVE</Button>
            }
            {
              action === 'add' &&
              <Button data-test-id={`list-add-button-${warning.name.replace(/\s+/g, '-').toLowerCase()}`} color='primary' variant='outlined' onClick={() => onAddWarning(warning)} style={{ marginRight: 8, padding: '2px 8px' }}>ADD</Button>
            }
          </div>
        }
      />
    )
  }

  let title;
  if (modalState === modalStates[0]) title = `Add warning notices`
  if (modalState === modalStates[1]) title = `Create a new warning notice`

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      disableBackdropClick={true}
    >
      <div className={classes.modalContainer}>
        {
          modalState === modalStates[0] &&
          <Paper className={classes.formBody}>
            <Typography className={classes.modalTitle}>{title}</Typography>
            <Button color='secondary' style={{ marginBottom: 16, paddingLeft: 0, paddingRight: 0 }} onClick={() => setModalState(modalStates[1])}>CREATE A NEW WARNING NOTICE</Button>
            <Divider style={{ background: theme.palette.listItemDivider.main }} />
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <SearchSelector
                url='/solr/search/select'
                params={{
                  q: `${searchValue}*`,
                  fq: `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"WARNING NOTICE"` + (linkedWarning && linkedWarning.data && linkedWarning.data.length > 0 ? ` AND -id:(${linkedWarning.data.map(el => el.id).join(' OR ')})` : ''),
                  fl: '*',
                  sort: 'active_srt desc',
                  rows: 20
                }}
                autoFocus
                testID="warning-modal-search"
                removeFLModify={false}
                scrollable
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder='Search for a warning notice'
                onResultClick={el => onAddWarning(el)}
                hideLocationFromItem
              />
            </div>
            <Typography className={classes.captionTitle}>
              ADDED WARNINGS
            </Typography>
            <div className={classes.listContainer}>
              {
                linkedWarning && linkedWarning.loading &&
                <CircularProgress style={{ height: 20, width: 20 }} color='secondary' />
              }
              {
                linkedWarning && linkedWarning.error &&
                <Typography className={classes.normalText}>Error occurred loading warning notices</Typography>
              }
              {
                linkedWarning && linkedWarning.data && linkedWarning.data.length === 0 &&
                <Typography className={classes.normalText}>This item does not have warning notice</Typography>
              }
              {
                linkedWarning && linkedWarning.data && linkedWarning.data.map(el => generateListItem({ warning: el, action: 'remove' }))
              }
            </div>
            {
              linkedWarning && !linkedWarning.loading &&
              <>
                <Typography className={classes.captionTitle}>
                  MY WARNING NOTICES
                </Typography>
                <div className={classes.listContainer}>
                  {
                    myWarnings && myWarnings.loading &&
                    <CircularProgress style={{ height: 20, width: 20 }} color='secondary' />
                  }
                  {
                    myWarnings && myWarnings.error &&
                    <Typography className={classes.normalText}>Error occurred loading warnings</Typography>
                  }
                  {
                    myWarnings && myWarnings.data && (myWarnings.data.length === 0 || myWarnings.data.filter(el => !linkedWarning || !linkedWarning.data || !linkedWarning.data.find(l => l.id === el.id)).length === 0) &&
                    <Typography className={classes.normalText}>No warnings found</Typography>
                  }
                  {
                    myWarnings && myWarnings.data && myWarnings.data.filter(el => !linkedWarning || !linkedWarning.data || !linkedWarning.data.find(l => l.id === el.id)).map(el => generateListItem({ warning: el, action: 'add' }))
                  }
                </div>
              </>
            }
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 36 }}>
              <Button data-test-id="warning-modal-close-button" color='primary' onClick={() => setModalOpen(false)}>CLOSE</Button>
            </div>
          </Paper>
        }

        {
          modalState === modalStates[1] &&
          <Paper className={classes.formBody} style={{ maxHeight: '65vh', overflow: 'auto' }}>
            <Typography className={classes.modalTitle} style={{ marginBottom: 24 }}>{title}</Typography>
            {
              warningTemplateData && warningTemplateData.error &&
              <Typography className={classes.normalText}>Error occurred loading template</Typography>
            }
            {
              warningTemplateData && warningTemplateData.loading &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color='secondary' />
              </div>
            }
            <div className={classes.listContainer} style={{ maxHeight: '50vh', paddingRight: 2 }}>
              {
                warningTemplateData && warningTemplateData.data &&
                <EditFields
                  values={values}
                  properties={warningTemplateData.data.properties}
                  setValues={setValues}
                  collectionMap={collectionMap}
                  setCollectionMap={setCollectionMap}
                  userMap={userMap}
                  setUserMap={setUserMap}
                  collection={warningTemplateData.data}
                  objectLabel={toTitleCase(mapObjectName(objectLabel).replace(/_/g, ' '))}
                  expiry={expiry}
                  setExpiry={setExpiry}
                />
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 36 }}>
              <Button
                data-test-id="warning-modal-create-button"
                color='primary'
                style={{ marginRight: 8 }}
                disabled={!warningTemplateData || !warningTemplateData.data || !checkFilled(warningTemplateData.data.properties, values)}
                onClick={() => onCreateWarning()}
              >
                CREATE
              </Button>
              <Button
                data-test-id="warning-modal-cancel-button"
                color='secondary'
                onClick={() => { setValues({}); setModalState(modalStates[0]); }}
              >
                CANCEL
              </Button>
            </div>
          </Paper>
        }

        <ModalAlert
          setOpen={setAlertOpen}
          open={alertOpen}
          message={alertMessage}
          type={'error'}
        />
      </div>
    </Modal>
  )
}

WarnigModalAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  state: PropTypes.object,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(WarnigModalAdder));