import { collectionIds, getItemChildType, toTitleCase } from "../../utilities"

const generateDataFq = ({stepList}) => {
  switch(stepList.length){
    case 1:
      return `object_type_srt:DATABASE AND source_id_srt:${stepList[0].id}`
    case 2:
      return `object_type_srt:SCHEMA AND hierarchy_parent_id_srt:${stepList[1].id}`
    case 3:
      return `object_type_srt:TABLE AND hierarchy_parent_id_srt:${stepList[2].id}`
    case 4:
      return `object_type_srt:COLUMN AND hierarchy_parent_id_srt:${stepList[3].id}`
    default:
  }
  return 
}

const generateContentFq = ({stepList}) => {
  switch(stepList.length){
    case 2:
      return `object_type_srt:(${stepList[1].id}) AND source_id_srt:${stepList[0].id}`
    case 4:
      if(stepList[1].id==='WORKSPACE'){
        return `object_type_srt:(${stepList[3].id}) AND source_id_srt:${stepList[0].id}`
      }
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id} AND source_id_srt:${stepList[0].id}`
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id} AND source_id_srt:${stepList[0].id}`
  }
}

const generateReferenceFq = ({stepList}) => {
  return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id} AND source_id_srt:${stepList[0].source_id}`
}

const generateGlossaryFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `object_type_srt:COLLECTION AND collection_type_srt:GLOSSARY`
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id}`
  }
}

const generatDomainFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `hierarchy_parent_id_srt:${collectionIds.domain}`
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id}`
  }
}

const generateVerifiedFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `hierarchy_parent_id_srt:${collectionIds.verifiedUseCase}`
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id}`
  }
}

const generateDMFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `collection_type_srt:DATA_MANAGEMENT AND object_type_srt:COLLECTION`
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id}`
  }
}

const generateDGFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `collection_type_srt:DATA_GOVERNANCE AND object_type_srt:COLLECTION`
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id}`
  }
}

const generateTagFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `object_type_srt:TAG`
    default:
  }
}

const generateKnowledgeFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `object_type_srt:COLLECTION AND collection_type_srt:KNOWLEDGE`
    case 1:
      return `hierarchy_parent_id_srt:${stepList[0].id}`
    default:
  }
}

const generateListFq = ({stepList}) => {
  switch(stepList.length){
    case 0:
      return `object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:LIST`
    default:
  }
}

const generatePeopleFq = ({stepList, showDeleted}) => {
  switch(stepList.length){
    case 0:
      return `object_type_srt:TEAM AND -hierarchy_parent_id_srt:[* TO *]` + (showDeleted?'':' AND active_srt:YES')
    default:
      return `hierarchy_parent_id_srt:${stepList[stepList.length-1].id} AND object_type_srt:TEAM` + (showDeleted?'':' AND active_srt:YES')
  }
}

const generateIssueFq = ({stepList}) => {
  switch(stepList.length){
    case 2:
      return `object_type_srt:ISSUE AND issue_severity:"${stepList[1].id}" AND source_id_srt:${stepList[0].id}`
    default:
  }
}

export const generateFq = ({stepList, category, showDeleted}) => {
  switch(category){
    case 'data':
      return generateDataFq({stepList})
    case 'content':
      return generateContentFq({stepList})
    case 'reference sources':
      return generateReferenceFq({stepList})
    case 'glossaries':
      return generateGlossaryFq({stepList})
    case 'domains':
      return generatDomainFq({stepList})
    case 'verified use case':
      return generateVerifiedFq({stepList})
    case 'data management':
      return generateDMFq({stepList})
    case 'data governance':
      return generateDGFq({stepList})
    case 'tags':
      return generateTagFq({stepList})
    case 'knowledge':
      return generateKnowledgeFq({stepList})
    case 'lists':
      return generateListFq({stepList})
    case 'teams':
      return generatePeopleFq({stepList, showDeleted})
    case 'issues':
      return generateIssueFq({stepList})
    default:
  }
}


export const mapStepStringToList =  str => {
  let list = [];
  let splitted = str.split(',')
  splitted.forEach((el,index)=>{
    if(index % 3 === 0){
      list.push({
        name:el,
        id:splitted[index+1],
        source_id:splitted[index+2]
      })
    }
  })
  return list;
}

export const mapStepListToStr =  list => {
  return list.map(e=>`${e.name},${e.id},${e.source_id||''}`).join(',')
}

export const getStepObjectType = ({category, stepList, list}) => {
  switch(category){
    case 'data':
      if(stepList.length===0)return 'Source'
      if(stepList.length===1)return 'Database'
      if(stepList.length===2)return 'Schema'
      if(stepList.length===3)return 'Table'
      if(stepList.length===4)return 'Column'
      break;
    case 'content':
      if(stepList.length===0)return 'Source'
      if(stepList.length===1)return 'Content Type'
      let contentType = stepList[1].id;
      if(stepList.length===2)return toTitleCase(contentType.replace(/_/g,' '))
      if(contentType==='REPORT'){
        return 'SHEET'
      }
      if(contentType==='DATASET'){
        if(stepList.length===3)return 'Dataset Table'
        if(stepList.length===4)return 'Dataset Field'
      }
      if(contentType==='CONTENT_APP'){
        if(stepList.length===3)return 'Report'
        if(stepList.length===4)return 'Sheet'
      }
      if(contentType==='DATA_PIPELINE'){
        return 'Data Pipeline'
      }
      if(!list || !list.docs || list.docs.length===0)return 'Item'
      return toTitleCase(list.docs[0].object_type_txt.replace(/_/g,' '))
    case 'reference sources':
      if(stepList.length===0)return 'Reference Sources'
      if(stepList.length>0){
        return list?.docs?.[0]?.object_type_txt || 'Item'
      }
      break;
    case 'glossaries':
      if(stepList.length===0)return 'Glossary'
      if(stepList.length>0)return 'Term'
      break;
    case 'domains':
      return 'Domain'
    case 'verified use case':
      return 'Use Case'
    case 'data management':
    case 'data governance':
    case 'knowledge':
      if(stepList.length===0)return 'Collection'
      if(stepList.length>0)return 'Instance'
      break;
    case 'tags':
      return 'TAG'
    case 'lists':
      return 'List'
    case 'teams':
      return 'Team'
    case 'issues':
      if(stepList.length===0)return 'Source'
      if(stepList.length===1)return 'Status'
      if(stepList.length===2)return 'Issue'
      break;
    default:
  }
}

export const constructHeader = ({category, stepList, list}) => {
  let type = getStepObjectType({category, stepList, list})
  if(!list || isNaN(list.numFound))return type + '(s)'
  let count;
  count = list.numFound
  return count + ' ' + type + '(s)';
}



export const getChildCountText = item => {
  if(isNaN(item.child_object_count_srt))return ''
  let childType = getItemChildType(item)
  return item.child_object_count_srt + ' ' + childType + '(s)'
}

export const checkShowChildCount = ({category, stepList, list}) => {
  if(!list || !list.numFound)return false;
  if(!list.docs || !list.docs[0])return false;
  if(!getItemChildType(list.docs[0]))return false;
  if(['data'].includes(category) && stepList.length===0)return false;
  if(['content'].includes(category) && stepList.length<=1)return false;
  if(['lists','tags','issues'].includes(category))return false;
  if(['knowledge'].includes(category) && stepList.length>0)return false;
  return true;
}