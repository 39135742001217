import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Popper } from '@material-ui/core';
import CustomMenu from '../../../../ContextMenu/ContextMenu'
import { checkCanAddToCart, checkCollectionLinkable, checkIsBusinessUserOnly, checkProfileEditable } from '../../../../../../permissionChecker';
import { contentItems, dataItems, userItems } from '../../../../../ImpactAssessment/utils';
import { useStore } from 'react-redux';
import { sendMessage } from '../../../../../../utilities';
import { globalListenerRef } from '../../../../../../GlobalListenerRef';
import { nodeTypes } from '../../layoutUtils';

const styles = theme => ({

})

function NodeContextMenu(props) {
  const {
    selectedItem,
    contextMenuOpen,
    setContextMenuOpen,
    isLinkable
  } = props;

  const [anchor, setAnchor] = useState(null);
  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  let isEditable = checkProfileEditable({sessionData})
  let collectionLinkable = checkCollectionLinkable({sessionData})

  let data = selectedItem?.data;
  let objectType = data?.obj?.object_type || data?.obj?.object_type_txt || data?.obj?.object?.name;
  
  useEffect(()=>{
    if(contextMenuOpen){
      let doc = document.getElementById(contextMenuOpen.node_id);
      // get the position of the element  
      let positionInfo = doc.getBoundingClientRect();
      // create an anchor el under document.body
      let el = document.createElement('div');
      el.id = `lineage-cotext-menu-anchor`
      el.style.position = 'absolute';
      el.style.top = positionInfo.top + window.scrollY + 'px';
      el.style.left = positionInfo.right + 32 + window.scrollX + 'px';
      document.body.appendChild(el);
      setAnchor(el)
    }else{
      let el = document.getElementById(`lineage-cotext-menu-anchor`)
      el && el.remove()
      setAnchor()
    }
  // eslint-disable-next-line 
  },[contextMenuOpen])
  
  useEffect(()=>{

    window.removeEventListener('message',globalListenerRef.lineageContextMenuMsgListener)
    globalListenerRef.lineageContextMenuMsgListener = (msg) => {
      if(msg.data.close_control_drawer){
        setAnchor()
      }
    }
    window.addEventListener('message',globalListenerRef.lineageContextMenuMsgListener)
    return ()=>window.removeEventListener('message',globalListenerRef.lineageContextMenuMsgListener)
  },[])

  if(!selectedItem)return <></>

  let isGroup = selectedItem.type===nodeTypes.groupNodeType

  return (
    <Popper style={{zIndex:1290}} key={selectedItem.id} anchorEl={anchor} keepMounted open={Boolean(anchor)} placement="right-start">
      <div
        style={{
          zoom:0.75
        }}
      >  
        <CustomMenu
          item={data.obj}
          actions={[
            'open_new_tab',
            'bookmark',
            'glossary'
          ]}
          isHidden={!anchor}
          onActionClicked={()=>setContextMenuOpen(false)}
          customActions={[
            isEditable && !isGroup? {
              name:'Edit Properties',
              iconLabel:'edit',
              isStandalone:true,
              hideBottomBorder:true,
              customMargin:'0px',
              id:'edit_property',
              onClick:()=>{
                sendMessage({
                  lineage_clicked_node_id:selectedItem.id,
                  parent_node_id:selectedItem.parentNode
                })
                setTimeout(()=>sendMessage({mini_profile_edit:'true'}))
              }
            }:undefined,
            isEditable && isLinkable && !isGroup? {
              name:'Edit Lineage',
              iconLabel:'edit',
              id:'edit_lineage',
              isStandalone:true,
              customMargin:'0px 0px 4px',
              onClick:()=>{
                sendMessage({
                  lineage_clicked_node_id:selectedItem.id,
                  parent_node_id:selectedItem.parentNode
                })
                setTimeout(()=>sendMessage({open_mini_profile_link_modal:true}),500)
              }
            }:undefined,
            checkCanAddToCart({sessionData,objectType})?{
              id:'addToCart'
            }:null,
            collectionLinkable && !isGroup?{
              name:'Add to Collection Instance',
              iconLabel:'collection',
              id:'link_to_collection',
              onClick:()=>{
                sendMessage({
                  lineage_clicked_node_id:selectedItem.id,
                  parent_node_id:selectedItem.parentNode
                })
                setTimeout(()=>sendMessage({mini_profile_link_collection:'true'}))
              }
            }:undefined,
            !isGroup?{
              name:'Add Tag',
              iconLabel:'tag',
              onClick:()=>{
                sendMessage({
                  lineage_clicked_node_id:selectedItem.id,
                  parent_node_id:selectedItem.parentNode
                })
                setTimeout(()=>sendMessage({mini_profile_add_tag:'true'}))
              }
            }:undefined,
            !checkIsBusinessUserOnly({sessionData}) && data.obj?.active_txt==='YES' && [...contentItems,...dataItems,...userItems].includes(objectType) ?
            {
              name:'Assess Downstream Impact',
              iconLabel:'ia_downstream',
              isStandaloneBottom:true,
              onClick:()=>{
                window.open(`/impact_assessment?targetObjectId=${data.obj.id.toLowerCase()}`,'_blank')
              }
            }:null,
            !checkIsBusinessUserOnly({sessionData}) && data.obj?.active_txt==='YES' && [...contentItems,...dataItems,...userItems].includes(objectType) ?
            {
              name:'Assess Upstream Impact',
              iconLabel:'ia_upstream',
              isStandaloneBottom:true,
              hideTopBorder:true,
              onClick:()=>{
                window.open(`/dependency_assessment?targetObjectId=${data.obj.id.toLowerCase()}`,'_blank')
              }
            }:null,
          ].filter(el=>el)}
        />
      </div>
    </Popper>
  )
}

NodeContextMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(NodeContextMenu));