import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import axiosCerebrum from '../../../../axios-cerebrum';
import { EditFields } from '../../../UI/CollectionInstanceEditFields/CollectionInstanceEditFields';
import { collectionIds } from '../../../../utilities';
import moment from 'moment';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 16,
  },
})

function CreateAndLinkToWarning(props) {

  const {
    classes,
    state,
    dispatch,
    // history,
  } = props;

  const instanceValues = state.instanceValues;
  const setInstanceValues = values => dispatch({ type: 'set_instance_values', instanceValues: values })
  const instanceExpiry = state.instanceExpiry || moment().add(1, 'hour');
  const setInstanceExpiry = expiry => dispatch({ type: 'set_instance_expiry', instanceExpiry: expiry })
  const collectionTemplate = state.collectionTemplate;
  const setCollectionTemplate = data => dispatch({ type: "set_collection_template", collectionTemplate: data })

  const [collectionMap, setCollectionMap] = useState({})
  const [userMap, setUserMap] = useState({})

  const loadCollection = () => {
    setCollectionTemplate({ loading: true })
    axiosCerebrum
      .get(`/api/collections/${collectionIds.warningNotice}`)
      .then(response => {
        setCollectionTemplate({ data: response.data })
      })
      .catch(error => {
        setCollectionTemplate({ error: true })
      })
  }

  useEffect(() => {
    if(!collectionTemplate?.data && !collectionTemplate?.loading)loadCollection()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Typography className={classes.header}>
        Create and link to warning notice
      </Typography>
      <>
        {
          collectionTemplate?.loading &&
          <CircularProgress color='secondary' />
        }
        {
          collectionTemplate?.data &&
          <div style={{ maxWidth: 800 }}>
            <EditFields
              values={instanceValues}
              setValues={setInstanceValues}
              properties={collectionTemplate.data.properties}
              collectionMap={collectionMap}
              setCollectionMap={setCollectionMap}
              userMap={userMap}
              setUserMap={setUserMap}
              collection={collectionTemplate.data}
              expiry={instanceExpiry}
              setExpiry={setInstanceExpiry}
            />
          </div>
        }
        {
          collectionTemplate?.error &&
          <Typography>Error occurred loading template</Typography>
        }
      </>
    </div>
  )
}

CreateAndLinkToWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(CreateAndLinkToWarning));