import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Popper, ClickAwayListener } from '@material-ui/core';
import { getDispFields, getIconComponent } from '../../../utilities';
import { checkClassName, checkId } from '../../UI/Editor/utils';

const styles = theme => ({
  root: {
    width: 320,
    height: 56,
    padding: 16,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 3,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  bodyContent: {
    flexGrow: 1,
    overflow: 'hidden',
    userSelect: 'none',
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 10,
    color: theme.palette.primaryText.light
  }
})

function SelectionChip(props) {

  const {
    classes,
    theme,
    id,
    object,
    interactiveBody,
    width,
    height,
    disabled,
    isErrorBorder
  } = props;

  const [anchor, setAnchor] = useState(null)

  return (
    <>
      <ClickAwayListener onClickAway={(event) => !checkClassName(event.target, 'selection-chip-popper') && !checkId(event.target, 'search_selector_popper') && setAnchor(null)}>
        <div
          className={classes.root}
          style={{
            cursor: interactiveBody ? 'pointer' : undefined,
            width,
            height,
            opacity: disabled ? 0.6 : undefined,
            pointerEvents: disabled ? 'none' : undefined,
            border: isErrorBorder ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.listItemDivider.main}`
          }}
          onClick={event => {
            if (interactiveBody) {
              setAnchor(anchor ? null : event.currentTarget)
            }
          }}
          id={id}
        >
          {
            object?.object_type_txt &&
            <div className={classes.icon}>
              {getIconComponent({ label: object.object_type_txt, size: 24, colour: theme.palette.primary.main })}
            </div>
          }
          <div className={classes.bodyContent}>
            <Typography className={classes.title + ' overflow-text'}>
              {getDispFields(object, 'dispTitle')}
            </Typography>
            {
              object?.id &&
              <Typography className={classes.subtitle + ' overflow-text'}>
                {getDispFields(object, 'dispSubtitle')}
              </Typography>
            }
          </div>
          {
            interactiveBody &&
            <div className={classes.icon} style={{ marginRight: 0 }}>
              {getIconComponent({ label: 'triangle_down', size: 24, colour: theme.palette.primaryText.main })}
            </div>
          }
        </div>
      </ClickAwayListener>
      {
        interactiveBody && anchor &&
        <Popper
          open={Boolean(anchor)}
          anchorEl={anchor}
          placement='bottom-start'
          className='selection-chip-popper'
          transition
        >
          {/* <ClickAwayListener onClickAway={() => setAnchor(null)}> */}
          <div style={{ width: 500, marginTop: 6, borderRadius: 3, border: `1px solid ${theme.palette.listItemDivider.main}`, backgroundColor: theme.palette.background.main }}>
            {interactiveBody}
          </div>
          {/* </ClickAwayListener> */}
        </Popper>
      }
    </>
  )
}

SelectionChip.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  interactiveBody: PropTypes.object,
}

export default withTheme()(withStyles(styles)(SelectionChip));