import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, Button } from '@material-ui/core';
import {checkIsHexColour, collectionIds,getDispFields,getIconComponent,onClickResultItem, sendMessage, toTitleCase} from '../../../utilities'
import moment from 'moment'
import {  postSaveAction } from '../../UI/InteractiveInput/Templates';
import InteractiveInput from '../../UI/InteractiveInput/InteractiveInput';
import AddCIModal from '../../UI/InteractiveInput/AddCIModal';
import { templates } from '../../UI/InteractiveInput/Templates';
import axiosCerebrum from '../../../axios-cerebrum'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import EditableInstances from '../../CollectionProfile/EditableInstances.js/EditableInstances';
import InteractiveViewer from '../../UI/InteractiveInput/InteractiveViewer';
import Editor from '../../UI/Editor/Editor';
import PropertyEditor from './PropertyEditor';
import UserChip from '../../UI/Chips/UserChip';
import { checkFilled } from '../../UI/CollectionInstanceEditFields/CollectionInstanceEditFields';
import useAlert from '../../../hooks/useAlert';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getIconLabel } from '../../UI/SearchResults/utils';
import { onUpdateCategory } from '../../../CategoryManager';
import JobTypeMembers from '../JobTypeMembers/JobTypeMembers';

const styles = theme => ({
  linkContainer:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    marginTop:-8,
    // padding:'0px 6px 8px',
    minHeight:47,
    width:'100%',
    // borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    // '&:hover':{
    //   background:theme.palette.hovered.main
    // }
  },
  dropdownChip:{
    height:24,
    display:'flex',
    alignItems:'center',
    borderRadius:12,
    padding:'0px 12px',
    minWidth:20,
    fontSize:13.75,
    background:theme.palette.secondary.main,
    marginRight:8,
    marginBottom:8,
    color:theme.palette.background.main,
    maxWidth:'100%',
    overflow:'hidden',
    textOverflow:"ellipsis",
    whiteSpace:'nowrap'
  },
  tagChip:{
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  urlTag:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.primary.main}`,
    paddingLeft:6,
  },
  urlText:{
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap', 
    marginLeft:8,
    color:theme.palette.primary.main,
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  }
})

function Details(props) {

  const {
    classes,
    history,
    theme,
    dispatch,
    state,
    fetchList,
    linkedFetch,
    editable,
    setModifiedUser,
    isList
  } = props;  
  
  const [values, setValues] = useState({})

  const isCancelledRef = useRef(false)

  const isQuestion = state.basicData.parent?.id===collectionIds.question
  const isLayer = state.basicData.parent?.id===collectionIds.sourceLayer
  const isCategory = state.basicData.parent?.id===collectionIds.category

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    let propertyValueMapping = {}
    state.collectionData.properties.forEach(el=>{
      propertyValueMapping[el.id] = state.basicData.properties[el.id]
    })
    setValues(propertyValueMapping)
  // eslint-disable-next-line
  },[state.basicData])
  
  let displayItems;

  displayItems = state.collectionData.properties.map(el=>({
    ...el,
    content:state.basicData.properties[el.id]
  }))

  if(!displayItems)displayItems = [];

  let showTerms = (['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.basicData.parent.category) || [collectionIds.domain,collectionIds.verifiedUseCase,collectionIds.classification,collectionIds.dqDimensions].includes(state.basicData.parent.id))

  const onSaveProperty = (text, id, callbackFn) => {

    let payload = {};
    payload.name = state.basicData.name;
    payload.description = state.basicData.description;

    let updatedProperty = {};
    Object.keys(state.basicData.properties).filter(el=>state.collectionData.properties.map(p=>p.id).includes(el)).forEach(el=>{
      updatedProperty[el] = state.basicData.properties[el]
    })
    payload.properties = updatedProperty;
    payload.properties[id] = text
    payload.source_id = state.basicData.source_id;

    return axiosCerebrum
      .put(
        `/api/collectioninstances/${state.basicData.id}`,
        payload
      )
      .then((response)=>{
        if(response.data.parent?.id===collectionIds.category){
          onUpdateCategory(response.data)
        }
        // fetchList();
        dispatch({
          type:'set_basic_data',
          basicData:{
            ...state.basicData,
            ...response.data
          }
        })
        callbackFn?.();
        if(isList)setModifiedUser()
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:'Error occurred updating definition, please try again',type:'error'})
      })
  }

  const onSaveDescription = (text) => {

    let payload = {};
    payload.name = state.basicData.name;
    payload.description = text;
    payload.properties = state.basicData.properties;
    payload.source_id = state.basicData.source_id;

    return axiosCerebrum
      .put(
        `/api/collectioninstances/${state.basicData.id}`,
        payload
      )
      .then(()=>{
        fetchList();
        postSaveAction({
          item:state.basicData, 
          initialValue: state.basicData.description, 
          newValue: text, 
          onUpdate:()=>{
            linkedFetch();
            window.postMessage({reload_related_terms:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
          }
        })
        if(isList)setModifiedUser()
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:'Error occurred updating the instance, please try again',type:'error'})
      })
  }

  const renderContent = (content, dataType, dataName, id) => {

    let defaultStyle = {fontSize:13.75,paddingBottom:8,wordBreak:'break-word', color:theme.palette.primaryText.main}
    let emptyDefaultStyle = {fontSize:13.75,paddingBottom:8,wordBreak:'break-word', color:theme.palette.primaryText.light}
    let emptyEl = editable?<Typography style={emptyDefaultStyle}>{`Add ${dataName.toLowerCase()}`}</Typography>:<Typography style={defaultStyle}>{`No ${dataName.toLowerCase()} provided`}</Typography>
    if(dataType==='BOOLEAN'){
      return <Typography style={defaultStyle}>{content?'True':'False'}</Typography>
    }
    if(!content){
      return emptyEl
    }
    if(dataType==='DROPDOWN_SINGLE' && !content){
      return emptyEl
    }
    if(['USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP_MULTI'].includes(dataType) && (!content || content.length===0)){
      return emptyEl
    }
    if(typeof(content)==='string' && (!content || content.trim()==="")){
      return  emptyEl
    }

    if((id==='3' && state.basicData.parent.name==='Question' && typeof(content)==='string')){
      return (
        <div>
          <InteractiveViewer initialValue={content} id={id}/>
        </div>
      )
    }
    if(dataType==='DATE'){
      return ( 
        <Typography style={defaultStyle}>{moment(content).format('DD - MM - YYYY')}</Typography>
      )
    } 
    if(['URL','URL_INTERNAL'].includes(dataType)){
      return (
        <div  
          onClick={()=>window.open(content.slice(0,8)!=='https://'&&content.slice(0,7)!=='http://'?'https://'+content:content)} 
          className={classes.dropdownChip+ ' ' + classes.urlTag+' prevent-inline-edit'}
          style={{width:"max-content"}}
        >
          {
            getIconComponent({label:'link',size:20,colour:theme.palette.primary.main})
          }
          <Typography className={classes.urlText}>
            {content}
          </Typography>
        </div>
        
      ) 
    }
    if(dataType==='DROPDOWN_MULTI'){
      if(content.length===0 || !Array.isArray(content)){
        return  emptyEl
      }else{
        return (
          <div style={{display:'flex',flexWrap:'wrap'}}>
            {
              content.map(el=>(
                <div className={classes.dropdownChip + ' ' + classes.tagChip}>
                  {el}
                </div>
              ))
            }
          </div>
        )
      }
    }
    if(dataType==='DROPDOWN_SINGLE'){
      return (
        <div style={{width:"max-content"}} className={classes.dropdownChip + ' ' + classes.tagChip}>
          {content}
        </div>
      )
    }
    if(['USER_LOOKUP_FILTERED','USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP','USER_LOOKUP_MULTI'].includes(dataType)){
      if(['USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP_MULTI'].includes(dataType) && !Array.isArray(content))return emptyEl
      return (
        <div style={{display:'flex',flexWrap:'wrap'}}>
          {
            (['USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP_MULTI'].includes(dataType)?content:[content]).map(el=>(
              <UserChip
                user={state.userMap && state.userMap[el]?state.userMap[el]:{id:el,name:el}}
                onClick={()=>onClickResultItem({label:'user',id:el,history:history})}
                bottomMargin={8}
              />
            ))
          }
        </div>
      )
    }
    if(['COLLECTION_LOOKUP_FILTERED','COLLECTION_LOOKUP_FILTERED_MULTI'].includes(dataType)){
      if(['COLLECTION_LOOKUP_FILTERED_MULTI'].includes(dataType) && !Array.isArray(content))return emptyEl
      let validValues = (['COLLECTION_LOOKUP_FILTERED_MULTI'].includes(dataType)?content:[content]).filter(el=>state.collectionMap && state.collectionMap[el])
      if(validValues.length===0){
        return emptyEl
      }
      return (
        <div >
          {
            validValues.map(id=>{
              let el = state.collectionMap[id]
              return (
                <SimpleResultItem
                  key={el.id}
                  title={getDispFields(el,'dispTitle')}
                  label={el.object_type_txt}
                  iconLabel={getIconLabel({label:'collection_instance', item:el})}
                  item={el}
                  showUnderline
                  keepPropagation
                  onClick={()=>onClickResultItem({label:'collection_instance',item:el,id:el.id,history:history})}
                />
              )
            })
          }
        </div>
      )
    }
    if(dataType==='COLOUR_SELECTOR' && checkIsHexColour(content)){
      return (
        <Typography style={{fontSize:13.75,paddingBottom:8,whiteSpace:'pre-wrap',wordBreak:'break-word', color:theme.palette.primaryText.main}}>
           <div style={{background:content,borderRadius:2,width:24,height:24,display:'inline-block',position:'relative',top:6,marginRight:4}}></div>{content+''}
        </Typography>
      )
    }
    return <Typography style={{fontSize:13.75,paddingBottom:8,whiteSpace:'pre-wrap',wordBreak:'break-word', color:theme.palette.primaryText.main}}>{content+''}</Typography>
  }

  const onHideEmptySection = () => {
    dispatch({
      type:'set_hide_empty_sections',
      hideEmptySections:!state.hideEmptySections
    })
  }

  const filterDisplayItems = (items) => {
    if(isQuestion)return items;
    if (!state.hideEmptySections) return items
    return items.filter(el=>el.content && el.content.length>0)
  }

  let instanceName = 'INSTANCE'
  if(state.basicData.parent.category==='GLOSSARY')instanceName = 'TERM'
  
  let enableGenAI = ['GLOSSARY','DATA_MANAGEMENT','DATA_GOVERNANCE'].includes(state.basicData.parent.category) || [collectionIds.domain,collectionIds.verifiedUseCase].includes(state.basicData.parent.id)

  const showMoreInfo = !isQuestion && !isCategory && !isLayer

  return (
    <div className={classes.root}>
      <div style={{marginBottom:36,maxWidth:'100%'}}>
        <InteractiveInput
          key={state.basicData.description}
          title={'DESCRIPTION'}
          id="description"
          shouldLoadSuggestedTerms={showTerms}
          object={state.basicData}
          onSave={onSaveDescription}
          enableGenAI={enableGenAI}
          isModal
          initialValue={state.basicData.description}
          triggerChar={templates.linkCI.triggerChar}
          placeholder={editable?`Add description`:`No description provided`}
          disableEditing={!editable}
          enableCustomContextMenu={true}
          generatePopper={({editorRef, setPopperAnchor})=>{
            return (
              <AddCIModal 
                editorRef={editorRef} 
                setPopperAnchor={setPopperAnchor} 
                targetItem={state.basicData}
              />
            )
          }}
        />
      </div>
      {
        showTerms  && 
        <div style={{ marginTop: 60 }} >
          <GlossaryAdder
            title={'RELATED TERMS'}
            state={state}
            dispatch={dispatch}
            history={history}
            forceEditable={editable}
            headerFontSize={16}
          />
        </div>
      }
      {
        (['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.basicData.parent.category) || 
        [collectionIds.domain, collectionIds.verifiedUseCase, collectionIds.classification].includes(state.basicData.parent.id)) &&
        <div style={{marginTop:60,marginBottom:60}}>
          <EditableInstances 
            viewOnly={true} 
            state={state} 
            dispatch={dispatch} 
            history={history} 
            instanceName={instanceName}
            headerFontSize={16}
            title={'INCLUDES'}
            subTitle={`${toTitleCase(instanceName)}(s) that belong to this ${toTitleCase(instanceName)}`}
          />
        </div>
      }
      {
        displayItems.length>0 &&
        <>
          {
            showMoreInfo && 
            <div 
              style={{borderTop:`1px solid ${theme.palette.listItemDivider.main}`,
              marginBottom:24,
              paddingTop:24,
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between'
            }}>
              <Typography style={{fontSize:20}}>MORE INFOMATION</Typography>
              <Button color='primary' onClick={onHideEmptySection}>
                {state.hideEmptySections?'SHOW':'HIDE'} EMPTY SECTIONS
              </Button>
            </div>
          }
          {
            filterDisplayItems(displayItems).sort((a,b)=>a.position-b.position).map(el=>(
              state.basicData.parent.name==="Question" && state.basicData.parent.category==="KNOWLEDGE" && el.id==='3' ?
                <div style={{marginBottom:36,maxWidth:'100%'}}>
                  <InteractiveInput
                    key={el.content}
                    title={el.name.toUpperCase()}
                    shouldLoadSuggestedTerms={false}
                    object={state.basicData}
                    onSave={text=>onSaveProperty(text, '3')}
                    initialValue={el.content}
                    placeholder={editable?`Add ${el.name}`:`No ${el.name} provided`}
                    disableEditing={!editable}
                    isModal
                    titleStyle={{fontSize:showMoreInfo?16:20}}
                  />
                </div>
                :
                <div style={{marginBottom:36}}>
                  <Editor
                    title={el.name.toUpperCase()}
                    subtitle={
                      <span>{el.description} {state.basicData.parent?.id===collectionIds.category && el.id==='2'?<span style={{cursor:'pointer',textDecoration:'underline'}} onClick={()=>window.open('https://docs.kada.ai/home/configuring-critical-collections#Configuringcriticalcollections-Category','_blank')}>See more</span>:''}</span>
                    }
                    triggerEditByButton={el.data_type.includes('COLLECTION_LOOKUP')}
                    maxEditWidth={el.data_type==='TEXT_BOX'?undefined:754}
                    body={
                      <div 
                        style={{
                          paddingTop:8,
                          fontSize:13.75,
                          color:theme.palette.primaryText.main,
                          maxWidth:el.data_type==='TEXT_BOX'?undefined:754,
                          ...(!checkFilled([el],values,true)?{paddingLeft:8,marginLeft:-8,border:`1px solid ${theme.palette.error.main}`,borderRadius:3}:{})
                        }}
                      >
                        {renderContent(el.content,el.data_type,el.name,el.id)}
                      </div>
                    }
                    footer={
                      !checkFilled([el],values,true) && 
                      <Typography style={{fontSize:12,marginTop:4,color:theme.palette.error.main}}>This is a required field.</Typography>
                    }
                    onSave={()=>{
                      onSaveProperty(
                        values[el.id], 
                        el.id,
                        ()=>{
                          if(el.data_type.includes('COLLECTION_LOOKUP')){
                            sendMessage({reload_header_instance:true})
                          }
                        }
                      )
                    }}
                    onClose={()=>{
                      setValues({...values,[el.id]:state.basicData.properties[el.id]})
                    }}
                    isModal={el.data_type==='TEXT_BOX'}
                    disableSaveButton={!checkFilled([el],values,true)}
                    editWindow={
                      <div style={{maxWidth:el.data_type==='TEXT_BOX'?undefined:754}}>
                        {
                          el.data_type==='TEXT_BOX' && 
                          <>
                            <Typography style={{fontSize:20,color:theme.palette.primary.main,marginBottom:24}}>
                              Edit {el.name}
                            </Typography>
                          </>
                        }
                        <PropertyEditor
                          property={el}
                          values={values}
                          setValues={setValues}
                          state={state}
                          dispatch={dispatch}
                        />
                      </div>
                    }
                    disableEditing={!editable}
                    titleStyle={{ color: theme.palette.header.main, fontWeight: '400', fontSize:showMoreInfo?16:20,marginTop:3 }}
                  />
                </div>
            ))
          }
          {
            filterDisplayItems(displayItems).length===0 &&
            <Typography>No details to display</Typography>
          }
        </>
      }
      {
        state.basicData.parent?.id===collectionIds.jobType && 
        <JobTypeMembers
          state={state}
          dispatch={dispatch}
          history={history}
        />
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(Details));