import React from 'react';
import { Tooltip } from '@material-ui/core';

function KTooltip(props) {
  const { children, title, ...other } = props;

  return (
    <Tooltip enterDelay={250} title={title||''} {...other} >
      {children}
    </Tooltip>
  );
}

KTooltip.propTypes = {...Tooltip.propTypes}

export default KTooltip;