import React, {} from 'react';
import { withTheme, withStyles, Typography, Select, MenuItem, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utilities';

const styles = theme => ({
  usageSelector:{
    ...theme.components.titleSelector,
    height:24,
    borderRadius:12,
    '& div div':{
      fontSize:13,
      height:24,
      borderRadius:`12px !important`,
      padding:'0 24px 0 8px',
    },
  },
  disabledSelector:{
    color:`${theme.palette.header.main} !important`
  }
})

const UsageSelector = props => {

  const {
    classes,
    theme,
    labelText,
    selectedUsages,
    onChangeUsage,
    availableUsages,
    removeCount
  } = props;
  
  const usageSelection = [
    {value:'DAILY',name:'Daily'},
    {value:'WEEKLY',name:'Weekly'},
    {value:'MONTHLY',name:'Monthly'},
    {value:'INFREQUENT',name:'Infrequent'},
    {value:'UNUSED',name:'Unused'},
    {value:'NO USAGE',name:'No Usage'}
  ]

  const onUnselectAll = () => {
    if(selectedUsages.length===0){
      onChangeUsage(usageSelection.map(el=>el.value))
    }else{
      onChangeUsage([])
    }
  }
  
  return (
    <div style={{display:'flex',alignItems:'center',marginBottom:24}}>
      <Typography style={{ fontSize:13,marginRight:8,letterSpacing:1}}>{labelText || 'FILTER BY USAGE'}: </Typography>
      <Select
        className={classes.usageSelector}
        value={selectedUsages}
        classes={{disabled:classes.disabledSelector}}
        multiple
        disableUnderline
        renderValue={values=>{
          if(availableUsages.length===0) return 'No usage options available'
          return `Usage: ${availableUsages.every(a=>values.includes(a.val))?'All':values.length===0?'None selected':usageSelection.filter(u=>availableUsages.find(a=>a.val===u.value) && values.includes(u.value)).map(el=>el.value).join(', ')}`
        }}
        displayEmpty
        disabled={availableUsages.length===0}
        // onChange={event=>{
        //   onChangeUsage(event.target.value)
        // }}
      >
        <div style={{display:'flex',justifyContent:'space-between',padding:'0 8px',marginBottom:8,minWidth:200}}>
          <Typography style={{color:theme.palette.primary.main,fontSize:12,letterSpacing:1}}>{availableUsages.filter(a=>selectedUsages.includes(a.val)).length} SELECTED</Typography>
          <Typography onClick={onUnselectAll} style={{color:theme.palette.secondary.main,fontSize:12,letterSpacing:1,cursor:'pointer'}}>{selectedUsages.length===0?'SELECT':'UNSELECT'} ALL</Typography>
        </div>
        <div>
          {
            usageSelection.filter(el=>availableUsages.map(el=>el.val).includes(el.value)).map(el=>(
              <MenuItem 
                value={el.value}  
                onClick={()=>{
                  if(selectedUsages.includes(el.value)){
                    onChangeUsage(selectedUsages.filter(u=>u!==el.value && availableUsages.map(el=>el.val).includes(u) ))
                  }else{
                    onChangeUsage([...selectedUsages,el.value])
                  }
                }}
              >
                <Checkbox color='primary' style={{paddingLeft:0}} checked={selectedUsages.includes(el.value)}/>
                {el.name} {removeCount?'':`(${formatNumber(availableUsages.find(a=>a.val===el.value).count)})`} 
              </MenuItem>
            ))
          }
          {
            usageSelection.filter(el=>availableUsages.map(el=>el.val).includes(el.value)).length===0 &&
            <Typography style={{fontSize:13.75,marginLeft:8}}>No options available</Typography>
          }
        </div>
      </Select>
    </div>
  )
}

UsageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.string.isRequired,
  selectedUsages: PropTypes.array.isRequired,
  onChangeUsage: PropTypes.func.isRequired,
  availableUsages: PropTypes.array.isRequired,
  removeCount: PropTypes.bool
};

export default withTheme()(withStyles(styles)(UsageSelector));