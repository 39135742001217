import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Modal, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { checkClassName } from './utils';

const styles = theme => ({
  root:{
    width: '100%'
  },
  title:{
    display: 'inline-block',
    fontSize: '1.1rem',
    position: 'relative',
    top: '0.1rem',
    color: theme.palette.primaryText.main,
    marginRight:8
  },
  body:{
    ...theme.components.customScroll,
    display: 'inline-block',
    maxWidth:'100%',
    width:'100%',
    marginTop: '1rem',
  },
  horizontalBody:{
    display: 'inline-block',
    position: 'relative',
    top: '0.1rem',
  },
  horizontalTextField:{
    display: 'inline',
    position:'relative',
    bottom: '0.1rem',
  },
  subtextReadOnly:{
    color: theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  disabledButton:{
    color:`${theme.palette.primaryText.light} !important`
  },
  hoverableBody:{
    cursor:'pointer',
    // boxSizing:"border-box",
    borderRadius:3,
    '&:hover':{
      // padding:'0 12px 4px',
      paddingLeft:8,
      marginLeft:-8,
      background:theme.palette.editHovered.main,
    }
  }
})

const Editor = props => {
  const {
    classes,
    theme,
    id,
    title,
    subtitle,
    body,
    footer,
    onSave,
    onClose,
    editWindow,
    triggerEditByButton,
    triggerText,
    titleStyle,
    disableEditing,
    disableSaveButton,
    maxEditWidth,
    hideActionButton,
    defaultEditing,
    buttonAlignment,
    isModal
  } = props;
  
  const [isEditing, setIsEditing] = useState(defaultEditing?true:false);
  const [mouseDownPos, setMouseDownPos] = useState()

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data[`close-editor-${id}`] ){
        setIsEditing(false)
      }
      if(msg.data[`open-editor-${id}`] ){
        setIsEditing(true)
      }
    }
    window.removeEventListener('message',globalListenerRef[`editorListner-${id}`]);
    globalListenerRef[`editorListner-${id}`] = onMsgReceived;
    window.addEventListener("message", globalListenerRef[`editorListner-${id}`]);
    return (()=>{window.removeEventListener('message',globalListenerRef[`editorListner-${id}`]);})
  // eslint-disable-next-line
  },[])


  const handleEditClick = (event) => {
    if(triggerEditByButton)return;
    if(checkClassName(event.target,'inline-chip') || checkClassName(event.target,'text-context-menu') || checkClassName(event.target,'inline-detail-popper') || checkClassName(event.target,'prevent-inline-edit'))return;
    if(event.target.href)return;
    if(event.target.onclick)return;
    if(!mouseDownPos)return;
    if(event.clientX!==mouseDownPos.x || event.clientY!==mouseDownPos.y)return;
    setIsEditing(true);
    setMouseDownPos()
  }

  const handleSaveClick = () => {
    if(onSave)onSave();
    setIsEditing(false);
  }

  const handleClose = () => {
    if(onClose)onClose();
    setIsEditing(false);
  }
  
  let editComp = (
    <div>
      <div className={classes.body + 'editor-body'} style={{marginTop:isModal?0:12,maxWidth:maxEditWidth}}>{editWindow}</div>
      {
        !hideActionButton && 
        <div 
          style={{
            display: 'flex',
            justifyContent:buttonAlignment, 
            marginTop:16,
            marginLeft:isModal?0:15,
            ...(
              isModal?
              {
                position:'sticky',
                bottom:0,
                paddingTop:16,
                paddingBottom:12,
                background:theme.palette.background.main,
              }:
              {}
            )
          }}
        >
          <Button data-test-id="editor-save-button" color='primary' variant='contained'  disabled={disableSaveButton} onClick={handleSaveClick}>SAVE</Button>
          <Button data-test-id="editor-close-button" color='secondary' onClick={handleClose} style={{marginLeft:16}}>CANCEL</Button>
        </div>
      }
    </div>
  )

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <div>
          <Typography className={classes.title} style={titleStyle}>{title}</Typography>
          <Typography className={classes.subtextReadOnly} variant='caption' color = 'textSecondary'>{subtitle}</Typography>
        </div>
        {
          triggerEditByButton && !isEditing && !disableEditing &&
          <Button 
            color='primary'
            onClick={()=>{
              setIsEditing(true)
            }}
          >
            {triggerText || 'EDIT'}
          </Button>
        }
      </div>
      {
        isEditing && !isModal ?
          editComp
          :
          <>
            <div 
              onMouseDown={
                !disableEditing && !triggerEditByButton?
                (event)=> ![1,2].includes(event.button) && setMouseDownPos({x:event.clientX, y:event.clientY})
                :
                undefined
              } 
              onMouseUp={!disableEditing && !triggerEditByButton?handleEditClick:undefined}
              className={classes.body + (disableEditing || triggerEditByButton?'':' ' + classes.hoverableBody)}
              style={{maxWidth:maxEditWidth}}
              data-test-classname="editor-body"
            >
              {body}
            </div>
            {footer}
          </>
      }
      {
        isModal && 
        <Modal 
          open={isEditing} 
          onClose={()=>{
            setIsEditing(false)
            onClose?.()
          }}
          // disableBackdropClick={true}
        > 
          <div style={{margin:'15vh auto 0 auto',width:1000,maxWidth:'95vw'}}>
            <Paper style={{width:'100%',boxSizing:"border-box",maxHeight:'80vh',overflow:'auto',padding:24,paddingBottom:0,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}} data-test-id="editor-modal">
              {editComp}
            </Paper>
            {/* <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            /> */}
          </div>
        </Modal>
      }
    </div>
  )
}

Editor.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  body: PropTypes.object,
  footer: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  editWindow: PropTypes.object,
  titleStyle: PropTypes.object,
  disableSaveButton: PropTypes.bool,
  hideActionButton: PropTypes.bool,
  maxEditWidth: PropTypes.number,
  defaultEditing: PropTypes.bool,
  disableEditing: PropTypes.bool,
  buttonAlignment: PropTypes.string,
  isModal: PropTypes.bool,
  triggerEditByButton: PropTypes.bool,
  triggerText: PropTypes.string,
}



export default withTheme()(withStyles(styles)(Editor));