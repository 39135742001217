import React, { useState, useReducer, useEffect } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/ContentProfile/Body/Body';
import { handleShareClick , setInitialState, getUserRoles, getDispFields, removeUrlQueryArg} from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkEmbedUrlEnabled, checkIsFollowed, checkLineageLinkable, checkProfileEditable, checkTermLinkable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tagModalOpen:false,
  tabState: 0,
  noteTabState:0,
  noteDetailMap:{},
  childSearch:'',
  childSort:'total_usage_srt desc',
  // basic table data
  basicData: null,
  linkedData: null,
  linkedTabState: 0,
  insightsData: null,
  insightsLoading: null,
  insightsError: null,
  // usage
  usageData: null,
  usageError: null,
  usageLoading: null,
  usagePeriod:3,
  usageType:'direct',

  //panel uage data
  usagePanelData: null,
  usagePanelError: null,
  usagePanelLoading: null,
  //
  selectedDate: null,
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],
    downstreamObjects:['TABLE','COLUMN','CONTENT','CONTENT_CHILD','DATASET','DATA_PIPELINE','FILE','QUERY'],
    
    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
  sortBy:'ALPHABETICAL',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_embed_url_modal_open':{
      return {
        ...state,
        embedUrlModalOpen:action.embedUrlModalOpen
      }
    }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_warning_modal_open': {
      return {
        ...state,
        warningModalOpen: action.warningModalOpen
      }
    }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_child_data':
      return {
        ...state,
        childListData:action.childListData,
        childListLoading:action.childListLoading,
        childListError:action.childListError
      }
    case 'set_show_reference_child':
      return {
        ...state,
        showReferenceChild:action.showReferenceChild
      }
    case 'set_show_deleted_child':
      return {
        ...state,
        showDeletedChild:action.showDeletedChild
      }
    case 'set_child_sort':
      return {
        ...state,
        childSort:action.childSort
      }
    case 'set_child_search':
      return {
        ...state,
        childSearch:action.childSearch
      }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_changes':
      return {
        ...state,
        changes:action.changes,
        changesLoading:action.changesLoading,
        changesError:action.changesError
      }
    case 'set_change_date':
      return {
        ...state,
        changeDate:action.changeDate
      }
    case 'set_change_type':
      return {
        ...state,
        changeType:action.changeType
      }
    case 'set_selected_change':
      return {
        ...state,
        selectedChange:action.selectedChange
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    case 'set_linked_data':
      return {
        ...state,
        linkedData: action.linkedData,
      }
    case 'set_linked_tab_state':
      return {
        ...state, linkedTabState: action.linkedTabState
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataLoading: action.basicDataLoading,
        basicDataError:action.basicDataError
      }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case 'set_child_count':
      return {
        ...state,
        childCount:action.childCount
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    case 'set_is_steward_or_owner':{
      return {
        ...state, isStewardOrOwner:action.isStewardOrOwner
      }
    }
    default:
      throw new Error("Reducer action not supported: "+action.type, action);
  }
}



const ContentProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const roles = getUserRoles(sessionData.user_role)


  const getTabOptions = (childCount, data) => {
    let tabOptions = ['DETAILS','KNOWLEDGE', roles.every(el=>el==='90') || data.usage_txt==='UNUSED' ?undefined:'USAGE', 'LINEAGE','ISSUES','CHANGES'].filter(el=>el!==undefined);
    if(!isNaN(childCount) && childCount>0)tabOptions = ['SHEETS',...tabOptions]
    return tabOptions
  }

  let isLineageLinkable = checkLineageLinkable({sessionData, isStewardOrOwner: state.isStewardOrOwner})
  
  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/contents/${match.params.id.toLowerCase()}`
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        if(fullResponse.reference===true){
          !state.basicData && dispatch({type:'set_show_reference_child',showReferenceChild:true})
        }
        if(fullResponse.active_flag === false){
          !state.basicData && dispatch({type:'set_show_deleted_child',showDeletedChild:true})
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...response.data.response.docs[0], ...fullResponse, source_data:sourceData }})
            dispatch({type:'set_child_count',childCount:response.data.response.docs[0]?response.data.response.docs[0].child_object_count_srt:0})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }


  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }


  useEffect(()=>{
    if(!tabName || isNaN(state.childCount) || !state.basicData)return;
    let tabs = getTabOptions(state.childCount,state.basicData)
    if(!tabs.includes(tabName.toUpperCase()))return;
    dispatch({type:'set_tab_state',tabState:tabs.indexOf(tabName.toUpperCase())})
    window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
  // eslint-disable-next-line
  },[state.childCount,tabName,state.basicData])


  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/contents/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC'
        }} 
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:solrRes.data.response.docs},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }


  useEffect(()=>{
    if(!state.basicData){
      fetchList();
      issueFetchList();
    }
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`,{params:{
        object_id:match.params.id.toLowerCase(),
        relationship:'OWNER_OF,STEWARD_OF'
      }}) 
      .then(response=>{
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:response.data.total>0})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:false})
      })
  }

  useEffect(()=>{
    checkIsStewardOrOwner()
   // eslint-disable-next-line
  },[state.insightsData])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.reload_issue){
        issueFetchList()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
      if(!isLineageLinkable)return;
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({
          relations:['SOURCE_OF','SOURCE_FROM'],
          linkableObjects:lineageObjectsMap.CONTENT
        })
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])


  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData  ){
    return <div></div>
  }

  let buttons = [];
    
  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  let optionTitle = []
  let optionList = []


  optionTitle.push('OPEN IN APPLICATIONS')
  optionList.push(
    roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/impact_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_downstream',text:'Assess Downstream Impact',group:'OPEN IN APPLICATIONS'},
    roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/dependency_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_upstream',text:'Assess Upstream Dependencies',group:'OPEN IN APPLICATIONS'}
  )

  if(checkEmbedUrlEnabled()){
    optionTitle.push('EMBEDDED PROFILE')
    optionList.push({
      onClick:()=>{dispatch({type:"set_embed_url_modal_open",embedUrlModalOpen:true})},
      iconLabel:'link',
      text:'Generate embedded profile link',
      group:'EMBEDDED PROFILE'
    })
  }

  buttons.push(
    <DropdownButton
      iconLabel='dot_menu'
      tooltip="More actions"
      iconOnly={true}
      iconColour={theme.palette.primaryText.light}
      title={optionTitle}
      optionList={optionList}
      hideSectionTitle
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )
  
  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick: () => dispatch({ type: 'set_warning_modal_open', warningModalOpen: true }), iconLabel: 'warning', text: 'Add warning notice'},
        {onClick:() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }),iconLabel:'issue',text:'Add Issue'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
        checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined,
      ]}
    />
  )

  if (state.basicData.external_url) {
    buttons.push(
      <ProfileButton
        onClick={() => {
          let win = window.open(state.basicData.external_url, '_blank');
          win.focus();
        }}
        iconLabel='open'
        text="OPEN"
      />
    )
  }

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )


  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.issueData && state.issueData.items.filter(i=>!i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText = `This report has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility = 'visible' ;
  }

  let data = state.basicData;
  let isShell = state.basicData.reference===true;

  if (data && isShell){
    let sourceName = data.source_data?data.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Report is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Report is referenced in ${data.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      }else{
        bannerdisplayText=(
          <Typography>
            This Report is referenced in multiple Tools. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Report is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  if(data && data.active_flag === false){
    bannerdisplayText = 'This report has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this report has been deactivated in K. Information on this report is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }
  
  
  let title = state.basicData.name || state.basicData.signature;
  let tabOptions = getTabOptions(state.childCount, state.basicData)

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','warning','note','additional_property','issue','embed_url']}
        history={history}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            tabOptions={tabOptions}
            type='content'
            title={title}
            shouldLoadBreadCrumb
            label={state.basicData.object.name.toLowerCase()}
            buttons={buttons}
            data={state.basicData}
            history={history}
            state={state}
            dispatch={dispatch}
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            shouldLoadLinkedInstance
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
              })
            }}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={1100/tabOptions.length}
            maxWidth={1100/tabOptions.length}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            basicData={state.basicData}
            // new props
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            sessionData={sessionData}
            setLinkModalOpen={setLinkModalOpen}
            isLineageLinkable={isLineageLinkable}
          />
        }
        disableMinContentHeight={['CHANGES','LINEAGE'].includes(tabOptions[state.tabState])}
        hideSideBar={['LINEAGE','CHANGES','RELATED'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            editable
            fetchList={fetchList}
            cerebrumLabel={'contents'}
            data={state.basicData}
            mapping={['tags','description','category','jobTypes','sourceType','lineage','upstreamSources','downstreamSources','knowledge','domain','verified','verifiedNot','trustscore','classification','channels','owner','stewardedBy','topUsers', 'topTeams', 'lastUpdated','lastUsed', 'createdBy']}
          />
        }
      />
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ContentProfile)));