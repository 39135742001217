import React, {useEffect, useState} from 'react';
import { CircularProgress, withStyles, Typography, Modal, Paper, Button} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import TeamSelector from '../../UI/SearchSelector/TeamSelector';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },
  title:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:24
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:16
  },
  modalBodyText:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:36,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
})

const TeamEdit = props => {

  const {
    classes,
    state,
    dispatch,
    setModalOpen,
    modalOpen
  } = props;

  let user = modalOpen.user || {}
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [teamLoading, setTeamLoading] = useState(false)
  const [teamError, setTeamError] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [addedIds, setAddedIds] = useState([])
  const [removedIds, setRemovedIds] = useState([])

  const [updating, setUpdating] = useState(false)

  const {
    sendAlert
  } = useAlert({})

  const loadLinkedTeams = () => {
    setTeamLoading(true)
    axiosCerebrum
      .get(`/api/users/${user.id}/related`,{
        params:{
          object_name:'GROUP',
          per_page:200,
          relationship:'MEMBER_OF'
        }
      })
      .then(res=>{
        setTeamLoading(false)
        setSelectedTeam(res.data.items)
      })
      .catch(err=>{
        setTeamLoading(false)
        setTeamError(true)
      })
  }

  useEffect(()=>{
    if(modalOpen){
      loadLinkedTeams()
    }else{
      setSelectedTeam()
      setAddedIds([])
      setRemovedIds([])
      setSearchValue('')
      setTeamError(false)
      setTeamLoading(false)
    }
  // eslint-disable-next-line
  },[modalOpen])

  const onSave = () => {
    let promises = [];
    addedIds.forEach(id=>{
      promises.push(
        axiosCerebrum.put(`/api/users/${user.id}/related?object_id=${id}&relationship=MEMBER_OF`)
      )
    })
    removedIds.forEach(id=>{
      promises.push(
        axiosCerebrum.delete(`/api/users/${user.id}/related`,{
          params:{
            object_id:id,
            relationship:'MEMBER_OF'
          }
        })
      )
    })

    setUpdating(true)

    Promise
      .all(promises)
      .then(response=>{
        setUpdating(false)
        setModalOpen(false)
        sendAlert({type:'info',message:'Teams updated successfully'})
        dispatch({
          type:'set_user_data',
          userData:{
            ...state.userData,
            items:state.userData.items.map(u=>{
              if(u.id===user.id){
                return {
                  ...u,
                  teams_txts:selectedTeam.map(el=>el.name)
                }
              }
              return u
            })
          }
        })
      })
      .catch(error=>{
        setUpdating(false)
        setAlertMessage('Error updating teams')
        setAlertOpen(true)
      })
  }

  const onAddTeam = (el) => {
    if(!selectedTeam?.find(s=>s.id===el.id)){
      setSelectedTeam([...selectedTeam,el])
    }
    if(removedIds.includes(el.id)){
      setRemovedIds(removedIds.filter(id=>id!==el.id))
    }
    else if(!addedIds.includes(el.id)){
      setAddedIds([...addedIds,el.id])
    }
  }

  const onRemoveTeam = (el) => {
    setSelectedTeam(selectedTeam.filter(s=>s.id!==el.id))
    if(addedIds.includes(el.id)){
      setAddedIds(addedIds.filter(id=>id!==el.id))
    }
    else if(!removedIds.includes(el.id)){
      setRemovedIds([...removedIds,el.id])
    }
  }

  return (
    <Modal open={modalOpen} disableBackdropClick>
      <div style={{width:'max-content',margin:'auto',outline:'none'}}>
        <Paper className={classes.modalRoot} style={{width:600}}>
          <Typography className={classes.title}>Update teams assigned to {user.name}</Typography>
          {
            teamLoading &&
            <CircularProgress color='secondary'/>
          }
          {
            teamError &&
            <Typography>Error loading teams</Typography>
          }
          {
            selectedTeam && 
            <TeamSelector
              url='/solr/search/select'
              params={{
                q:`${searchValue.trim()}*`,
                fq:`object_type_srt:TEAM`,
                fl:'*',
                sort:'name_srt asc',
              }}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedGroup={selectedTeam}
              setGroup={el=>onAddTeam(el)}
              clearTeam={el=>onRemoveTeam(el)}
              autoSuggestion={true}
              multiple={true}
            />
          }
          <div className={classes.buttons}>
            <Button color='primary' disabled={updating} onClick={()=>onSave()} style={{marginRight:16}}>SAVE</Button>
            <Button color='secondary' onClick={()=>setModalOpen(false)}>CANCEL</Button>
          </div>
        </Paper>
        <div style={{margin:'0 auto',width:600}}>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </div>
    </Modal>
  )
}


export default withStyles(styles)(TeamEdit);