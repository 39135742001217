import React, { useEffect, useState } from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { getLabelPlural, onClickResultItem } from '../../../utilities';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum';
import {withRouter} from 'react-router';
import { globalListenerRef } from '../../../GlobalListenerRef';

const styles = theme => ({
  root:{
    display:'flex',
    whiteSpace:'pre-wrap',
    overflow:'hidden',
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  breadCrumbText: {
    ...theme.components.breadcrumb
  },
});


const BreadCrumb = props => {

  const {
    classes,
    data,
    history,
    fontSize,
    shouldWrap
  } = props;

  const isActive = data.active_flag;

  const [breadCrumb, setBreadCrumb] = useState()
  
  const loadBreadCrumb = async () => {
    const loadParent = async ({label, id, sourceId, sourceName, obj}) => {
      let parent;
      if(['COLLECTION_INSTANCE'].includes(label)){
        if(obj.hierarchy_parent_id){
          await axiosCerebrum
            .get(
              `/api/collectioninstances/${obj.hierarchy_parent_id}`
            )
            .then(response=>{
              parent = {
                ...response.data,
                type:'COLLECTION_INSTANCE'
              }
            })
            .catch(error=>{
              console.log(error)
            })
        }else{
          await axiosCerebrum
            .get(
              `/api/collections/${obj.parent_id}`
            )
            .then(response=>{
              parent = {
                ...response.data,
                type:'COLLECTION'
              }
            })
            .catch(error=>{
              console.log(error)
            })
        }
      }else if(!['COLLECTION'].includes(label)){
        await axiosCerebrum
          .get(
            `/api/${getLabelPlural(label)}/${id}/related`,
            {params:{
              relationship:'CHILD_OF',
              active_flag:isActive?true:undefined,
              per_page:1
            }}
          )
          .then(response=>{
            parent = response.data.items[0]
          })
          .catch(error=>{
            console.log(error)
          })
      }
      if(!parent && sourceId && sourceName){
        parent = {
          id:sourceId,
          type:'SOURCE',
          name:sourceName
        }
      }
      if(!parent && sourceId && !sourceName){
        await axiosCerebrum
          .get(
            `/api/sources/${sourceId}`
          )
          .then(response=>{
            if(response.data){
              parent = {
                id:response.data.host_ids[0],
                type:'SOURCE',
                name:response.data.name
              }
            }
          })
          .catch(error=>{
            console.log(error)
          })
      }

      if(parent.type==='HOST'){
        await axiosCerebrum
          .get(
            `/api/hosts/${parent.id}`
          )
          .then(response=>{
            if(response.data){
              parent.alternate_name = response.data.alternate_name || response.data.name
            }
          })
          .catch(error=>{
            console.log(error)
          })
      }
    
      return parent;
    }

    let parentArray = []
    
    if(data.object.name!=='HOST'){
      let levelOneParent = await loadParent({label:data.object.name, id:data.id, sourceId: data.source_id, sourceName: data.source_name, obj:data })
      if(levelOneParent){
        parentArray.push(levelOneParent)
      }
      if(parentArray[0] && parentArray[0].type!=='SOURCE' && parentArray[0].type!=='HOST'){
        let levelTwoParent = await loadParent({label:parentArray[0].type, id:parentArray[0].id, sourceId: parentArray[0].source_id, sourceName: data.source_name,obj:parentArray[0] })
        if(levelTwoParent){
          parentArray.push(levelTwoParent)
        }
      }
      
      if(parentArray[1] && parentArray[1].type!=='SOURCE' && parentArray[0].type!=='HOST'){
        let levelThreeParent = await loadParent({label:parentArray[1].type, id:parentArray[1].id, sourceId: parentArray[1].source_id, sourceName: data.source_name, obj:parentArray[1]})
        if(levelThreeParent){
          parentArray.push(levelThreeParent)
        }
      }
      
      if(parentArray[2] && parentArray[2].type!=='SOURCE' && parentArray[2].type!=='HOST'){
        parentArray.push({
          id:'more_parent',
          name:'...'
        })
      }
    }
    parentArray.push({
      id:'source_root',
      name:'ECOSYSTEM',
    })
    parentArray.reverse();
    setBreadCrumb(parentArray)

  }

  useEffect(()=>{
    if(!breadCrumb)loadBreadCrumb()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.reload_breadcrumb ){
        loadBreadCrumb()
      }
    }
    window.removeEventListener('message',globalListenerRef.reloadBreadCrumb);
    globalListenerRef.reloadBreadCrumb = onMsgReceived;
    window.addEventListener("message", globalListenerRef.reloadBreadCrumb);
    return (()=>{window.removeEventListener('message',globalListenerRef.reloadBreadCrumb);})
  // eslint-disable-next-line
  },[data])

  let breadcrumbs = 
    breadCrumb && breadCrumb.length>0 && 
    breadCrumb.map((el) =>
      {
        let dispName = el.type==='HOST'?el.alternate_name?.toUpperCase():el.name.toUpperCase()
        if(dispName==='')dispName = 'UNKNOWN'
        return (
          <span 
            key={el.id}
            style={{fontSize}} 
            data-test-id={`breadcrumb-${dispName.toLowerCase()}`} 
            className={el.id==='more_parent'?undefined:classes.breadCrumbText} 
            onClick={
              el.id==='source_root'?
              ()=>history.push('/my_ecosystem?tabName=SOURCES'):
              () => onClickResultItem({label:el.type, id:el.id, history, item:el})
            }
          >
            {dispName}
          </span>
        )
      }   
    ).reduce((prev, curr) => [prev, ' / ', curr])

  return (
    <div className={classes.root} style={{fontSize, flexWrap:shouldWrap?'wrap':undefined}}>
      {breadcrumbs}
    </div>
  )
}

BreadCrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  shouldWrap: PropTypes.bool
}



export default withRouter(withTheme()(withStyles(styles)(BreadCrumb)));