import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Typography , withTheme} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import { palette } from '../../../theme';


const SortableProperty = (props) => {

  const {
    classes,
    theme,
    property,
    onEditProperty,
    onDelete,
    setSelectedProperty,
  } = props;

  const [buttonHovered, setButtonHovered] = React.useState(false)

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString({x:transform?.x,y:transform?.y,scaleX:1,scaleY:1}),
    transition,
  };
  
  return (
    <div className={classes.customPropContainer} ref={setNodeRef} {...attributes} {...listeners} style={{...style,display:'flex',alignItems:'center',height:88,width:800,borderBottom:'1px solid #DDD',background:buttonHovered?palette.background.main:undefined}}>
      <div align='center' style={{width:56,cursor:'grab'}}>
        {getIconComponent({label:'drag',size:24,colour:theme.palette.primaryText.light})}
      </div>
      <div align='left' style={{display:'flex',flexGrow:1,flexDirection:'column'}}>
        <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>{property.data_type}</Typography>
        <Typography color='primary' style={{fontSize:16}}>{property.name}</Typography>
        <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'pre-wrap'}}>{`Description: ${property.description?property.description:'No description'}`}</Typography>
      </div>
      <div style={{display:'flex'}}>
        <div 
          className={classes.divButton} 
          style={{marginLeft:0}} 
          data-test-id = {`edit-property-${property.name}`} 
          onMouseUp={()=>{onEditProperty(property)}}
          onMouseEnter={()=>setButtonHovered(true)}
          onMouseLeave={()=>setButtonHovered(false)}
        >
          {getIconComponent({label:'edit',colour:theme.palette.primaryText.light,size:24})}
        </div>
        <div 
          className={classes.divButton}  
          data-test-id = {`delete-property-${property.name}`} 
          onMouseUp={()=>{setSelectedProperty(property);onDelete(property)}}
          onMouseEnter={()=>setButtonHovered(true)}
          onMouseLeave={()=>setButtonHovered(false)}
        >
          {getIconComponent({label:'clear',colour:theme.palette.primaryText.light,size:24})}
        </div>
      </div>
    </div>
  );
}

export default withTheme()(SortableProperty);