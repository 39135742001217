import React, {} from 'react';
import { withTheme, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import TeamSelector from '../../../UI/SearchSelector/TeamSelector';


const ExtractTeamSelector = props => {

  const {
    classes,
    teamSearchValue,
    setTeamSearchValue,
    team,
    setTeam,
  } = props;


  return (
    <div className={classes.modalBlock}>
    <Typography  className={classes.modalBlockTitle}>Team</Typography>
    <div style={{flexGrow:1}}>
      <TeamSelector
        // renderResults={renderResults}
        url='/solr/search/select'
        params={{
          q:`${teamSearchValue}*`,
          fq:`object_type_srt:TEAM`,
          fl:'object_type_txt, id, name_txt',
          sort:'name_srt asc',
          rows:10,
        }}
        searchValue={teamSearchValue}
        setSearchValue={setTeamSearchValue}
        selectedGroup={team}
        autoSuggestion={true}
        setGroup={el=>{
          setTeam(el)
        }}
        clearTeam={el=>{
          setTeam();
        }}
      />
    </div>
  </div>
  )
}

ExtractTeamSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  teamSearchValue: PropTypes.string.isRequired,
  setTeamSearchValue: PropTypes.func.isRequired,
  team: PropTypes.object,
  setTeam: PropTypes.func.isRequired,
}

export default withTheme()(ExtractTeamSelector);