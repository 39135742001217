import theme, { checkIsDarkMode, palette } from "../../../../theme";
import { mapSearchObjectName, sendMessage } from "../../../../utilities";
import { getBackgroundColour } from "../utils";
import { constructGroupNodes, loadChildrenByGroups, onNodeExpandToggle } from "./dataLoadUtils";
import { addDuplicateTag, setLinkFocus, setNodeFocus, setNodeTag } from "./MapControlUtils";

export const nodeHeight = 42;
export const groupHeaderHeight = 62;
export const childrenNumPerGroup = 3;
export const groupVerticalGap = 100;
export const groupBodyActionHeight = 32;
export const groupTotalHeight = groupHeaderHeight + childrenNumPerGroup * nodeHeight;
export const nodeHorizontalGap = 600; // this is the difference between the x position of the nodes, not their actual gap.
export const nodeWidth = 300;
export const nodeTypes = {
  customNode: 'KCustomNode',
  groupNodeType: 'KLineageGroupNodeV3',
  childNodeType: 'KLineageNodeV3',
  edgeType: 'KLineageEdge',
  swimLaneType: 'KSwimLane',
  swimLaneControlType: 'KSwimLaneControl'
}

const getSameLevelGroups = ({target, groups}) => {
  return groups.filter(group=>group.position.x===target.position.x)
}

export const getGroupNodeByChildNode = ({childNode, groups}) => {
  return groups.find(group=>group.id===childNode.parentNode)
}

const spreadOutGroups = ({groups, centerY, x}) => {
  let totalHeight = 0;
  groups.forEach(el=>{
    totalHeight += groupHeaderHeight + Math.min(childrenNumPerGroup, el.data.obj.object_count) * nodeHeight;
  })
  totalHeight += (groups.length-1) * groupVerticalGap;
  // totalHeight = groups.length * (groupTotalHeight + groupVerticalGap);
  let startY = centerY - totalHeight/2;
  groups.forEach((group, index)=>{
    if(!group.position)group.position = {};
    if(groups.length===1){
      group.position.y = centerY;
    }else{
      group.position.y = startY;
      startY += groupHeaderHeight + Math.min(childrenNumPerGroup, group.data.obj.object_count) * nodeHeight + groupVerticalGap;
    }
    group.position.x = x;
  })
}


export const insertLinks = ({
  newGroups, 
  sourceNode, 
  currentLinks,
  direction
}) => {
  let newLinks = [];
  newGroups.forEach(group=>{
    sourceNode.nextLevelRefs.push(group);
    let l = {
      source:direction==='UPSTREAM'?group.id:sourceNode.id,
      sourceRef:direction==='UPSTREAM'?group:sourceNode,
      target:direction==='UPSTREAM'?sourceNode.id:group.id,
      targetRef:direction==='UPSTREAM'?sourceNode:group,
      type:nodeTypes.edgeType,
      data:{
        lineType:'solid'
      }
    }
    l.id = l.source+'-'+l.target;
    if(currentLinks.find(cl=>cl.id===l.id))return;
    newLinks.push(l)
  })
  currentLinks.push(...newLinks);
}

export const onCollapseSameLevelNodes = ({sourceNode, currentGroups, childrenMap, direction}) => {
  let sourceGroup = getGroupNodeByChildNode({childNode:sourceNode, groups:currentGroups});
  getSameLevelGroups({target:sourceGroup, groups:currentGroups}).forEach(group=>{
    childrenMap[group.id]?.forEach(c=>{
      if(c.id===sourceNode.id)return;
      onNodeExpandToggle({
        node:c,
        direction,
        forceExpand:false
      })
    });
  })
}

export const insertGroups = ({
  newGroups,
  currentGroups, 
  sourceNode, //  the node that is expanded 
  childrenMap, 
  direction,
  mode="focus" // mode is either "focus" or "multi"
}) => {
  if(mode==='focus'){
    let sourceGroup = getGroupNodeByChildNode({childNode:sourceNode, groups:currentGroups});

    onCollapseSameLevelNodes({sourceNode, currentGroups, childrenMap, direction});

    spreadOutGroups({
      groups:newGroups,
      centerY:sourceGroup.position.y + sourceNode.position.y,
      x:sourceGroup.position.x + (direction==='UPSTREAM' ? -1 : 1) * nodeHorizontalGap
    })

    currentGroups.push(...newGroups);
  } 
}

export const insertNodesIntoGroup = ({nodes, targetGroup}) => {
  nodes.forEach((node,index)=>{
    node.position = {
      x:0,
      y:groupHeaderHeight + index * nodeHeight
    }
  })
  targetGroup.data.height = groupHeaderHeight + nodes.length * nodeHeight;
}

export const removeNodesFromGroup = ({nodes, targetGroup, childrenMap, currentGroups}) => {
  childrenMap[targetGroup.id].forEach((node,index)=>{
    if(!nodes.includes(node)){
      let aboveNodesCount = nodes.filter(n=>n.position.y < node.position.y).length;
      node.position.y -= aboveNodesCount * nodeHeight;
    }
  })
  childrenMap[targetGroup.id] = childrenMap[targetGroup.id].filter(node=>!nodes.includes(node));
  targetGroup.data.height -= nodes.length * nodeHeight;

  currentGroups.forEach(g=>{
    if(g.prevLevelRef===targetGroup.prevLevelRef && g.level===targetGroup.level && g.position.y > targetGroup.position.y){
      g.position.y -= nodes.length * nodeHeight;
    }
  })
}

export const addAdditionalNodesIntoGroup = ({nodes, targetGroup, childrenMap, currentGroups}) => {
  nodes.forEach(n=>{
    n.position = {
      x:0,
      y:targetGroup.data.height
    }
    targetGroup.data.height += nodeHeight;
  })
  childrenMap[targetGroup.id].push(...nodes)

  currentGroups.forEach(g=>{
    if(g.prevLevelRef===targetGroup.prevLevelRef && g.level===targetGroup.level && g.position.y > targetGroup.position.y){
      g.position.y += nodes.length * nodeHeight;
    }
  })
}

export const removeGroupFromSwimlane = ({groups, currentGroups, targetNode}) => {
  let sameLevelGroups = currentGroups.filter(g=>g.level===groups[0].level);
  let removedTotalHeight = 0;
  groups.forEach(g=>{
    removedTotalHeight += g.data.height;
  })
  removedTotalHeight += (groups.length-1) * groupVerticalGap;
  
  sameLevelGroups.forEach(g=>{
    g.position.y += removedTotalHeight/2;
    groups.forEach(dg=>{
      if(g.position.y > dg.position.y){
        g.position.y -= dg.data.height + groupVerticalGap;
      }
    })
  })
  // remove the group from the currentGroups using splice
  groups.forEach(g=>{
    let index = currentGroups.indexOf(g);
    currentGroups.splice(index, 1);
    g.prevLevelRef.nextLevelRefs = g.prevLevelRef.nextLevelRefs.filter(n=>n.id!==g.id);
  })
}

export const addAditionalGroupsIntoSwimlane = ({groups, currentGroups, currentLinks, currentChildrenMap, mapControls, targetNode, direction}) => {
  let newGroups = constructGroupNodes({groups, targetNode, direction, detailMap:{}, isLoading:true})
  insertLinks({
    newGroups,
    sourceNode:targetNode,
    currentLinks:currentLinks,
    direction
  })
  
  let newGroupTotalHeight = 0;
  newGroups.forEach(group=>{
    newGroupTotalHeight += group.data.height + groupVerticalGap;
  })

  let currentMaxY = -Infinity;
  let currentX;
  currentGroups.forEach(group=>{
    if(group.level===newGroups[0].level){
      currentX = group.position.x;
      group.position.y -= newGroupTotalHeight/2;
      if(group.position.y + group.data.height > currentMaxY){
        currentMaxY = group.position.y + group.data.height;
      }
    }
  })
  currentMaxY += groupVerticalGap;

  if(!currentX){ // this is when all existing groups are removed
    let sourceGroup = getGroupNodeByChildNode({childNode:targetNode, groups:currentGroups});
    spreadOutGroups({
      groups:newGroups,
      centerY:sourceGroup.position.y + targetNode.position.y,
      x:sourceGroup.position.x + (direction==='UPSTREAM' ? -1 : 1) * nodeHorizontalGap
    })
  }else{
    newGroups.forEach(group=>{
      group.position = {
        x:currentX,
        y:currentMaxY
      }
      currentMaxY += groupHeaderHeight + Math.min(childrenNumPerGroup, group.data.obj.object_count) * nodeHeight + groupVerticalGap;
    })
  }

  currentGroups.push(...newGroups);

  sendMessage({lineage_update_map:true})

  loadChildrenByGroups({
    groups: newGroups,
    targetNode,
    direction,
    currentGroups,
    currentChildrenMap,
    mapControls
  })
}

export const generateSwimLaneEl = ({nodes, rootObj, selectedLevel}) => {
  let minX = 0;
  let maxX = 0;
  let minY = -500;
  let maxY = 500;
  
  nodes.forEach(node=>{
    if(node.type!==nodeTypes.groupNodeType)return;
    if(node.position.x < minX)minX = node.position.x;
    if(node.position.x > maxX)maxX = node.position.x;
    if(node.position.y < minY)minY = node.position.y;
    if(node.position.y > maxY)maxY = node.position.y;
  })
  minX -= (nodeHorizontalGap-nodeWidth)/2;
  maxX -= (nodeHorizontalGap-nodeWidth)/2;
  let swimLaneWidth = nodeHorizontalGap;

  let swimLanes = [];
  swimLanes.push({
    x: -(nodeHorizontalGap-nodeWidth)/2,
    level:0,
    width: swimLaneWidth,
    background: checkIsDarkMode()?'#383D45':getBackgroundColour(mapSearchObjectName(rootObj.object_type_txt || rootObj.object?.name || 'unknown', rootObj.code_type_txt))
  })

  let currentX = swimLanes[0].x-swimLaneWidth;
  let count = 0;
  let whiteBackground = checkIsDarkMode()?'#4B5057':'#F8F8F8';
  let darkBackground = checkIsDarkMode()?'#60646A':palette.hovered.main
  while(currentX >= minX){
    swimLanes.push({
      x:currentX,
      width:swimLaneWidth,
      level:count-1,
      direction:'UPSTREAM',
      background:count%2===0?darkBackground:whiteBackground
    })
    count--;
    currentX -= swimLaneWidth;
  }

  currentX = swimLanes[0].x+swimLaneWidth;
  count = 0;
  while(currentX <= maxX){
    swimLanes.push({
      x:currentX,
      width:swimLaneWidth,
      level:count+1,
      direction:'DOWNSTREAM',
      background:count%2===0?palette.hovered.main:whiteBackground
    })
    count++;
    currentX += swimLaneWidth;
  }

  swimLanes = swimLanes.map(s=>{

    return {
      type:nodeTypes.swimLaneType,
      id: s.level+'-swim-lane',
      level:s.level,
      selected:selectedLevel===s.level,
      position:{
        x:s.x,
        y:(maxY+minY)/2 - (maxY-minY),
      },
      data:{
        label:s.level?(s.direction + ' ' + (s.level>0?'+':'') + s.level):'START',
        background:s.background,
        width:s.width,
        height:(maxY-minY)*3,
        onClick:s.level?()=>{
          sendMessage({
            clicked_swimlane_level:s.level
          })
          sendMessage({swimlane_hide_label:true})
        }:()=>{
          sendMessage({swimlane_hide_label:true})
        },
      }
    }
  })
  

  return swimLanes;
}

export const drawSwimlaneLabels = () => {
  let swimalnes = document.getElementsByClassName('react-flow__node-KSwimLane');
  let root = document.getElementById('k-lineage-v3-root')
  let top = 81;
  if(root){
    top = root.getBoundingClientRect().top + 8;
  }
  // loop through all the swimlanes and add a label to them
  for (let i = 0; i < swimalnes.length; i++) {
    let swimlane = swimalnes[i];
    let label = swimlane.childNodes[0]?.getAttribute('data-swimlane-label');
    let left = swimlane.getBoundingClientRect().left + 8;
    if(swimlane.getBoundingClientRect().top>top){
      top = swimlane.getBoundingClientRect().top+8;
    }
    let labelEl = document.createElement('div');
    let container = document.getElementsByClassName('react-flow__container')[0];
    let containerRect = container.getBoundingClientRect();
    labelEl.innerText = label;
    labelEl.style.position = 'absolute';
    labelEl.style.left = (left-containerRect.left)+'px';
    labelEl.style.top = '8px';
    labelEl.style.color = theme.palette.primaryText.main;
    labelEl.style.fontSize = '13.75px';
    labelEl.className = 'swimlane-label'
    labelEl.style.zIndex = 1000;
    // document.body.appendChild(labelEl);
    container.appendChild(labelEl);
  }
}

export const removeSwimlaneLabels = () => {
  let labels = document.getElementsByClassName('swimlane-label');
  Array.from(labels).forEach(l=>{
    l.remove();
  })
}

export const getFullNodeList = ({currentGroups, childrenMap, currentLinks, rootObject, mapControls, selectedItem, selectedLevel}) => {
  let fullNodeList = [];
  if(!currentGroups?.length>0)return fullNodeList;

  const setNodeSelected = (n) => {
    if(n.id===selectedItem?.id){
      n.selected = true;
    }else{
      n.selected = false;
    }
    if(n.type===nodeTypes.groupNodeType){
      n.data.borderWidth = undefined;
    }
  }
  
  let rootGroup = currentGroups[0];
  let rootNode = childrenMap[rootGroup.id][0];
  setNodeSelected(rootNode);
  setNodeSelected(rootGroup)

  const sameObjIdMap = {
    [rootNode.data.obj.id]:[rootNode]
  }
  
  fullNodeList.push(rootGroup, rootNode);

  setNodeFocus({
    focus:mapControls.focusView,
    subFocus:mapControls.subFocusView,
    group:rootGroup,
    children:[rootNode],
    type:mapControls.focusViewType,
    isExclude:mapControls.isViewExclude
  })

  setNodeTag({tagView:mapControls.tagView, children:[rootNode]})
  
  const traverseTree = (current) => {
    current.data.forceFocus = false;

    if(current.expanded){
      current.nextLevelRefs?.forEach(group=>{
        fullNodeList.push(group);
        setNodeSelected(group);

        if(group.data.obj.object_count===0){
          if(!sameObjIdMap[group.data.obj.id]){
            sameObjIdMap[group.data.obj.id] = [];
          }
          sameObjIdMap[group.data.obj.id].push(group);
        }

        if(childrenMap[group.id]){
          let children = childrenMap[group.id];
          if(children.length===group.data.obj.object_count){
            group.data.hideBodyAction = true
          }else{
            group.data.hideBodyAction = false
          }
          children.forEach(c=>{
            setNodeSelected(c);
            traverseTree(c)
            fullNodeList.push(c);
            if(!sameObjIdMap[c.data.obj.id]){
              sameObjIdMap[c.data.obj.id] = [];
            }
            sameObjIdMap[c.data.obj.id].push(c);
          })
          setNodeFocus({
            focus:mapControls.focusView,
            subFocus:mapControls.subFocusView,
            group,
            children,
            type:mapControls.focusViewType,
            isExclude:mapControls.isViewExclude
          })
          setNodeTag({tagView:mapControls.tagView, children})
        }
      })
    }
  }


  traverseTree(rootNode);
  setLinkFocus({links:currentLinks})
  
  Object.keys(sameObjIdMap).forEach(id=>{
    if(sameObjIdMap[id].length>1){
      sameObjIdMap[id].forEach((node)=>{
        addDuplicateTag({node})
      })
    }
  })

  generateSwimLaneEl({nodes:fullNodeList.filter(n=>n.type===nodeTypes.groupNodeType), rootObj:rootObject, selectedLevel}).forEach(swimLane=>{
    fullNodeList.push(swimLane);
  })

  // focus border of selected item's parent
  if(selectedItem?.parentNodeRef?.data){
    selectedItem.parentNodeRef.data.borderWidth = 4;
  }

  // focus nodes that on the expanded path
  if(selectedItem){
    let currentNode = selectedItem;
    while(currentNode){
      currentNode.data.forceFocus = true;
      if(currentNode.isRoot)break;
      currentNode = currentNode.parentNodeRef?.prevLevelRef;
    }
  }

  return fullNodeList;
}

export const getFullLinkList = ({links, selectedItem}) => {

  let finalLinks = [...links];

  let linkedItem = selectedItem?.parentNodeRef?.prevLevelRef;
  if(linkedItem){
    finalLinks.forEach(l=>{
      l.data.faded = true;
    })
    let source, target;
    if(linkedItem.level<selectedItem.level){
      source = linkedItem;
      target = selectedItem;
    }else{
      source = selectedItem;
      target = linkedItem;
    }

    let tempLink = {
      source:source.id,
      sourceRef:source,
      target:target.id,
      targetRef:target,
      type:nodeTypes.edgeType,
      selected:true,
      data:{
        lineType:selectedItem.data.obj.edge_types.find(e=>e.includes('SOURCE'))?'dashed':'solid',
      }
    }
    tempLink.id = tempLink.source+'-'+tempLink.target;
    finalLinks.push(tempLink)
  }
  

  return finalLinks;
}