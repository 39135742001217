import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, IconButton, Button } from '@material-ui/core';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import SimpleResultItem from '../../../UI/SearchResults/SimpleResultItem';
import { collectionIds, getDispFields, getIconComponent, onClickResultItem } from '../../../../utilities';
import axiosCerebrum from '../../../../axios-cerebrum';
import { actionIds } from '../../utils';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 16,
  },
  sectionHeader: {
    fontSize: 12,
    letterSpacing: 1,
    marginBottom: 16
  }
})

function LinkToWarning(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    sessionData
    // history,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [isSeeMore, setIsSeeMore] = useState(false);
  const properties = state.properties;
  const setProperties = (properties) => dispatch({ type: 'set_properties', properties });
  const propertyValues = state.propertyValues;
  const setPropertyValues = (propertyValues) => dispatch({ type: 'set_property_values', propertyValues });

  const loadSuggestions = () => {
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/collections`,
        {
          params: {
            parent_name: 'warning notice',
            per_page: 50,
            relationship: 'MODIFIES,CREATOR_OF',
            page: 1,
            sort: 'END_DESC'
          }
        }
      )
      .then(res => {
        dispatch({ type: 'set_instance_suggestions', instanceSuggestions: res.data.items })
      })
  }

  useEffect(() => {
    if (!state.instanceSuggestions) {
      loadSuggestions()
    }
    // eslint-disable-next-line
  }, [])

  const getInitialvalues = () => {
    let field = 'MEMBER_OF';
    let values = [];
    let valueDetails = [];
    let operation = 'ADD_TO_EXISTING';

    return {
      field,
      operation,
      values,
      valueDetails
    }
  }

  const onAddInstance = instance => {
    let properties = state.properties;
    let newPropertyValues = { ...(propertyValues || {}) };
    if (properties.length === 0) {
      properties.push({
        name: 'Warning Notice',
        id: collectionIds.warningNotice,
      });
      newPropertyValues[collectionIds.warningNotice] = getInitialvalues(collectionIds.warningNotice);
    }
    newPropertyValues[collectionIds.warningNotice].valueDetails.push(instance);
    newPropertyValues[collectionIds.warningNotice].values.push(instance.id);
    setPropertyValues(newPropertyValues);
    setProperties(properties)
  }

  const onRemoveInstance = id => {
    let newPropertyValues = { ...propertyValues };
    newPropertyValues[collectionIds.warningNotice].valueDetails = newPropertyValues[collectionIds.warningNotice].valueDetails.filter(p => p.id !== id)
    newPropertyValues[collectionIds.warningNotice].values = newPropertyValues[collectionIds.warningNotice].values.filter(p => p !== id)
    setPropertyValues(newPropertyValues)
  }

  let selectedInstances = [];
  if (properties[0]) {
    selectedInstances = propertyValues[properties[0].id].valueDetails;
  }

  let suggestions = state.instanceSuggestions || [];
  suggestions = suggestions.filter(s => !selectedInstances.find(p => p.id === s.id))


  const onChangeToCreate = () => {
    dispatch({
      type: 'set_selected_action',
      selectedAction: actionIds.createAndLinkToWarning
    })
    dispatch({
      type: 'reset_state'
    })
  }


  return (
    <div>
      <Typography className={classes.header}>
        Link to warning notices <Button color='secondary' style={{position:'relative', right: -8}} onClick={onChangeToCreate}>CREATE A NEW WARNING NOTICE</Button>
      </Typography>
      <div style={{ maxWidth: 800 }}>
        <div style={{ marginBottom: 24 }}>
          <SearchSelector
            url='/solr/search/select'
            testID={'add-property-search'}
            params={{
              q: `${searchValue}*`,
              fq: `object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:"WARNING NOTICE"` +
                `${selectedInstances.length > 0 ? ` AND -id:(${selectedInstances.map(p => p.id).join(' ')})` : ''}`,
              fl: '*',
              sort: 'name_srt asc',
              rows: 10
            }}
            autoSuggestion
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={'Search for a warning notice'}
            onResultClick={el => {
              onAddInstance(el)
            }}
          />
        </div>
        {
          suggestions?.length > 0 &&
          <>
            <Typography className={classes.sectionHeader}>MY WARNING NOTICES</Typography>
            {
              suggestions.slice(0, isSeeMore ? suggestions.length : 3).map(l => {
                return (
                  <SimpleResultItem
                    item={l}
                    iconLabel={'warning_notice'}
                    title={getDispFields(l, 'dispTitle')}
                    titleColour={theme.palette.primaryText.main}
                    onTitleClick={()=>onClickResultItem({ item: l, label: 'collection_instance', newWindow: true, id: l.id })}
                    showUnderline
                    tailObject={
                      <Button style={{ height: 28 }} color='primary' variant='outlined' onClick={() => onAddInstance(l)}>
                        ADD
                      </Button>
                    }
                  />
                )
              })
            }
            {
              suggestions.length > 3 &&
              <Button color='primary' style={{ marginLeft: 8, marginTop: 8 }} onClick={() => setIsSeeMore(!isSeeMore)}>SEE {isSeeMore ? 'LESS' : 'MORE'}</Button>
            }
          </>
        }
        <Typography className={classes.sectionHeader} style={{ marginTop: 24 }}>SELECTED</Typography>
        {
          selectedInstances.length === 0 &&
          <Typography>
            No warning notices selected
          </Typography>
        }
        {
          selectedInstances.map(l => {
            return (
              <SimpleResultItem
                item={l}
                iconLabel={'warning_notice'}
                title={getDispFields(l, 'dispTitle')}
                titleColour={theme.palette.primaryText.main}
                onTitleClick={()=>onClickResultItem({ item: l, label: 'collection_instance', newWindow: true, id: l.id })}
                showUnderline
                tailObject={
                  <IconButton onClick={() => onRemoveInstance(l.id)}>
                    {getIconComponent({ label: 'clear', size: 24, colour: theme.palette.primaryText.light })}
                  </IconButton>
                }
              />
            )
          })
        }
      </div>
    </div>
  )
}

LinkToWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LinkToWarning));