import React, {useState} from 'react';
import { withStyles, withTheme, Typography, Button, } from '@material-ui/core';
import {  getIconComponent, getTrustScoreColor, getTrustScoreBand, getUserRoles } from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../ContextMenu/ContextMenu'
import {useStore} from 'react-redux'
import {addBadgeToIcon, getIconLabel, isShowTrust} from './utils'
import PropTypes from 'prop-types';
import CodeDisplayModal from './CodeDisplayModal';
import KTooltip from '../KTooltip/KTooltip';


const styles = theme => ({
  root:{
    width:'100%',
    paddingTop:10,
    paddingBottom:10,
    display:'flex',
  },
  clickableRoot:{
    '&:hover':{
      background:theme.palette.hovered.main
    },
    cursor:'pointer'
  },
  hoverable:{
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  tooltip:{
    fontSize:13.75
  },
  statusChip:{
    flexShrink:0,
    marginLeft:12,
    background:'#9E9E9E',
    color:'#fff',
    height:24,
    display:'flex',
    alignItems:'center',
    paddingLeft:6,
    paddingRight:12,
    overflow:'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize:13.75,
    borderRadius:4,
    marginRight:16
  }
})


function SimpleResultItem(props) {

  const {
    classes,
    theme,
    item,
    height,
    label = '',
    iconColour,
    title = '',
    titleColour,
    onTitleClick,
    subTitle,
    rightTitle='Trust',
    rightText,
    rightColour,
    onClick,
    showUnderline,
    headObject,
    bodyObject,
    tailObject,
    hideRight,
    footer,
    removeHorizontalPadding,
    iconLabel,
    disableContextMenu,
    keepPropagation,
    alwaysEnableHoverEffect,
    hideIcon
  } = props;
  
  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)
  const [viewCodeModalOpen, setViewCodeModalOpen] = useState(false)
  
  
  if(!item)return<></>
  
  let shownTitle = title
  if(['column','table'].includes(label.toLowerCase())){
    let businessName = item.alternate_name_txt || item.alternate_name;
    if(businessName && businessName.trim()!==''){
      if(!roles.every(el=>el==='90'))shownTitle =  `${title} (${businessName})`
      else{shownTitle = `${businessName} (${title})`}
    }
  }
  if(shownTitle===undefined)shownTitle = 'Untitled'

  let icon = iconLabel || getIconLabel({label, item})
  
  let isDisplayCode = (label.toLowerCase()==='query' || (label.toLowerCase()==='code' && item && item.code_type_txt==='QUERY'))

  return (
    <div style={{width:"100%"}}>
      <ContextMenuTrigger id={item.id}>
        <div 
          data-test-classname="simple-result-item" 
          data-test-id={`simple-result-item-${title.replace(/\s+/g,'-').toLowerCase()}`} 
          className={classes.root + (onClick?' '+classes.clickableRoot:'') + (alwaysEnableHoverEffect?' '+classes.hoverable:'')} 
          style={{height:height || (subTitle && subTitle.trim()!==''?undefined:27),borderBottom:showUnderline?`1px solid ${theme.palette.listItemDivider.main}`:undefined,alignItems:subTitle?'flex-start':'center'}} onClick={event=>{if(!keepPropagation){event.stopPropagation()};onClick && onClick(event)}}
        >
          {
            !hideIcon && 
            <div style={{marginLeft:removeHorizontalPadding?0:16,marginRight:16,height:24}}>
              {
                addBadgeToIcon({
                  icon: getIconComponent({label:icon,size:24,colour:iconColour||theme.palette.primary.main}),
                  modification_badge: item.modification_badge_txt,
                  active: item.active_txt || item.active_flag,
                })
              }
            </div>
          }
          {
            headObject &&
            <div style={{alignSelf:'center',flexShrink:0}}>
              {headObject}
            </div>
          }
          <div style={{flexGrow:1,overflow:'hidden',marginRight:16,marginLeft:hideIcon && !removeHorizontalPadding?16:0}}>
            <KTooltip title={shownTitle}>
              <Typography 
                data-test-classname="simple-result-item-title" 
                style={{
                  fontSize:16,
                  color:titleColour||theme.palette.header.main,
                  overflow:'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textDecoration: onTitleClick?'underline':'none',
                  cursor: onTitleClick?'pointer':'default',
                }}
                onClick={onTitleClick}
              >
                  {shownTitle}
              </Typography>
            </KTooltip>
            <KTooltip title={subTitle}>
              <Typography data-test-classname="simple-result-item-subtitle"  style={{fontSize:13.75,color:theme.palette.primaryText.light,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>{subTitle}</Typography>
            </KTooltip>
            {
              footer && 
              <KTooltip title={footer}>
                <Typography data-test-classname="simple-result-item-footer"  style={{fontSize:13.75,color:theme.palette.primaryText.light,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>{footer}</Typography>
              </KTooltip>
            }
          </div>
          {
            bodyObject && 
            <div style={{marginRight:removeHorizontalPadding?0:8,alignSelf:'center',flexShrink:0}}>
              {bodyObject}
            </div>
          }
          {
            isDisplayCode && 
            <Button 
              variant="outlined" 
              color='primary' 
              style={{height:28,width:112,flexShrink:0,marginRight:16}}
              onClick={(event)=>{
                event.stopPropagation();
                event.preventDefault();
                setViewCodeModalOpen(true);
              }}
            >
              VIEW CODE
            </Button>
          }
          {
            !hideRight && ( isShowTrust(label) || rightTitle!=='Trust') && 
            <div style={{width:60,textAlign:'center',flexShrink:0,marginRight:removeHorizontalPadding?0:16}}>
              <Typography style={{fontSize:12,color:theme.palette.primaryText.main}}>{rightTitle}</Typography>
              <KTooltip classes={{tooltip:classes.tooltip}} title={rightText}>
                <Typography style={{fontSize:13.75,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',color:rightColour||getTrustScoreColor(rightText)}}>{rightTitle==='Trust'?getTrustScoreBand({trustScore:rightText}):rightText}</Typography>
              </KTooltip>
            </div>
          }
          {
            tailObject && 
            <div style={{marginRight:removeHorizontalPadding?0:8,alignSelf:'center',flexShrink:0}}>
              {tailObject}
            </div>
          }
        </div>
      </ContextMenuTrigger>

      {
        !disableContextMenu && 
        <ContextMenu id={item.id}>
          <CustomMenu
            item={item}
            actions={[
              'open_new_tab',
              'bookmark'
            ]}
          />
        </ContextMenu>
      }

      {
        viewCodeModalOpen && 
        <CodeDisplayModal
          item={item}
          modalOpen={viewCodeModalOpen}
          setModalOpen={setViewCodeModalOpen}
          onOpen={onClick}
        />
      }
    </div>
  );

}

SimpleResultItem.propTypes = {
  item: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  height: PropTypes.number,
  iconColour: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColour: PropTypes.string,
  onTitleClick: PropTypes.func,
  subTitle: PropTypes.string.isRequired,
  rightText: PropTypes.string,
  rightColour: PropTypes.string,
  iconLabel: PropTypes.string,
  showUnderline: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClick: PropTypes.func,
  headObject: PropTypes.object,
  bodyObject: PropTypes.object,
  tailObject: PropTypes.object,
  hideRight: PropTypes.bool,
  footer: PropTypes.string,
  removeHorizontalPadding: PropTypes.bool,
  disableContextMenu: PropTypes.bool,
  keepPropagation: PropTypes.bool,
  hideIcon: PropTypes.bool
}

export default withTheme()(withStyles(styles)(SimpleResultItem));