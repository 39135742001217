import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, InputBase, Typography, FormControl, Avatar, Snackbar, ListItem, Divider, SnackbarContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, Select, MenuItem, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { getIconComponent, getInitials, toTitleCase,getDispFields } from '../../../utilities';
import FormHelperText from '@material-ui/core/FormHelperText';
import axiosCerebrum from '../../../axios-cerebrum';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import TeamSelector from '../../UI/SearchSelector/TeamSelector';
import UserSuggestionItem from '../../UI/SearchResults/UserSuggestionItem';
import useAlert from '../../../hooks/useAlert';


const styles = theme => ({
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
  block:{
    marginTop:18,
    overflow:'visible',
    cursor:'default'
  },
  selector: {
    width: '100%',
    marginTop: '0.5rem',
    backgroundColor: '#EEE',
    color: 'rgba(0,0,0,0.6) !important',
    '& div': {
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem'
    }
  },
  textField: {
    ...theme.components.inputBase,
    flexGrow:1,
    '& input': {
      padding:16
    },
    '& textarea': {
      padding:16
    },
  },
  avatar: {
    fontSize: '0.75rem',
    height: 25,
    width: 25,
    backgroundColor: theme.palette.avatar.main,
  },
  title: {
    height: '24px',
    color: theme.palette.primary.main,
    
    fontSize: '13.75px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '24px'
  },
  subTitle: {
    margin: '0.25rem 0 0.5rem'
  },
  button: {
    width:96,
    height:36,
    marginTop:24
  },
  inputField: {
    width: '100%',
    fontWeight: '400',
    backgroundColor: '#EEE',
  },
  helperText: {
    margin:0,
    backgroundColor: theme.palette.background.main,
    color:theme.palette.primaryText.light,
    paddingTop: '0.5rem',
    paddingLeft: '1rem'
  },
  chip: {
    backgroundColor: theme.palette.chip.main,
    borderRadius: 5,
    border:`1px solid ${theme.palette.border.main}`,
    display: 'inline-block',
    marginRight: 5,
    marginTop: 5,
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    '&:hover $avatar': {
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.hovered.main
    }
  },
  alertTitle: {
    color: theme.palette.primaryText.main,
    fontSize: '19.64px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    lineHeight: '26px',
    marginLeft:24,
    marginTop:24,
    marginBottom:24
  },
  alertText: {
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    color: theme.palette.primaryText.light,
    
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  assigneeChip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: '#EEE',
    marginTop: '-0.5rem',
    marginBottom: '1rem',
    borderRadius: 5
  },
  stepper:{
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  snackbar:{
    background:theme.palette.snackbarBackground.main
  },
  paper:{
    border:`1px solid ${theme.palette.border.main}`
  },
  accountTypeSelector:{
    ...theme.components.selector,
    marginRight:16,
    height:48,
    width:200
  },
});

const CreateTeam = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [disableButton, setDisableButton] = useState(false); // to disable save/cancel buttons on submitting
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parent,setParent] = useState();
  const [parentSearchValue,setParentSearchValue]=useState('');
  const [addedMembers, setAddedMembers] = useState([]);
  const [accountType, setAccountType] = useState('all')
  const [searchValue, setSearchValue] = useState('');

  const [step, setStep] = useState(0)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let steps = [
      'Enter a Team name',
      'Add a Team description',
      `Select a Parent`,
      `Add people to this Team`,
      'Create Team'
    ]

  const onResultClick = (el) => {
    setSearchValue('')
    if (!addedMembers.find(o => o.id === el.id)) {
      setAddedMembers([...addedMembers, el])
    }
    else {
      // put a snackbar and say that the team member is already added.
    }
  }

  const removeTeamMember = (member) => {
    setAddedMembers(addedMembers.filter(el => el.id !== member.id))
  }

  const displayedTeamMember = () => {
    return addedMembers
  }

  const saveTeamMember = (groupID) => {
    const promises = [];
    addedMembers.forEach(a=>{
      promises.push(axiosCerebrum.put(`/api/users/${a.id}/related?object_id=${groupID}&relationship=MEMBER_OF`))
    })
    return Promise.all(promises)
  }

  const onSave = () => {
    if (!name) {
      setSnackbarMessage('The team name is required');
      setSnackbarOpen(true);
      return;
    }
    setDisableButton(true)
    let requestBody = {};
    requestBody.description = description || '';
    requestBody.name = name;
    if(parent){
      requestBody.parent_id = parent.id;
    }
    axiosCerebrum
      .post('/api/groups', requestBody,{withCredentials: true})
      .then(async response => {
        sendAlert({message:`${name} team created successfully`,type:'info'})
        // await updatedParentTeam(response.data);
        if(addedMembers.length===0){
          dispatch({
            type:'set_teams_data',
            teamsData:{...state.teamsData,page:0,items:[]}
          })
          dispatch({ type: 'set_tab_state', tabState: 0 })
          dispatch({ type: 'set_alert', alert: false })
          setDisableButton(false)
        }
        else{
          saveTeamMember(response.data.id)
          .then(res=>{
            dispatch({
              type:'set_teams_data',
              teamsData:{...state.teamsData,page:0,items:[]}
            })
            sendAlert({message:`${name} team created successfully`,type:'info'})
            dispatch({ type: 'set_tab_state', tabState: 0 })
            dispatch({ type: 'set_alert', alert: false })
            setDisableButton(false)
          })
          .catch(error=>{
            console.log(error)
            sendAlert({message:`Error occurred adding team members`,type:'error'})
            setDisableButton(false)
          })
        }
      })
      .catch(error => {
        console.log(error)
        let msg = `Error occurred creating the team`;
        if(error.response && error.response.status && error.response.status===409){
          msg = 'The team name already exists'
        }
        sendAlert({message:msg,type:'error'})
        setDisableButton(false);
      })
  }

  function handleClose() {
    dispatch({ type: 'set_alert', alert: false })
  }


  const labelClickable = index => {
    if(index===0)return true;
    if(index===1 && name.trim()!=='')return true;
    if(index===2 && name.trim()!=='')return true;
    if(index===3 && name.trim()!=='')return true;
    if(index===4 && name.trim()!=='')return true;
    return false
  }


  const onNext = index => {
    if(labelClickable(index+1)){
      setStep(index+1);
    }
  }

  const onSkip = index => {
    setStep(index+1)
  }

  const onClose = () => {
    dispatch({type:'set_tab_state',tabState:0})
  }

  const onReset = () => {
    setName('')
    setDescription('');
    setParent();
    setStep(0)
    setSnackbarMessage('');
    setAddedMembers([]);
    setParentSearchValue('');
    setSearchValue('');
  }

  const onLabelClick = index => {
    if(!labelClickable(index))return;
    setStep(index)
  }

  const getStepLabelText = index => {
    let actualStep = step;
    switch(index){
      case 0:
        if(actualStep!==0){
          return `Team Name: ${name}`
        }else{
          return steps[0]
        }
      case 1:
        if(actualStep===1 || description.trim()===''){
          return steps[1]
        }else{
          return `Team Description: ${description.length>60?description.slice(0,60)+'...':description}`
        }
      case 2:
        if(actualStep===2 || !parent){
          return steps[2];
        }else{
          return `Parent: ${parent.name}`
        }
      case 3:
        if(actualStep===3|| displayedTeamMember().length===0){
          return steps[3];
        }else{
          return `Team Members: ${displayedTeamMember().length>3?displayedTeamMember().slice(0,3).map(el=>el.name).join(', ')+` and ${displayedTeamMember().length-3} others`:displayedTeamMember().map(el=>el.name).join(', ')}`
        }
      case 4:
        return 'Create Team'
      default:
        return ''
    }
  }


  const renderResults = (data) => {
    if(!data)return[]
    if(data.length===0){
      return [
        <div style={{minWidth:300}}>
          <ListItem >
            <div style={{display:'flex',alignItems:'center'}}>
              <Typography style={{marginLeft:12,color:theme.palette.primaryText.main}}>{'No suggestions'}</Typography>
            </div>
          </ListItem>
        </div>
      ]
    }

    return data.map((el,index)=>
      (
        <div style={{minWidth:300}}>
          <ListItem 
            button 
            onClick={() => {
              setParent(el)
              setParentSearchValue('')
            }}
          >
              <div style={{display:'flex',alignItems:'center'}}>
                <Typography style={{marginLeft:12,color:theme.palette.primaryText.main}}>{toTitleCase(el.name)}</Typography>
              </div>
          </ListItem>
          {index!==data.length-1 && <Divider style={{marginLeft:16,backgroundColor:theme.palette.listItemDivider.main}}/>}
        </div>
      )
    )
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return (
          <div className={classes.block}>
            <FormControl className={classes.inputField}>
              <div style={{display:'flex'}}>
                <InputBase
                  className={classes.textField}
                  value={name}
                  onChange={event => setName(event.target.value)}
                  placeholder={'Enter a name for the Team'}
                />
              </div>
              <FormHelperText className={classes.helperText}>Required</FormHelperText>
            </FormControl>
            <Button color='primary' variant='contained' classes={{disabled:classes.disabledButton}} className={classes.button} disabled={!labelClickable(1)} onClick={()=>onNext(0)}>NEXT</Button>
          </div>
        )
      case 1:
        return (
          <div className={classes.block}>
            <FormControl className={classes.inputField}>
              <div style={{display:'flex'}}>
                <InputBase
                  className={classes.textField}
                  value={description}
                  onChange={e => {if(e.target.value.length<=200)setDescription(e.target.value)}}
                  placeholder={'Enter a description for this team'}
                  multiline
                  //rows="3"
                  rows="4"
                  rowsMax="300"
                />
              </div>
              <FormHelperText className={classes.helperText}>Optional. Max 200 characters</FormHelperText>
            </FormControl>
            <Button color='primary' variant='contained' className={classes.button} onClick={()=>onSkip(1)}>{description.trim()===''?'SKIP':'NEXT'}</Button>
          </div>
        )
      case 2:
        return (
          <div className={classes.block}>
            <div style={{display:'flex'}}>
              <div style={{flexGrow:1}}>
                <TeamSelector
                  renderResults={renderResults}
                  url='/solr/search/select'
                  params={{
                    q:`${parentSearchValue.trim()}*`,
                    fq:`object_type_srt:TEAM ${parent?`AND -id:${parent.id}`:''}`,
                    fl:'*',
                    sort:'name_srt asc',
                  }}
                  searchValue={parentSearchValue}
                  setSearchValue={setParentSearchValue}
                  selectedGroup={parent}
                  setGroup={el=>setParent(el)}
                  clearTeam={el=>setParent()}
                  autoSuggestion={true}
                />
              </div>
            </div>
            <Button color='primary' variant='contained' className={classes.button} onClick={()=>onSkip(2)}>{parent?'NEXT':'SKIP'}</Button>
          </div>
        )
      case 3:
        return (
          <div className={classes.block}>
            <div style={{display:'flex',alignItems:'center',padding:'4px',borderRadius:4,background:theme.palette.chip.main,border:`1px solid ${theme.palette.border.main}`,marginBottom:24}}>
              {getIconComponent({label:'info_contained',size:24,colour:'#2196F3'})}
              <span style={{fontSize:13.75, color:theme.palette.primaryText.main,marginLeft:8}}>
                You can add User Accounts (users registered in K) and Local Accounts (users found in sources)
              </span>
            </div>
            <div style={{display:'flex'}}>
              <Select
                value={accountType}
                onChange={event=>setAccountType(event.target.value)}
                className={classes.accountTypeSelector}
              >
                {
                  [
                    {value:'all',dispValue:'All Types'},
                    {value:'USER_ACCOUNT',dispValue:'User Account'},
                    {value:'LOCAL_ACCOUNT',dispValue:'Local Account'},
                  ].map(el=>(
                    <MenuItem value={el.value}>{el.dispValue}</MenuItem>
                  ))
                }
              </Select>
              <SearchSelector
                url='/solr/search/select'
                params={{
                  q: `${searchValue}*`,
                  fq: `object_type_srt:USER ${accountType==='all'?'':`AND account_type_srt:"${accountType}"`} AND -merge_type_srt:CANDIDATE`,
                  fl:'*',
                  rows: 10
                }}
                removeFLModify={false}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder='Search for a person to add to this team'
                onResultClick={onResultClick}
                renderResults={items=>{
                  if(items.length===0){
                    return (
                      [<div>
                        <ListItem>
                          <div style={{display:'flex',alignItems:'center'}}>
                            <Typography style={{marginLeft:12, color:theme.palette.primaryText.main}}>No suggestions found</Typography>
                          </div>
                        </ListItem>
                      </div>]
                    )
                  }
                  return items.map(el=><UserSuggestionItem item={el} onClick={()=>onResultClick(el)}/>)
                }}
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              {displayedTeamMember().length > 0 && displayedTeamMember().map(el => {
                return (
                  <div className={classes.chip}>
                    <div style={{ marginRight: 10, display: 'inline-block', verticalAlign: 'middle' }}>
                      <Avatar align='center' className={classes.avatar}>{getInitials(getDispFields(el,'dispTitle'))}</Avatar>
                    </div>
                    <Typography style={{ display: 'inline-block', verticalAlign: 'middle', color:theme.palette.primaryText.main }}>{getDispFields(el,'dispTitle')}</Typography>
                    <div style={{ marginLeft: 10, display: 'inline-block', verticalAlign: 'middle' }}>
                      <IconButton style={{padding:0}} onClick={() => removeTeamMember(el)}>{getIconComponent({ label: 'clear', size: 20, colour:theme.palette.primaryText.main })}</IconButton>
                    </div>
                  </div>
                )
              })}
              {
                displayedTeamMember().length===0 && 
                <div style={{display:'flex',alignItems:'center',height:45,width:152,backgroundColor:theme.palette.chip.main,border:`1px solid ${theme.palette.border.main}`,borderRadius:5}}>
                  <div style={{margin:'0 6px 0 6px',height:24}}>{getIconComponent({label:'user',size:24,colour:theme.palette.primaryText.main})}</div>
                  <Typography style={{ color:theme.palette.primaryText.main, display: 'inline-block', verticalAlign: 'middle' }}>No people added</Typography>
                </div>
              }
            </div>
            <Button color='primary' variant='contained' className={classes.button} onClick={()=>onNext(3)}>{addedMembers.length===0?'SKIP':'NEXT'}</Button>
          </div>
        )
      case 4:
        return (
          <div>
            <Button color='primary' variant='contained' classes={{disabled:classes.disabledButton}} className={classes.button}  onClick={() => dispatch({ type: 'set_alert', alert: true })} disabled={disableButton}>{'CREATE'}</Button>
          </div>
        )
      default:
        return <div></div>
    }
  }

  if(state.memberLoading)return <CircularProgress color='secondary'/>

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        style={{marginLeft: 50,marginTop:24}}
      >
        <SnackbarContent
          className={classes.snackbar}
          message={<div style={{color:theme.palette.snackbarContent.main}}>{snackbarMessage}</div>}
        />
      </Snackbar>

      <div style={{ maxWidth: '50rem' }}>

        <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
          {steps.map((label, index) => (
            <Step className={classes.step} key={index}>
              <StepLabel style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
                <Typography color='primary' style={{color:step<index?'#888':undefined,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
              </StepLabel>
              <StepContent >
              {getStepContent(index)}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <div>
          <Dialog
            classes={{paper:classes.paper}}
            open={state.alert}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Typography className={classes.alertTitle} id="alert-dialog-title">{"Are you sure?"}</Typography>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography color='textSecondary' className={classes.alertText}>
                  {
                    `You are about to create a team called ${name}.`
                  }
                </Typography>
                <Typography color='textSecondary' className={classes.alertText}>
                  {"Make sure the name is correct as this cannot be changed."}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus disabled={disableButton}>
                CANCEL
              </Button>
              <Button onClick={onSave} style={{ color: '#FC642D' }} disabled={disableButton}>
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </div>

      </div>
      <div style={{  left:0,right: 0, height:80, boxShadow:'0px -1px 5px 0px #DDD', backgroundColor: theme.palette.background.main, borderTop:`1px solid ${theme.palette.border.main}`, position: 'fixed', bottom: 0,display:'flex',alignItems:'center',justifyContent:'flex-end' }}>
        <Button  classes={{disabled:classes.disabledButton}} style={{marginLeft:'60%',width:120,height:48,color:theme.palette.primaryText.light}} onClick={onReset} disabled={disableButton}>
          RESET
        </Button>
        <Button  classes={{disabled:classes.disabledButton}} color='primary' variant='contained' style={{marginLeft:30,width:120,height:48,marginRight:'15%'}} onClick={onClose} disabled={disableButton}>
          CLOSE
        </Button>
      </div>
    </div>)
}

export default withTheme()(withStyles(styles)(CreateTeam));
