import React, { useState, useEffect, useReducer } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { LinearProgress, Typography } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/TableProfile/Body/Body';
import { handleShareClick, setInitialState, getUserRoles, getDispFields, removeUrlQueryArg } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr';
import { connect, useDispatch } from 'react-redux'
import * as actions from '../../store/actions/index';
import * as actionTypes from '../../store/actions/actionTypes';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import 'url-search-params-polyfill';
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import { addHistory } from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkIsFollowed, checkLineageLinkable, checkProfileEditable, checkTermLinkable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
import { getIconLabel } from '../../components/UI/SearchResults/utils';
import { getAlterName } from '../../components/UI/ProfileHeader/utils';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  normalText: {
    color: theme.palette.primaryText.main,
  },
  clickableText: {
    textDecoration: 'underline',
  }

});

const initialState = {
  tabState: 0,
  // basic table data
  basicData: null,
  tagsData: { items: [] },
  // creator data
  creatorData: null,
  // insights 
  insightsData: null,
  // usage
  usageData: null,
  usageError: null,
  usageLoading: null,
  usagePeriod: 3,
  usageType: 'direct',
  // lineage
  lineageData: {
    tabState: 0,
    upstreamView: 'basic_map',
    downstreamView: 'basic_map',
    upstreamObjects: ['TABLE', 'CONTENT', 'DATASET', 'DATA_PIPELINE', 'FILE', 'QUERY'],
    downstreamObjects: ['TABLE', 'CONTENT', 'DATASET', 'DATA_PIPELINE', 'FILE', 'QUERY'],

    upstreamListSort: 'LAST_USED_DESC',
    downstreamListSort: 'LAST_USED_DESC',


  },
  showDeletedColumn: false,

  joinsView: 'list',

  //panel uage data
  usagePanelData: null,
  usagePanelError: null,
  usagePanelLoading: null,

  //
  dqModalOpen: false,
  //selectedDate:new Date()
  selectedDate: null,
  columnSort: 'total_usage_srt desc',
  relatedContentData: null,

  joinsData: null,
  tableClusterError: null,
  tableClusterLoading: null,

  // reference
  referenceFilter: 'name_srt asc',

  linkToEditing: false,


  tagModalOpen: false,
  historyDetailData: {},
  noteTabState: 0,
  noteDetailMap: {},
  searchFilter: ''
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError: action.basicDataError,
        basicDataLoading: action.basicDataLoading
      }
    case 'set_replaced_object':
      return {
        ...state,
        replacedObject: action.replacedObject,
      }
    case 'set_child_count':
      return {
        ...state,
        childCount: action.childCount
      }
    case 'set_list_modal_open': {
      return {
        ...state,
        listModalOpen: action.listModalOpen
      }
    }
    case 'set_warning_modal_open': {
      return {
        ...state,
        warningModalOpen: action.warningModalOpen
      }
    }
    case 'set_note_modal_open': {
      return {
        ...state,
        noteModalOpen: action.noteModalOpen
      }
    }
    case 'set_terms':
      return {
        ...state,
        terms: action.terms,
        termsLoading: action.termsLoading,
        termsError: action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading: action.notesLoading,
        notesError: action.notesError,
        notes: action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData: action.surveyData,
        surveyLoading: action.surveyLoading,
        surveyError: action.surveyError
      }
    case 'set_note_tab_state': {
      return {
        ...state,
        noteTabState: action.noteTabState
      }
    }
    case 'set_note_detail_map': {
      return {
        ...state,
        noteDetailMap: action.noteDetailMap
      }
    }
    case 'set_access_modal_open': {
      return {
        ...state,
        accessModalOpen: action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading: action.accessRolesLoading,
        accessRolesError: action.accessRolesError,
        accessRoles: action.accessRoles
      }
    case 'set_changes':
      return {
        ...state,
        changes: action.changes,
        changesLoading: action.changesLoading,
        changesError: action.changesError
      }
    case 'set_change_date':
      return {
        ...state,
        changeDate: action.changeDate
      }
    case 'set_change_type':
      return {
        ...state,
        changeType: action.changeType
      }
    case 'set_selected_change':
      return {
        ...state,
        selectedChange: action.selectedChange
      }
    case 'set_collection_instances': {
      return {
        ...state,
        collectionInstancesData: action.collectionInstancesData
      }
    }
    case 'set_creator_data':
      return {
        ...state,
        creatorData: action.creatorData
      }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod: action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType: action.usageType
      }
    case 'set_dq_insights_data':
      return {
        ...state,
        dqInsightsData: action.dqInsightsData
      }
    // case 'set_quality_data':
    //   return {
    //     ...state,
    //     qualityData:action.qualityData,
    //     qualityError:action.qualityError,
    //     qualityLoading:action.qualityLoading
    //   }
    // case 'set_quality_date_data':
    //   return {
    //     ...state,
    //     qualityDateData:action.qualityDateData,
    //     qualityDateError:action.qualityDateError,
    //     qualityDateLoading:action.qualityDateLoading,
    //     qualityDateSelected: action.qualityDateSelected
    //   }
    // case 'set_dq_detail_data':
    //   return {
    //     ...state,
    //     dqDetailData:action.dqDetailData,
    //   }
    case 'set_history_data':
      return {
        ...state,
        historyData: action.historyData,
        historyError: action.historyError,
        historyLoading: action.historyLoading
      }
    case 'set_history_detail_data':
      return {
        ...state,
        historyDetailData: action.historyDetailData
      }
    case 'set_columnlist_data':
      return {
        ...state,
        columnlistData: action.columnlistData,
        columnlistError: action.columnlistError,
        columnlistLoading: action.columnlistLoading
      }
    case 'set_search_filter':
      return {
        ...state,
        searchFilter: action.searchFilter
      }
    case 'set_show_deleted_column':
      return {
        ...state,
        showDeletedColumn: action.showDeletedColumn
      }
    case 'set_reference_child_count':
      return {
        ...state,
        referenceChildCount: action.referenceChildCount
      }
    case 'set_show_reference_columns':
      return {
        ...state,
        showReferenceColumns: action.showReferenceColumns
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData: action.linkObjectData
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_downstream_data':
      return {
        ...state,
        downStreamData: action.downStreamData,
        downStreamError: action.downStreamError,
        downStreamLoading: action.downStreamLoading
      }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties: action.additionalProperties,
        additionalPropertiesLoading: action.additionalPropertiesLoading,
        additionalPropertiesError: action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen: action.additionalPropertiesModalOpen,
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case "set_column_sort":
      return {
        ...state,
        columnSort: action.columnSort
      }
    case 'set_related_data':
      return {
        ...state,
        relatedContentData: action.relatedContentData
      }
    case 'set_table_cluster_data':
      return {
        ...state,
        tableClusterData: action.tableClusterData,
        tableClusterLoading: action.tableClusterLoading,
        tableClusterError: action.tableClusterError,
      }
    case 'set_table_cluster_total':
      return {
        ...state,
        tableClusterTotal: action.tableClusterTotal
      }
    case 'set_lineage_data':
      return {
        ...state, lineageData: action.lineageData
      }
    case 'set_link_editing':
      return {
        ...state, linkToEditing: action.linkToEditing
      }
    case "set_downstream_filter":
      return { ...state, downStreamFilter: action.downStreamFilter }

    case 'set_joins_data':
      return {
        ...state,
        joinsData: action.joinsData
      }
    case 'set_joins_view':
      return {
        ...state,
        joinsView: action.joinsView
      }
    case "set_only_clear_state":
      return { ...initialState, loadedFromContainer: true }
    case 'set_following': {
      return {
        ...state, following: action.following
      }
    }
    case 'set_is_steward_or_owner': {
      return {
        ...state, isStewardOrOwner: action.isStewardOrOwner
      }
    }
    case 'set_reference_data': {
      return {
        ...state,
        referenceData: action.referenceData,
        referenceError: action.referenceError,
        referenceLoading: action.referenceLoading
      }
    }
    case 'set_reference_filter': {
      return {
        ...state,
        referenceFilter: action.referenceFilter
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}



const TableProfile = props => {

  const {
    match,
    history,
    theme,
    classes,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')
  const roles = getUserRoles(sessionData.user_role)
  const reduxDispatch = useDispatch()

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache, initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  let isLineageLinkable = checkLineageLinkable({ sessionData, isStewardOrOwner: state.isStewardOrOwner })

  const getTabOptions = data => {
    // return [state.childCount?'COLUMNS':undefined, 'DETAILS','KNOWLEDGE', roles.every(el=>el==='90')?undefined:data.usage_txt==='UNKNOWN'?undefined:'USAGE', 'JOINS', 'LINEAGE','ISSUES','QUALITY', 'RELATED', 'CHANGES'].filter(el=>el!==undefined)
    return [state.childCount?'COLUMNS':undefined, 'DETAILS','KNOWLEDGE', roles.every(el=>el==='90')?undefined:'USAGE', 'JOINS', 'LINEAGE','ISSUES','QUALITY', 'RELATED', 'CHANGES'].filter(el=>el!==undefined)
  }

  useEffect(() => {
    if (!state) return;
    storePageCache({ cacheID: window.location.href, ...state })
    // eslint-disable-next-line
  }, [state])

  const loadReplacedObject = () => {
    axiosCerebrum
      .get(
        `/api/tables/${match.params.id.toLowerCase()}/related`, {
        params: {
          relationship: 'REPLACED_BY',
          per_page: 1
        }
      }
      )
      .then(response => {
        if (!response.data.items[0]) return;
        axiosSolr
          .get(
            `/solr/search/select`, {
            params: {
              q: '*',
              fq: `id:(${response.data.items[0].id})`
            }
          }
          )
          .then(solrResp => {
            dispatch({
              type: 'set_replaced_object',
              replacedObject: solrResp.data.response.docs[0]
            })
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchList = () => {
    dispatch({ type: 'set_basic_data', basicDataLoading: state.basicData ? false : true, basicData: state.basicData });
    axiosCerebrum
      .get(
        `/api/tables/${match.params.id.toLowerCase()}`,
      )
      .then(async cerebrumResponse => {
        let fullResponse = cerebrumResponse.data;
        if (!fullResponse) {
          dispatch({ type: 'set_basic_data', basicDataError: true })
          return;
        }
        if (fullResponse.active_flag === false) {
          !state.basicData && dispatch({ type: 'set_show_deleted_column', showDeletedColumn: true })
        }
        if (fullResponse.reference === true) {
          dispatch({ type: 'set_show_reference_columns', showReferenceColumns: true })
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response => sourceData = response.data).catch(error => { console.log(error) })
        let codeData;
        if ([2, 3].includes(fullResponse.table_type_ref_id)) {
          await axiosCerebrum
            .get(
              `/api/tables/${match.params.id.toLowerCase()}/related`, {
                params: {
                  relationship: 'REFERENCES',
                  object_name: 'QUERY',
                  per_page: 1,
                  sort: "START_DESC"
                }
            }
            )
            .then(async r => {
              if (r.data.items.length > 0) {
                await axiosCerebrum
                  .get(`/api/queries/${r.data.items[0].id}`)
                  .then(q => {
                    codeData = q.data
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }

        addHistory({ url: window.location.pathname, title: getDispFields(fullResponse, 'dispTitle'), subTitle: getDispFields(fullResponse, 'dispSubtitle'), object: fullResponse, type: 'profile' })
        axiosSolr
          .get(`/solr/search/select`, { params: { q: "*", fq: `id:${fullResponse.id}`, fl: '*' } })
          .then(response => {
            dispatch({ type: 'set_basic_data', basicData: { ...(state.basicData || {}), ...(response.data.response.docs[0] || {}), ...fullResponse, source_data: sourceData, codeData } })
            dispatch({ type: 'set_child_count', childCount: response.data.response.docs[0] ? response.data.response.docs[0].child_object_count_srt : 0 })
            if (fullResponse.replace_date) {
              loadReplacedObject()
            }
          })
          .catch(error => {
            console.log(error)
            dispatch({ type: 'set_basic_data', basicData: fullResponse })
          })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_basic_data', basicDataError: true })
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({ objectId: match.params.id.toLowerCase() })
      .then(followed => {
        dispatch({ type: 'set_following', following: followed })
      })
  }

  const loadTableClusterTotal = () => {
    axiosCerebrum
      .get(
        `/api/tables/${match.params.id.toLowerCase()}/related`,
        {
          params: {
            per_page: 0,
            object_name: 'COLLECTION_INSTANCE',
            relationship: 'MEMBER_OF_AUTO',
            'parent_name': 'table cluster'
          }
        }
      )
      .then(response => {
        dispatch({
          type: 'set_table_cluster_total', tableClusterTotal: response.data.total
        })
      })
  }

  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/tables/${match.params.id.toLowerCase()}/related`,
        {
          params: {
            relationship: 'IMPACTED_BY,IMPACTED_BY_SRC',
            per_page: 200,
            sort: 'END_DESC'
          }
        }
      )
      .then(response => {
        if (response.data.items.length === 0) {
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select', { query: '*', limit: response.data.items.length, filter: `id:(${response.data.items.map(el => el.id).join(' OR ')})` })
          .then(solrRes => {
            dispatch({
              type: 'set_issue_data',
              issueData: { ...response.data, items: solrRes.data.response.docs },
            })
          })
          .catch(error => {
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error => {
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }

  useEffect(() => {
    if (!state.issueData) issueFetchList()
    if (!state.basicData) {
      fetchList();
    }
    if (state.followData === undefined) {
      followDataFetch()
    }
    // eslint-disable-next-line
  }, [])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`, {
        params: {
          object_id: match.params.id.toLowerCase(),
          relationship: 'OWNER_OF,STEWARD_OF'
        }
      })
      .then(response => {
        dispatch({ type: 'set_is_steward_or_owner', isStewardOrOwner: response.data.total > 0 })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: 'set_is_steward_or_owner', isStewardOrOwner: false })
      })
  }

  useEffect(() => {
    checkIsStewardOrOwner()
    // eslint-disable-next-line
  }, [state.insightsData])

  // trigger api call when tab state is changed, if data is already fetched, do nothing
  useEffect(() => {
    if (!state.basicData) {
      return;
    }
    window.scrollTo(0, 0);
    let tabName = getTabOptions(state.basicData)[state.tabState]
    if (tabName === 'DETAILS') {
      dispatch({ type: 'set_link_editing', linkToEditing: false });
    }
    switch (tabName) {
      case 'RELATED':
        if (!state.tableClusterTotal) loadTableClusterTotal();
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [state.tabState, state.basicData])


  useEffect(() => {
    if (tabName && state.basicData && !isNaN(state.childCount)) {
      let tabOptions = getTabOptions(state.basicData);
      if (!tabOptions.includes(tabName.toUpperCase())) return;
      window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['tabName'] }));
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({ type: 'set_tab_state', tabState: presetTabState })
    }
    // eslint-disable-next-line
  }, [state.basicData, tabName, state.childCount])


  useEffect(() => {
    window.removeEventListener('message', globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if (msg.data.reload_related_terms) {
        dispatch({ type: 'set_terms' })
      }
      if (msg.data.reload_issue) {
        issueFetchList()
      }
      if (msg.data.open_link_term_modal) {
        setLinkModalOpen({ relations: ['RELATED'], linkableObjects: ['TERM'] })
      }
      if (!isLineageLinkable) return;
      if (msg.data.open_profile_link_modal) {
        setLinkModalOpen({
          relations: ['SOURCE_OF', 'SOURCE_FROM', 'REPLACED_BY', 'REPLACES'],
          linkableObjects: lineageObjectsMap.TABLE
        })
      }
    }
    window.addEventListener('message', globalListenerRef.profileLinkModalListener)

    return () => {
      window.removeEventListener('message', globalListenerRef.profileLinkModalListener)
    }

    // eslint-disable-next-line
  }, [isLineageLinkable])


  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign: 'center', width: '18.75rem', margin: '20vh auto' }}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if (!state.basicData) {
    return <div></div>
  }


  let isShell = state.basicData.reference !== false


  const tabOptions = getTabOptions(state.basicData)

  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  const onCasCaseUpdate = () => {
    reduxDispatch({
      type: actionTypes.SET_BULK_EDIT_PARAM,
      data: {
        url: '/solr/search/select',
        type: 'solr',
        params: {
          q: "*",
          fq: `(source_id_srt:${state.basicData.source_id} OR source_id_srt:998) AND database_srt:"${state.basicData.database_txt}" AND schema_srt:"${state.basicData.schema_txt}" AND (table_srt:"${state.basicData.name}" OR id:${state.basicData.id})`,
        },
        editMode: 'cascade',
        cascadeParentObj: state.basicData,
        redirectUrl: window.location.pathname,
        fileName: `Cascade update from ${state.basicData.name} table`,
      }
    })

    history.push('/bulk_update')
  }

  let optionTitle = []
  let optionList = []
  optionTitle.push('NOTICES')
  optionList.push(
    { onClick: onCasCaseUpdate, iconLabel: 'bulk_action_cascade', text: 'Cascade Update', group: 'NOTICES' }
  )

  optionTitle.push('OPEN IN APPLICATIONS')
  optionList.push(
    roles.every(r => r === '90') || state.basicData.active_flag === false ? undefined : { onClick: () => history.push(`/impact_assessment?targetObjectId=${match.params.id.toLowerCase()}`), iconLabel: 'ia_downstream', text: 'Assess Downstream Impact', group: 'OPEN IN APPLICATIONS' },
    roles.every(r => r === '90') || state.basicData.active_flag === false ? undefined : { onClick: () => history.push(`/dependency_assessment?targetObjectId=${match.params.id.toLowerCase()}`), iconLabel: 'ia_upstream', text: 'Assess Upstream Dependencies', group: 'OPEN IN APPLICATIONS' },
  )

  if (roles.find(el => ['10', '40', '00'].includes(el))) {
    optionList.push(
      // {onClick:() => history.push(`/question_board?subTabName=RISK&presetCode=0340_OBJECT&objectId=${match.params.id.toLowerCase()}`),iconLabel:'questionboard',text:'Usage Report',group:'ASK K'},
      { onClick: () => history.push(`/question_board?subTabName=RISK&presetCode=0340_OBJECT&objectId=${match.params.id.toLowerCase()}`), iconLabel: 'questionboard', text: 'Access Report', group: 'OPEN IN APPLICATIONS' },
    )
  }
  
  buttons.push(
    <DropdownButton
      iconLabel='dot_menu'
      tooltip="More actions"
      hideSectionTitle={true}
      iconOnly={true}
      iconColour={theme.palette.primaryText.light}
      title={optionTitle}
      optionList={optionList}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({ type: 'set_list_modal_open', listModalOpen: true })}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )

  if (!isShell) {
    buttons.push(
      <DropdownButton
        iconLabel='add'
        text='ADD'
        optionList={[
          { onClick: () => dispatch({ type: 'set_warning_modal_open', warningModalOpen: true }), iconLabel: 'warning', text: 'Add warning notice' },
          { onClick: () => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }), iconLabel: 'issue', text: 'Add Issue' },
          { onClick: () => dispatch({ type: 'set_note_modal_open', noteModalOpen: true }), iconLabel: 'note', text: 'Add Knowledge' },
          { onClick: () => dispatch({ type: 'set_note_modal_open', noteModalOpen: { isQuestion: true } }), iconLabel: 'question', text: 'Ask Question' },
          checkTermLinkable({ sessionData }) ? { onClick: () => setLinkModalOpen({ relations: ['RELATED'], linkableObjects: ['TERM'] }), iconLabel: 'glossary', text: 'Add Term' } : undefined,
          checkProfileEditable({ sessionData, isStewardOrOwner: state.isStewardOrOwner }) ? { onClick: () => dispatch({ type: 'set_additional_properties_modal_open', additionalPropertiesModalOpen: true }), iconLabel: 'info', text: 'Add Property' } : undefined,
        ]}
      />
    )
  }

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )


  let bannerVisibility, bannerCause, bannerdisplayText;

  let openIssueCount = state.issueData && state.issueData.items.filter(i => !i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText = `This table has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility = 'visible'
  }
  if (state.basicData && state.basicData.replace_date) {
    bannerdisplayText = 'This table has been replaced and should no longer be used';
    if (state.replacedObject) {
      bannerdisplayText = (
        <span>
          This table has been replaced by <span className={classes.clickableText}>
            {getDispFields(state.replacedObject, 'dispTitle')} ({state.replacedObject.source_txt}.{state.replacedObject.schema_txt})</span> and should no longer be used
        </span>
      )
    }
    bannerCause = 'replacedByDate';
    bannerVisibility = 'visible'
  }
  if (state.basicData && state.basicData.active_flag === false) {
    bannerdisplayText = 'This table has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }
  if (state.basicData && state.basicData.source_data && state.basicData.source_data.active_flag === false) {
    bannerdisplayText = 'The source of this table has been deactivated in K. Information on this table is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }
  if (isShell && state.basicData) {
    let sourceName = state.basicData.source_data ? state.basicData.source_data.name : 'Unkown Source'
    if (state.basicData.reference_sources) {
      if (state.basicData.reference_sources.length === 0) {
        bannerdisplayText = `This Table is referenced in unknown source. Limited metadata available.`
      }
      else if (state.basicData.reference_sources.length === 1) {
        bannerdisplayText = `This Table is referenced in ${state.basicData.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      } else {
        bannerdisplayText = (
          <Typography>
            This Table is referenced in <span onClick={() => dispatch({ type: 'set_tab_state', tabState: getTabOptions(state.basicData).indexOf('RELATED') })} style={{ textDecoration: 'underline', cursor: 'pointer' }}>multiple Tools</span>. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else {
      bannerdisplayText = 'This Table is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  // let title = state.basicData.name;
  // let description;
  // if(state.basicData.alternate_name && state.basicData.alternate_name.trim()!==''){
  //   if(!roles.every(el=>el==='90')){
  //     description = `Business name: ${state.basicData.alternate_name}`
  //   }else{
  //     title = state.basicData.alternate_name
  //     description = `Technical name: ${state.basicData.name}`
  //   }
  // }

  let { title, description } = getAlterName(state.basicData, roles)

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list', 'link', 'warning', 'note', 'additional_property', 'issue']}
        history={history}
      />
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='table'
              label="table"
              iconLabel={getIconLabel({ label: 'table', item: state.basicData })}
              title={title}
              shouldLoadBreadCrumb
              buttons={buttons}
              description={description}
              data={state.basicData}
              creatorData={state.creatorData}
              history={history}
              isShell={isShell}
              state={state}
              dispatch={dispatch}
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              shouldLoadLinkedInstance
              onClickAddTag={() => {
                setLinkModalOpen({
                  linkableObjects: ['COLLECTION_INSTANCE'],
                  relations: ['MEMBER_OF'],
                  onLinkUpdated: () => window.postMessage({ reload_header_instance: true }, document.location.protocol + "//" + document.location.hostname + ':' + document.location.port)
                })
              }}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={isShell ? 200 : 135}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            label='table'
            tabState={state.tabState}
            data={state.basicData}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            fetchList={fetchList}
            isShell={isShell}
            setLinkModalOpen={setLinkModalOpen}
            initialState={initialState}
            isLineageLinkable={isLineageLinkable}
          />
        }
        disableMinContentHeight={['CHANGES', 'LINEAGE'].includes(tabOptions[state.tabState])}
        hideSideBar={['LINEAGE', 'JOINS', 'CHANGES', 'RELATED', 'QUALITY'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <div>
            <ProfileSideBar
              tabOptions={tabOptions}
              history={history}
              state={state}
              dispatch={dispatch}
              mapping={
                ['tags', 'masked', 'piiDet', 'category', 'domain', 'description', 'sourceType', 'verified', 'verifiedNot', 'joins', 'trustscore', 'lastUpdated', 'knowledge', 'lineage', 'upstreamSources', 'downstreamSources', 'classification', 'channels', 'owner', 'stewardedBy', 'topTeams', 'topUsers', 'lastUsed', 'createdBy']
              }
              fetchList={fetchList}
              sessionData={sessionData}
              cerebrumLabel={'tables'}
              data={state.basicData}
            />
          </div>
        }
      />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(TableProfile)));
