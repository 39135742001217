import React from 'react';
import { withStyles, Typography} from '@material-ui/core';
import ListItem from '../Components/ListItem';
 

const styles = theme => ({
  root:{
    marginBottom:40
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16
  }
})

const DataManagementSetup = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;
  
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>DATA MANAGEMENT SETUP</Typography>
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Create a Data Management Collection'}
        onClick={()=>{history.push(`/admin/collection?tabName=DATA_MANAGEMENT`)}}
      />
    </div>
  )
}

export default withStyles(styles)(DataManagementSetup);