import React, {useReducer, useEffect} from 'react';
import { LinearProgress, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import {  getCustomerName, setInitialState } from '../../utilities';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import useGetCerebrum from '../../hooks/useGetCerebrum'
import { addHistory } from '../../HistoryManager';
import Body from '../../components/Inventory/Body/Body';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import 'url-search-params-polyfill';
import { mapStepStringToList } from '../../components/Inventory/utils';
import { CerebrumLongListLoader } from '../../LongListLoader';

const initialState = {
  category:'data',
  stepList:[],
  search:'',
  nameSort:'name_srt asc',
  showDeleted:false,
  showReference:false
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_source_list':
      return {
        ...state,
        sourceList: action.sourceList
      }
    case 'set_hosts':{
      return {
        ...state,
        hosts:action.hosts,
        hostLoading:action.hostLoading,
        hostError:action.hostError
      }
    }
    case 'set_category':
      return {
        ...state,
        category: action.category
      }
    case 'set_step_list':
      return {
        ...state,
        stepList: action.stepList
      }
    case 'set_list':
      return {
        ...state,
        list:action.list
      }
    case 'set_search':
      return {
        ...state,
        search:action.search
      }
    case 'set_sidebar_source_list':
      return {
        ...state,
        sidebarSourceList: action.sidebarSourceList
      }
    case 'set_name_sort':
      return {
        ...state,
        nameSort:action.nameSort
      }
    case 'set_show_deleted':
      return {
        ...state,
        showDeleted:action.showDeleted
      }
    case 'set_show_reference':
      return {
        ...state,
        showReference:action.showReference
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const styles = theme => ({
})

function Inventory(props) {

  const {
    classes,
    history,
    pageCache,
    storePageCache
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const stepList = urlSearch.get('stepList');
    const category = urlSearch.get('category');
    if(category){
      dispatch({type:'set_category',category})
    }
    if(stepList){
      dispatch({type:'set_step_list',stepList:mapStepStringToList(stepList)})
    }
  },[])

  const {
    data:sourceData,
    loading:sourceLoading,
    error:sourceError,
    fetchList:sourceFetch
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:200,
      sort:'ALPHABETICAL'
      // active_flag:true
    },
    preventAuto:true
  })

  const hostFetchList = () => {
    CerebrumLongListLoader({
      url: '/api/hosts',
      params: {
        reference:false,
        sort:'ALPHABETICAL'
      },
      per_page:200,
      onStartLoad: () => {
        dispatch({
          type:'set_hosts',
          hostLoading:true,
        })
      },
      onFinishLoad: ({data}) => {
        dispatch({
          type:'set_hosts',
          hosts:data
        })
      },
      onError: (error) => {
        console.log(error)
        dispatch({
          type:'set_hosts',
          hostError:true
        })
      }
    })
  }

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
    // eslint-disable-next-line
  },[state])

  useEffect(()=>{
    if(!state.sourceList){
      sourceFetch();
    }
    if(!state.hosts){
      hostFetchList();
    }
    addHistory({url:window.location.pathname, iconLabel:'inventory', title: 'Inventory', subTitle:'Inventory',type:'application'})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(sourceData){
      dispatch({type:'set_source_list',sourceList:sourceData.items})
    }
  },[sourceData])

  if (sourceLoading || state.hostLoading ) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (sourceError || state.hostError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.sourceList || !state.hosts)return <div></div>

  let customerName = getCustomerName()

  return (
    <div className={classes.root}>
      <ProfileLayout
        noPadding
        disableHeaderAutoHeight
        disableMinContentHeight
        header= {
          <ProfileHeader
            label='inventory'
            title={customerName===''?'Inventory':`${customerName} data inventory`}
            subtitle=' '
            description={' '}
            minHeight={100}
          />
        }
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
          />
        }
      >
        
      </ProfileLayout>
    </div>
  )
}

Inventory.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Inventory));

