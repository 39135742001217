import React, { useState, useEffect } from 'react';
import { withTheme, withStyles, Select, MenuItem, Checkbox, InputBase, Button, Typography, IconButton, Divider } from '@material-ui/core';
import { getIconComponent } from '../../../../utilities'
// import Alert from 'react-s-alert'
import { defaultColumnMap, formatColumnNames, processObjectType } from '../Utils/Utils'
import { globalListenerRef } from '../../../../GlobalListenerRef';

const styles = theme => ({
  selector:{
    ...theme.components.selector,
    background:theme.palette.background.main,
    border:'0px',
    // borderBottom:`1px solid ${theme.palette.primaryText.main}`,
    borderRadius:0,
    width:144,
    '& svg':{
      color:theme.palette.primary.main,
      marginTop:-2
    }
  },
  inputBase:{
    ...theme.components.inputBase,
    height:56,
    flexGrow:1,
    flexShrink:0,
    '& input':{
      paddingLeft:24
    }
  },
  valueList:{
    overflow:'auto',
    maxHeight:'30vh',
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  menu:{
    // marginTop:60,
    '& ul':{
      paddingTop:0,
      minWidth:400,
    },
  },
  chexkbox:{
    paddingLeft:0
  }
});



const ColumnSelector = props => {

  const {
    classes,
    state,
    dispatch,
    theme
  } = props;

  const [columnSearchValue, setColumnSearchValue] = useState('')
  const [tmpSelectedColumn, setTmpSelectedColumn] = useState([...state.selectedColumns])
  const [columnSelectorOpen, setColumnSelectorOpen] = useState(false)
  const [popperOffset, setPopperOffset] = useState(60)

  useEffect(()=>{
    if(columnSelectorOpen){
      if(Math.min(window.innerHeight,window.outerHeight)<950)setPopperOffset(0)
      else{setPopperOffset(60)}
    }else{
      setColumnSearchValue('')
    }
  },[columnSelectorOpen])

  useEffect(()=>{
    setTmpSelectedColumn([...state.selectedColumns])
  }, [state.selectedColumns])

  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.columnSelectorListener)
    globalListenerRef.columnSelectorListener = () => {
      if(Math.min(window.innerHeight,window.outerHeight)<950)setPopperOffset(0)
      else{setPopperOffset(60)}
    }
    window.addEventListener('resize',globalListenerRef.columnSelectorListener)

    return ()=>{
      window.removeEventListener('resize',globalListenerRef.columnSelectorListener)
    }
  },[])

  const onSelectColumn = column => {
    let newColumns;
    if(tmpSelectedColumn.includes(column)){
      newColumns = tmpSelectedColumn.filter(el=>el!==column)
    }else{
      newColumns = [...tmpSelectedColumn,column]
    }
    setTmpSelectedColumn(newColumns)
  }

  const onRestoreColumns = () => {

    let tabs=state.searchTabs;
    let objectType=state.selectedObjectType;

    let isCollection = tabs && tabs.find(el=>el.objectType===objectType).isCollection;
    let isCollectionType = tabs && tabs.find(el=>el.objectType===objectType).isCollectionType;
    let collectionType;
    if(isCollection){
      collectionType = tabs && tabs.find(el=>el.objectType===objectType).collectionType;
    }
    if(isCollectionType){
      collectionType = tabs && processObjectType(tabs.find(el=>el.objectType===objectType).objectType);
    }

    let defaultValue = defaultColumnMap[isCollection?collectionType==='PLATFORM'?'PLATFORM':'COLLECTION_INSTANCE':isCollectionType?'COLLECTION':objectType]||defaultColumnMap.DEFAULT

    dispatch({type:'set_selected_columns',selectedColumns: defaultValue })
    setTmpSelectedColumn(defaultValue)
  }

  const onApplyColumnChange = () => {
    if(tmpSelectedColumn.length===state.selectedColumns.length && tmpSelectedColumn.every(el=>state.selectedColumns.includes(el))){
      setColumnSelectorOpen(false)
      return;
    }
    dispatch({type:'set_selected_columns',selectedColumns:tmpSelectedColumn})
    dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
    setColumnSelectorOpen(false)
  }

  const onCancelColumnChange = () => {
    setTmpSelectedColumn([...state.selectedColumns])
    setColumnSelectorOpen(false)
  }

  return (
    <Select
      variant='standard'
      multiple={true}
      value={tmpSelectedColumn}
      className={classes.selector}
      open={columnSelectorOpen}
      id="search-edit-column-dropdown"
      data-test-id="search-edit-column-dropdown"
      onOpen={()=>setColumnSelectorOpen(true)}
      onClose={()=>setColumnSelectorOpen(false)}
      disableUnderline={true}
      SelectDisplayProps={{
        id:"search-edit-column-display"
      }}
      renderValue={()=><Typography style={{fontSize:14,color:theme.palette.primary.main}}>EDIT COLUMNS</Typography>}
      MenuProps={{
        className:classes.menu,
        style:{
          marginTop:popperOffset
        }
      }}
      classes={{selectMenu:classes.selectMenu}}
      displayEmpty={true}
    >
      <div style={{display:'flex',flexGrow:0,width:'100%'}}>
        <InputBase
          className={classes.inputBase}
          value={columnSearchValue}
          onChange={event=>setColumnSearchValue(event.target.value)}
          placeholder={`Search columns`}
          endAdornment={
            <IconButton disabled={columnSearchValue===''} onClick={()=>setColumnSearchValue('')} style={{padding:6,marginRight:16}}>
              {getIconComponent({label:columnSearchValue===''?'search':'clear',colour:theme.palette.primaryText.light,size:24})}
            </IconButton>
          }
        />
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingLeft:18,paddingRight:16,marginTop:8}}>
        <Typography color='primary' style={{fontSize:13.75,letterSpacing:2}}>{state.selectedColumns.length} SELECTED</Typography>
        <Button color='secondary' onClick={onRestoreColumns} style={{margin:'0',letterSpacing:2}}>RESET</Button>
      </div>
      <div className={classes.valueList}>
        {
          state.allColumns.filter(el=>columnSearchValue==='' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).length===0 && 
          <Typography style={{fontSize:13.75,marginLeft:24,color:theme.palette.primaryText.main}}>No column found</Typography>
        }
        {
          state.selectedColumns && state.selectedColumns.filter(el=>columnSearchValue==='' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).length>0 && 
          <>
            {
              [...state.selectedColumns].sort((a,b)=>a<b?-1:1).filter(el=>columnSearchValue==='' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).map(el=>(
                <MenuItem data-test-classname="search-edit-column-menuitem"  onClick={()=>onSelectColumn(el)} value={el}>
                  <Checkbox className={classes.chexkbox} color='primary' checked={tmpSelectedColumn.includes(el)}/>
                  {formatColumnNames(el)}
                </MenuItem>
              ))
            }
            <Divider style={{backgroundColor:theme.palette.listItemDivider.main,marginTop:16,marginBottom:16}}/>
          </>
        }
        {
          state.allColumns.filter(el=>el!=='name_txt' && !state.selectedColumns.includes(el)).filter(el=>columnSearchValue==='' || formatColumnNames(el).includes(columnSearchValue.toUpperCase())).map(el=>(
            <MenuItem data-test-classname="search-edit-column-menuitem"  onClick={()=>onSelectColumn(el)} value={el}>
              <Checkbox className={classes.chexkbox} color='primary' checked={tmpSelectedColumn.includes(el)}/>
              {formatColumnNames(el)}
            </MenuItem>
          ))
        }
      </div>
      <div style={{textAlign:'right',paddingRight:16}}>
        <Button color='primary' onClick={onApplyColumnChange} style={{marginRight:12}}>APPLY</Button>
        <Button color='primary' onClick={onCancelColumnChange}>CANCEL</Button>
      </div>
    </Select>
  )
}

export default withTheme()(withStyles(styles)(ColumnSelector));
